import { TRequestQueryParams } from "../redux/types/common/common.types";
import { StoredProductList, TSupplierDetails } from "../redux/types/StoredTypes/stored.types";
import { instance } from "./authAPI";
import Cookies from "universal-cookie";
import { processRequestQueryParams } from "./utils/apiUtils";

export const storedProduct = {
    getStoredProduct(cookies: Cookies, country_code?: string) {
        return instance.get<StoredProductList>(`/shipments/stored/dashboard/?${country_code && country_code !== "ALL" ? `integration__country_code=${country_code}` : ""}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getStoredProductAll(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<StoredProductList>(`/shipments/stored/dashboard/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getSuppliers(cookies: Cookies) {
        return instance.get<TSupplierDetails[]>(`/shipments/suppliers/dashboard/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
};
