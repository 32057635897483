import React from "react";
import { Container } from "react-bootstrap";
import "../Auth.css";
import loginBackground from "../../../assets/img/Login-page-graphic.webp";

const InfoBlock = () => {
    return (
        <Container className="block info-block d-flex justify-content-center p-0 mw-100 login-background">
            <img src={loginBackground} alt="Login"></img>
        </Container>
    );
};

export default InfoBlock;
