import { Suspense, lazy } from "react";
import { Container } from "react-bootstrap";
import { useAppSelector } from "./redux/ReduxStore";
import { Navigate, Route, Routes } from "react-router";

import Auth from "./components/Auth/Auth";
import Terms from "./components/LandingPage/Terms/Terms";
import Policy from "./components/LandingPage/Terms/Policy";
import CaseStudy1 from "./components/LandingPage/CaseStudy1";
import CaseStudy2 from "./components/LandingPage/CaseStudy2";
import LandingPage from "./components/LandingPage/LandingPage";
import ConfirmEmail from "./components/Auth/common/ConfirmEmail";
import SignInContainer from "./components/Auth/SignIn/SignInContainer";
import ConfirmAmazonLink from "./components/Auth/common/ConfirmAmazonLink";
import ResetContainer from "./components/Auth/ResetPassword/ResetContainer";
import AddUserToTenantPage from "./components/Auth/common/AddUserToTenantPage";
import ConfirmDocusign from "./components/Dashboard/common/DocuSign/ConfirmDocusign";
import PaymentConfirm from "./components/Settings/Billing/PaymentConfirm/PaymentConfirm";
import AddAuthorizedUserToTenant from "./components/Auth/common/AddAuthorizedUserToTenant";
import MaintenancePage from "./components/Dashboard/common/MaintenancePage/MaintenancePage";

import preloader from "./assets/video/preloader.webp";

const Dashboard = lazy(() => import("./components/Dashboard/Dashboard"));
const DashboardWarehouse = lazy(() => import("./components/DashboardWarehouse/DashboardWarehouse"));
const DashboardPortal = lazy(() => import("./components/DashboardPortal/DashboardPortal"));

const AppRouter = () => {
    const isAuth = useAppSelector((state) => state.auth.isAuth);
    const user = useAppSelector((state) => state.auth.user);
    const tenantType = useAppSelector((state) => state.auth.tenantType);

    return (
        <div className="min-height">
            <Suspense
                fallback={
                    <Container className="spiner-container flex-column">
                        <img src={preloader} style={{ marginLeft: 40 }} alt="Loading..." width="150" height="150"></img>
                    </Container>
                }
            >
                {isAuth && user ? (
                    tenantType === "WAREHOUSE" ? (
                        <Routes>
                            <Route path="/dashboard-warehouse/*" element={<DashboardWarehouse />} />
                            <Route path="/maintenance" element={<MaintenancePage />} />
                            <Route path="*" element={<Navigate to="/dashboard-warehouse" />} />
                        </Routes>
                    ) : tenantType === "DEFAULT" ? (
                        <Routes>
                            <Route path="/dashboard/*" element={<Dashboard />} />
                            <Route path="/payment-confirm" element={<PaymentConfirm />} />
                            <Route path="/docusign/" element={<ConfirmDocusign />} />
                            <Route path="/login-amazon/" element={<ConfirmAmazonLink />} />
                            <Route path="/maintenance" element={<MaintenancePage />} />
                            <Route path="*" element={<Navigate to="/dashboard" />} />
                        </Routes>
                    ) : (
                        tenantType === "FREIGHT_FORWARDER" && (
                            <Routes>
                                <Route path="/dashboard-portal/*" element={<DashboardPortal />} />
                                <Route path="/maintenance" element={<MaintenancePage />} />
                                <Route path="*" element={<Navigate to="/dashboard-portal" />} />
                            </Routes>
                        )
                    )
                ) : (
                    <Routes>
                        <Route path="/add-user-to-tenannt/:id/:token" element={<AddUserToTenantPage />} />
                        <Route path="/add-authorized-user-to-tenannt/:id/:token" element={<AddAuthorizedUserToTenant />} />
                        <Route path="/register" element={<Auth />} />
                        <Route path="/login" element={<SignInContainer />} />
                        <Route path="/reset-password/:id?/:token?" element={<ResetContainer />} />
                        <Route path="/login-amazon/" element={<ConfirmAmazonLink />} />
                        <Route path="/confirm-email/:id/:token" element={<ConfirmEmail />} />
                        <Route path="/first-case-study" element={<CaseStudy1 />} />
                        <Route path="/second-case-study" element={<CaseStudy2 />} />
                        <Route path="/terms-conditions" element={<Terms />} />
                        <Route path="/privacy-policy" element={<Policy />} />
                        <Route path="/maintenance" element={<MaintenancePage />} />
                        <Route path="/" element={<LandingPage />} />
                        <Route path="*" element={<Navigate to="/login" />} />
                    </Routes>
                )}
            </Suspense>
        </div>
    );
};

export default AppRouter;
