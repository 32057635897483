import Cookies from "universal-cookie";

import { instance } from "./authAPI";
import { processRequestQueryParams } from "./utils/apiUtils";
import { TGenericResponseFormat, TRequestQueryParams } from "../redux/types/common/common.types";
import { TPastInboundShipment, TPastOwnFFShipment, TPastQuoteShipment, TPastQuoteShipmentDetails, TPastSKUdropShipment } from "../redux/types/SettingsTypes/settings.types";

export const userPastShipmentsApi = {
    getPastInboundShipments(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TGenericResponseFormat<TPastInboundShipment>>(`/shipments/user/dashboard/incoming-shipment-history/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getPastSKUdropShipments(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TGenericResponseFormat<TPastSKUdropShipment>>(`/shipments/user/dashboard/outgoing-shipment-history/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getPastOwnFFShipments(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TGenericResponseFormat<TPastOwnFFShipment>>(`/shipments/user/dashboard/off-shipment-history/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    getPastQuoteShipments(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TGenericResponseFormat<TPastQuoteShipment>>(`/manual_quoting/dashboard/manual-quote-history/${processRequestQueryParams(queryParams)}`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
    // Past Shipments Details
    getPastQuoteShipmentDetails(cookies: Cookies, queryParams: TRequestQueryParams) {
        return instance.get<TPastQuoteShipmentDetails>(`/manual_quoting/dashboard/manual-quote-details-history/${queryParams.id}/`, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },
};
