import React, { useState, useEffect } from "react";
import UK_shipping_desctop from "../../../assets/img/LandingPage/UKShippingSection/UK_shipping_desktop.png";
import UK_shipping_mobile from "../../../assets/img/LandingPage/UKShippingSection/UK_shipping_mobile.png";

const UKShippingSection = () => {
    const [mobile, setMobile] = useState(false);

    const handleResize = () => {
        if (window.innerWidth < 990) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    };

    // create an event listener
    useEffect(() => {
        window.addEventListener("resize", handleResize);
    });

    useEffect(() => {
        handleResize();
    }, []);

    return (
        <div className="py-5 pl-bg d-flex align-items-center justify-content-center">
            <div className="uk-shipping-section">
                <h1 className="story-title text-center">One warehouse, multiple destinations</h1>
                <p className="story-text text-center w-60 mt-3 mb-0">
                    Seize the reins of your global Amazon marketplace distribution with unmatched precision and technology. SKUdrop is your gateway to both the US and UK
                    marketplaces.
                    <br />
                    Consolidate all your goods under one roof, harnessing unparalleled control to distribute cartons effortlessly worldwide. Our cutting-edge software management
                    system puts you in the driver's seat, allowing seamless control over your UK or US carton distribution with a simple tap of a key.
                </p>
                <img className="w-80 mt-2" src={mobile ? UK_shipping_mobile : UK_shipping_desctop} alt="uk_shipping_section" />
            </div>
        </div>
    );
};

export default UKShippingSection;
