import ReactGA from "react-ga";
import { useEffect, useState } from "react";
import { Container, Modal } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router";

import { confirmLinkDocuSign } from "../../../../redux/authReducer";
import { useAppDispatch, useAppSelector } from "../../../../redux/ReduxStore";

import preloader from "../../../../assets/video/preloader.webp";

const ConfirmDocusign = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const cookies = useAppSelector((state) => state.auth.cookies);
    const [show, setShow] = useState(false);
    const user_id = location.search.split("?")[1].split("&")[0].split("=")[1];
    const user_token = location.search.split("?")[1].split("&")[1].split("=")[1];
    const country_code = location.search.split("?")[1].split("&")[2].split("=")[1];
    const event = location.search.split("?")[1].split("&")[3].split("=")[1];

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    const redirectToDashboard = () => {
        const redirectPath = cookies.get("docusignPageRedirect");
        cookies.remove("docusignPageRedirect", { path: "/", secure: true });

        navigate(redirectPath ? redirectPath : "/dashboard/add-product-from-amazon/");
    };

    const onShowModal = () => {
        setShow(true);
    };

    const onHideModal = (status: boolean) => {
        setShow(false);
        redirectToDashboard();
    };

    useEffect(() => {
        if (user_id && user_token && event) {
            dispatch(confirmLinkDocuSign(user_id, user_token, country_code, event, onShowModal, cookies));
        }
    }, []);

    return (
        <Container className="spiner-container container-bg">
            <img src={preloader} style={{ marginLeft: 40 }} width="150" height="150" alt="Loading..."></img>
            <Modal
                show={show}
                onHide={() => {
                    onHideModal(false);
                }}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{country_code === "UK" ? "UK Shipping Preparation" : "Power of Attorney"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="mb-0 text-center">
                        {country_code === "UK"
                            ? "We are reviewing your documents and will approve them shortly."
                            : "Thank you, we have received your POA. A copy has been emailed to you."}
                    </p>
                </Modal.Body>
            </Modal>
        </Container>
    );
};

export default ConfirmDocusign;
