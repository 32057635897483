import React, { useEffect, useState } from "react";
import HeroGraphics from "../../../assets/img/LandingPage/HeroSection/HeroGraphic.png";
import hero_mobile from "../../../assets/img/LandingPage/HeroSection/Hero_mobile.png";
import { useNavigate } from "react-router";

const HeroSection = () => {
    const navigate = useNavigate();
    const [mobile, setMobile] = useState(false);

    const handleResize = () => {
        if (window.innerWidth < 990) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    };

    // create an event listener
    useEffect(() => {
        window.addEventListener("resize", handleResize);
    });

    useEffect(() => {
        handleResize();
    }, []);

    return (
        <div aria-label="hero-section" className="py-10 hero-section d-flex flex-column justify-content-start align-items-center">
            <div className="hero-block">
                <span aria-label="hero-section-title" className="hero-title title-position">
                    <b style={{ color: "#F96251" }}>WE STOP YOU LOSING MONEY</b> IN YOUR SUPPLY CHAIN
                </span>
                <p className="hero-desc ">
                    SKUdrop manages the flow of cartons between your supplier's factory and Amazon FBA. We provide cost effective storage in China, direct to Amazon shipping
                    options, and software designed to save you time and money.
                </p>
                <img src={mobile ? hero_mobile : HeroGraphics} className="hero-img" alt="" />
                <button className="lp_button hero-btn mb-5" onClick={() => navigate("/register")}>
                    Get Started
                </button>
            </div>
        </div>
    );
};

export default HeroSection;
