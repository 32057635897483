import ReactGA from "react-ga";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router";
import { Container, Form, Col, Row, Button } from "react-bootstrap";

import { schemaSecondStep } from "../SignUp/validation/validation";
import { ConfirmAddedUserToTenant } from "../../../redux/settingsReducer";
import { useAppDispatch, useAppSelector } from "../../../redux/ReduxStore";

import InfoBlock from "../InfoBlock/InfoBlock";
import skudropLogo from "../../../assets/img/skudrop-logo.png";

export type AddUserSubmitDataType = {
    password: string;
    confirmPassword: string | undefined;
};

export type AddUserUrlParamsType = {
    id: string;
    token: string;
};

const AddUserToTenantPage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const cookies = useAppSelector((state) => state.auth.cookies);

    const { id, token } = useParams<AddUserUrlParamsType>();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<AddUserSubmitDataType>({
        resolver: yupResolver(schemaSecondStep),
    });

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    const onSubmit = (data: AddUserSubmitDataType) => {
        if (id && token) {
            dispatch(ConfirmAddedUserToTenant(id, token, data.password, cookies));
            navigate("/login");
        }
    };
    return (
        <Container className="container-bg" style={{ margin: 0, maxWidth: "100%", padding: 0 }}>
            <Row>
                <Col xl={6} style={{ padding: 0 }}>
                    <InfoBlock />
                </Col>
                <Col xl={6} style={{ padding: 0 }}>
                    <Container className="block info-block auth-form-container container-bg m-0 mw-100 p-0">
                        <Form onSubmit={handleSubmit(onSubmit)} className="auth-form shadow-sm">
                            <Row className="d-flex justify-content-center">
                                <img src={skudropLogo} className="skudrop-logo my-5" alt="log" />
                            </Row>
                            <Row className="mb-2 mt-3">
                                <Col>
                                    <h4>Create password</h4>
                                </Col>
                            </Row>
                            <Form.Group className="mb-4" controlId="formBasicFirstName">
                                <Form.Control className={errors.password ? "p-2 input" : "p-2"} type="password" placeholder="Password" {...register("password")} />
                                {errors.password && <p className="mb-0 error-message">{errors.password.message}</p>}
                            </Form.Group>
                            <Form.Group className="mb-4" controlId="formBasicConfirmPassword">
                                <Form.Control className={errors.password ? "p-2 input" : "p-2"} type="password" placeholder="Confirm Password" {...register("confirmPassword")} />
                                {errors.confirmPassword && <p className="mb-0 error-message">{errors.confirmPassword.message}</p>}
                            </Form.Group>
                            <Button variant="primary" type="submit">
                                CONFIRM PASSWORD
                            </Button>
                        </Form>
                    </Container>
                </Col>
            </Row>
        </Container>
    );
};
export default AddUserToTenantPage;
