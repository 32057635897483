import OwnFFSectionImg from "../../../assets/img/LandingPage/OwnFFSection/own_ff_section.png";

const OwnFFSection = () => {
    return (
        <div id="shipping-flexibility" className="py-5 feature-bg pl-bg d-flex align-items-center justify-content-center">
            <div className="own-ff-section-wrapper">
                <div>
                    <h1 className="own-ff-section-label">Shipping flexibility at your finger tips</h1>
                    <p className="own-ff-section-desc-paragraph">
                        We're thrilled to offer a feature that puts you in control of your shipping process. Understanding the importance of flexibility and customization in
                        optimizing your logistics, we provide the option for you to use your own freight forwarders.
                    </p>
                    <p className="own-ff-section-desc-paragraph">
                        This approach empowers you to select the shipping solution that best fits your unique needs, whether it's for cost-efficiency, speed, or reliability.
                    </p>
                    <p className="own-ff-section-desc-paragraph">
                        By allowing you to simply use your own freight forwarder with our platform, you gain unprecedented control over your shipping operations. This enables a
                        more tailored logistics experience and enhances your ability to manage costs and streamline your supply chain.
                    </p>
                    <p className="own-ff-section-desc-paragraph">Embrace the freedom to choose, and make your logistics work for you, not the other way around.</p>
                </div>
                <div className="own-ff-section-img-container">
                    <img className="own-ff-section-img" src={OwnFFSectionImg} alt="Own Freight Forwarders" />
                </div>
            </div>
        </div>
    );
};

export default OwnFFSection;
