import React, { useEffect, useState } from "react";
import storyImage from "../../../assets/img/LandingPage/StorySection/STORY_OF_SKUDROP.png";
import storyImageMobile from "../../../assets/img/LandingPage/StorySection/STORY_OF_SKUDROP_MOBILE.png";

const StorySection = () => {
    const [mobile, setMobile] = useState(false);

    const handleResize = () => {
        if (window.innerWidth < 990) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    };

    // create an event listener
    useEffect(() => {
        window.addEventListener("resize", handleResize);
    });

    useEffect(() => {
        handleResize();
    }, []);
    return (
        <div className="py-5 feature-bg d-flex align-items-center justify-content-center">
            <div className="basis-container">
                <div className="first-block-basis">
                    <h3 className="story-title">The story of SKUdrop</h3>
                    <p className="story-text story-padding">
                        One day, a group of 7-figure sellers jumped on a zoom call to discuss a massive problem around how Amazon sellers manage their supply chain.
                    </p>
                    <p className="story-text story-padding">
                        Instead of continuing to do the same old supply chain sequence over and over, they decided that enough is enough and began the journey to find a solution to
                        this big problem.
                    </p>
                    <p className="story-text story-padding">
                        After 15 months of research, late night zoom calls, testing, planning, failures, and navigating 3 different time zones, SKUdrop was born, and the problem
                        was solved.
                    </p>
                    <p className="story-text story-padding">
                        The SKUdrop team have cracked the supply chain code, and by joining the SKUdrop family, you save time, and keep more cash in your pocket.
                    </p>
                    <p className="story-text story-padding">Welcome to the new way of managing your Amazon FBA supply chain.</p>
                </div>
                <div className="second-block-basis">
                    <img className="story-image" src={mobile ? storyImageMobile : storyImage} alt="" />
                </div>
            </div>
        </div>
    );
};

export default StorySection;
