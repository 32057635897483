import { useEffect } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";

import { registerComplete } from "../../../redux/authReducer";
import { useAppDispatch, useAppSelector } from "../../../redux/ReduxStore";

import preloader from "../../../assets/video/preloader.webp";
import WELCOME_image from "../../../assets/img/SignUpComplete/WELCOME_image.jpg";
import registerCompleteSVG from "../../../assets/img/SignUpComplete/skudropO.png";

const SignUpComplete = () => {
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector((state) => state.auth.isLoading);
    const cookies = useAppSelector((state) => state.auth.cookies);

    useEffect(() => {
        dispatch(registerComplete(cookies));
    }, []);

    if (isLoading) {
        return (
            <Container className="block auth-form-container container-bg">
                <Form className="auth-form d-flex align-items-center justify-content-center shadow-sm">
                    <img src={preloader} alt="Loading..." style={{ marginLeft: 40 }} width="150" height="150"></img>
                </Form>
            </Container>
        );
    }

    return (
        <Container className="block auth-form-container container-bg p-0 justify-content-start position-relative">
            <div
                className="d-flex flex-column align-items-center justify-content-center w-100 h-100 signup-complete-background-image"
                style={{ backgroundImage: `url(${WELCOME_image})` }}
            >
                <Form className="complete">
                    <Row>
                        <Col className="d-flex justify-content-center">
                            {registerCompleteSVG && <img src={registerCompleteSVG} className="register-complete-image" style={{ width: "40%" }} alt="logo"></img>}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-flex justify-content-center flex-column text-center">
                            <h2 className="complete-title text-white">Welcome to SKUdrop</h2>
                            <p className="my-2 complete-h2-text text-white fz-20">
                                We are creating your account and you'll receive an email shortly with a link to your SKUdrop account.
                            </p>
                            <p className="my-2 complete-h2-text text-white fz-20">
                                Our team are on standby to make your supply chain as efficient as possible. Thank you for choosing our service.
                            </p>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Container>
    );
};

export default SignUpComplete;
