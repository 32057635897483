import { FC } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import { MdCheckCircle } from "react-icons/md";
import { CompareSkuDropData } from "../FreightForwarderVsSKUdrop";

export type CompareTablePropsType = {
    image: string;
    data: CompareSkuDropData[];
    mobile: boolean;
};

const CompareTable: FC<CompareTablePropsType> = ({ image, data, mobile }) => {
    return (
        <div className="data-table mt-4">
            {mobile ? (
                <div className="main-table-wrapper w-100">
                    <div className="w-100 d-flex flex-column">
                        <div className="table-header-mobile w-100 d-flex flex-row justify-content-center align-items-center mb-3">
                            <div className="d-flex flex-row justify-content-center w-100">
                                <div className="w-50 d-flex flex-row align-items-center justify-content-center">
                                    <h5 className="mb-0 py-3 text-center">
                                        Freight <br /> Forwarders
                                    </h5>
                                </div>
                                <div className="w-50 d-flex flex-row align-items-center justify-content-center">
                                    <img className="w-60 max-w-260px py-3" src={image} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="w-100">
                            {data.map((item, i) => (
                                <div key={i} className="w-100 d-flex flex-column justify-content-start align-items-center px-4">
                                    <div className="d-flex flex-row align-items-center justify-content-center w-100 pb-3 border-bottom">
                                        <p className="mb-0 fz-18px font-weight-bold text-center avenir-book-400">{item.question}</p>
                                    </div>
                                    <div className="d-flex flex-row align-items-start justify-content-center w-100 py-4">
                                        <div className="w-50 d-flex flex-column align-items-center justify-content-center">
                                            <div className="d-flex flex-row justify-content-center align-items-center">
                                                <h3 className="mb-0 mr-2 mt-1">{item?.answer_freight_forward?.title}</h3>
                                                <IoMdCloseCircle size={30} color="#FF0000" />
                                            </div>
                                            {item.answer_freight_forward.description && (
                                                <p className="mb-0 mt-2 text-center fz-13px px-3 max-w-260px avenir-book-400">{item.answer_freight_forward.description}</p>
                                            )}
                                        </div>
                                        <div className="w-50 d-flex flex-column align-items-center justify-content-center">
                                            <div className="d-flex flex-row justify-content-center align-items-center">
                                                <h3 className="mb-0 mr-2 mt-1">{item?.answer_skudrop?.title}</h3>
                                                <MdCheckCircle size={30} color="#32CD32" />
                                            </div>
                                            {item.answer_skudrop.description && (
                                                <p className="mb-0 mt-2 text-center fz-13px px-3 max-w-260px avenir-book-400">{item.answer_skudrop.description}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            ) : (
                <div className="main-table-wrapper w-100">
                    <div className="w-100 d-flex flex-column">
                        <div className="table-header-container w-100 d-flex flex-row justify-content-between align-items-center">
                            <div className="header-left-col d-flex justify-content-center w-30"></div>
                            <div className="w-70 d-flex flex-row justify-content-around">
                                <div className="header-col justify-content-center">
                                    <h5 className="mb-0 py-3">Freight Forwarders</h5>
                                </div>
                                <div className="header-col">
                                    <img className="w-60 py-3" src={image} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="w-100">
                            {data.map((item, i) => (
                                <div key={i} className="w-100 d-flex flex-row justify-content-between align-items-center">
                                    <div className="header-left-col w-30 ml-4">
                                        <p className="mb-0 fz-18px avenir-book-400">{item.question}</p>
                                    </div>
                                    <div className="w-70 d-flex flex-row justify-content-around position-relative">
                                        <div className={`header-col container-in-td w-100 py-4 ${!data[i - 1] ? "top-border" : ""} ${!data[i + 1] ? "bottom-border" : null}`}>
                                            <div className="d-flex flex-row justify-content-center">
                                                <h3 className="mb-0 mr-2">{item?.answer_freight_forward?.title}</h3>
                                                <IoMdCloseCircle size={30} color="#FF0000" />
                                            </div>
                                            {item.answer_freight_forward.description && (
                                                <p className="mb-0 text-center fz-13px px-3 avenir-book-400">{item.answer_freight_forward.description}</p>
                                            )}
                                        </div>
                                        {i === 2 ? <h1 className="vs-position">VS</h1> : null}
                                        <div className={`header-col container-in-td w-100 py-4 ${!data[i - 1] ? "top-border" : ""} ${!data[i + 1] ? "bottom-border" : null}`}>
                                            <div className="d-flex flex-row justify-content-center">
                                                <h3 className="mb-0 mr-2">{item?.answer_skudrop?.title}</h3>
                                                <MdCheckCircle size={30} color="#32CD32" />
                                            </div>
                                            {item.answer_skudrop.description && <p className="mb-0 text-center fz-13px px-3 avenir-book-400">{item.answer_skudrop.description}</p>}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CompareTable;
