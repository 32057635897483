import React from "react";
import { useNavigate } from "react-router-dom";
import caseStudy1 from "../../../assets/img/LandingPage/SaveMoneySection/case_study_1.png";
import caseStudy2 from "../../../assets/img/LandingPage/SaveMoneySection/case_study_2.png";

const SaveMoney = () => {
    const navigate = useNavigate();
    return (
        <div id="case-study" className="py-5 pl-bg d-flex align-items-center justify-content-center w-100 flex-column">
            <h1 className="section-name save-money-title text-center mb-3rem">SKUdrop saves you money</h1>
            <div className="w-90 save-money-block p-2">
                <div className="case-study py-2">
                    <div className="w-20 save-money-img">
                        <img src={caseStudy1} className="case-study-image" alt="" />
                    </div>
                    <div className="d-flex flex-column w-60 save-money-content">
                        <div>
                            <span className="case-study-title">
                                <b style={{ color: "#F96251" }}>CASE STUDY #1 </b> -SKUdrop vs Full Container Load (FCL)
                            </span>
                        </div>
                        <div className="d-flex flex-row mt-3 save-money-numbers ">
                            <div className="pr-4">
                                <h3 className="case-study-numbers">$14,972</h3>
                                <p>Saved in hard supply chain costs by using SKUdrop</p>
                            </div>
                            <div className="px-4">
                                <h3 className="case-study-numbers">31.9%</h3>
                                <p>Reduction in storage fees by storing at SKUdrop</p>
                            </div>
                            <div className="px-4">
                                <h3 className="case-study-numbers">26%</h3>
                                <p>Reduction in prep fees by using SKUdrop </p>
                            </div>
                        </div>
                    </div>
                    <div className="save-money-content d-flex justify-content-center">
                        <button className="lp_button" onClick={() => navigate("/first-case-study")}>
                            VIEW CASE STUDY
                        </button>
                    </div>
                </div>
                <div className="line"></div>
                <div className="case-study py-2">
                    <div className="w-20 save-money-img">
                        <img src={caseStudy2} className="case-study-image" alt="" />
                    </div>
                    <div className="d-flex flex-column w-60 save-money-content">
                        <div>
                            <span className="case-study-title">
                                <b style={{ color: "#F96251" }}>CASE STUDY #2 </b> -SKUdrop vs Less than Container Load (LCL)
                            </span>
                        </div>
                        <div className="d-flex flex-row mt-3 save-money-numbers">
                            <div className="pr-4">
                                <h3 className="case-study-numbers">$2,474</h3>
                                <p>Saved in hard supply chain costs by using SKUdrop</p>
                            </div>
                            <div className="px-4">
                                <h3 className="case-study-numbers">31.9%</h3>
                                <p>Reduction in storage fees by storing at SKUdrop</p>
                            </div>
                            <div className="px-4">
                                <h3 className="case-study-numbers">42.9%</h3>
                                <p>Reduction in prep fees by using SKUdrop </p>
                            </div>
                        </div>
                    </div>
                    <div className="save-money-content d-flex justify-content-center">
                        <button
                            className="lp_button"
                            onClick={() => {
                                navigate("/second-case-study");
                            }}
                        >
                            VIEW CASE STUDY
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SaveMoney;
