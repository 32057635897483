import { useState, useEffect } from "react";

import { getFBAList, postFBAList } from "../../../redux/authReducer";
import { useAppDispatch, useAppSelector } from "../../../redux/ReduxStore";
import { getMinMedMaxValue } from "../../DashboardWarehouse/common/Constants";

import Calculator from "./Calculator";
import OversizeIssue from "./OversizeIssue";
import Quize from "./Quize";
import Summary from "./Summary";
import DeliveryMethodIssue from "./DeliveryMethodIssue";

export type SkuListType = {
    name: string;
};

export type SubmitDataType = {
    length_0: number;
    width_0: number;
    height_0: number;
    weight_0: number;
    number_of_cartons_0: number;
    warehouse_id: string;
};

export type DataObjectType = {
    cartons: {
        weight: number;
        weight_measure: string;
        length: number;
        width: number;
        height: number;
        dimensions_measure: string;
        number_of_cartons: number;
    }[];
    quantity_of_month: number;
    warehouse_id: string;
};

export type MonthDataType = {
    text: string;
};

export type StepDataType = {
    data: string;
    step: number | null;
};

const CalculatorSection = () => {
    const [step, setStep] = useState<string | number>(0);
    const [stepData, setStepData] = useState<StepDataType[]>([]);
    const [monthButtons, setMonthButtons] = useState<MonthDataType[]>([]);
    const [dimension, setDimension] = useState(false);
    const [pound, setPound] = useState(false);
    const [selectedMarket, setSelectedMarket] = useState("US");
    const [marketWarehouses, setMarketWarehouses] = useState<string[] | null>(null);
    const [skuList] = useState([
        {
            name: "SKU 1",
        },
    ]);
    const [requestData, setRequestData] = useState({});
    const fbaList = useAppSelector((state) => state.auth.fbaList);
    const calculatedData = useAppSelector((state) => state.auth.calculatedData);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getFBAList());
    }, [dispatch]);

    useEffect(() => {
        let findedMarket = fbaList && fbaList.find((item) => item.market === selectedMarket);
        if (findedMarket) {
            if (selectedMarket === "US") {
                let first = "ONT8";
                setMarketWarehouses(
                    findedMarket.warehouse_ids.sort(function (x, y) {
                        return x === first ? -1 : y === first ? 1 : 0;
                    })
                );
            } else {
                setMarketWarehouses(findedMarket.warehouse_ids);
            }
        }
    }, [selectedMarket, fbaList]);

    useEffect(() => {
        if (calculatedData) {
            if (selectedMarket === "US" && !calculatedData.final_price && !calculatedData.air_price) {
                setStep("delivery_error");
            } else if (selectedMarket === "UK" && !calculatedData.sea_rate && !calculatedData.truck_rate) {
                setStep("delivery_error");
            }
        }
    }, [calculatedData]);

    const onChangeMarket = (market: string) => {
        if (market !== selectedMarket) {
            setSelectedMarket(market);
        }
    };

    const onClickCalculate = (error: string | null) => {
        setStep(error ? error : Number(step) + 1);
    };

    const onSubmit = (data: SubmitDataType) => {
        const MAX_FORMULA_VALUE = 266;
        const MAX_DIMANSION_VALUE = 120;
        const MAX_WEIGHT_VALUE = 22;
        const weight_measure = pound ? "pound" : "kg";
        const dimensions_measure = dimension ? "inch" : "cm";
        const warehouse_id = data.warehouse_id === "" ? marketWarehouses && marketWarehouses[0] : data.warehouse_id;
        let dataObj: DataObjectType | null = null;
        let number_of_cartons: number | undefined;
        let result: number | undefined;
        let weight: number | undefined;
        skuList.forEach((item, index) => {
            number_of_cartons = data[`number_of_cartons_${index}` as keyof SubmitDataType] as number;
            let height =
                dimensions_measure === "inch"
                    ? parseFloat(data[`height_${index}` as keyof SubmitDataType] as string) * 2.54
                    : (data[`height_${index}` as keyof SubmitDataType] as number);
            let width =
                dimensions_measure === "inch"
                    ? parseFloat(data[`width_${index}` as keyof SubmitDataType] as string) * 2.54
                    : (data[`width_${index}` as keyof SubmitDataType] as number);
            let length =
                dimensions_measure === "inch"
                    ? parseFloat(data[`length_${index}` as keyof SubmitDataType] as string) * 2.54
                    : (data[`length_${index}` as keyof SubmitDataType] as number);
            weight =
                weight_measure === "pound"
                    ? parseFloat(data[`weight_${index}` as keyof SubmitDataType] as string) * 0.45359
                    : (data[`weight_${index}` as keyof SubmitDataType] as number);

            let productDimantions = [Number(height), Number(width), Number(length)];
            const { min, medium, max } = getMinMedMaxValue(productDimantions);

            result = (medium + min) * 2 + max;
            console.log(min, medium, max, result);
            dataObj = {
                cartons: [
                    {
                        weight: data[`weight_${index}` as keyof SubmitDataType] as number,
                        weight_measure: weight_measure,
                        length: data[`length_${index}` as keyof SubmitDataType] as number,
                        width: data[`width_${index}` as keyof SubmitDataType] as number,
                        height: data[`height_${index}` as keyof SubmitDataType] as number,
                        dimensions_measure: dimensions_measure,
                        number_of_cartons: number_of_cartons,
                    },
                ],
                quantity_of_month: 0,
                warehouse_id: warehouse_id as string,
            };
        });
        let monthButtonsData = [];
        if (number_of_cartons) {
            for (let i = 0; i < parseInt((number_of_cartons / 4).toString(), 10); i++) {
                monthButtonsData.length < 6 && monthButtonsData.push({ text: i === 0 ? `${i + 1} Month` : `${i + 1} Months` });
            }
        }
        setMonthButtons(monthButtonsData);
        console.log(dataObj);
        if (dataObj && number_of_cartons) {
            result && result < MAX_FORMULA_VALUE && setRequestData(dataObj);
            number_of_cartons < 4 && dispatch(postFBAList(selectedMarket, dataObj, setStep));
        }
        if (
            result &&
            dataObj &&
            weight &&
            (result >= MAX_FORMULA_VALUE ||
                (dataObj as DataObjectType).cartons[0].width >= MAX_DIMANSION_VALUE ||
                (dataObj as DataObjectType).cartons[0].height >= MAX_DIMANSION_VALUE ||
                (dataObj as DataObjectType).cartons[0].length >= MAX_DIMANSION_VALUE ||
                weight >= MAX_WEIGHT_VALUE)
        ) {
            onClickCalculate("error");
        } else {
            onClickCalculate(null);
        }
    };

    const nextStep = (data: string, customStep: null | number, submitRequest: boolean) => {
        setStep(customStep ? customStep : Number(step) + 1);
        step === 3 && setStepData([...stepData, { data: data, step: step }]);
        if (submitRequest) {
            let dataObj = requestData as DataObjectType;
            dataObj.quantity_of_month = Number(data.split(" ")[0]);

            dispatch(postFBAList(selectedMarket, dataObj, setStep));
        }
    };

    const onResetCalculator = () => {
        setStep(0);
        setStepData([]);
        setMonthButtons([]);
        setDimension(false);
        setPound(false);
        setRequestData({});
    };

    return (
        <div id="calculator" className="feature-bg d-flex flex-column align-items-center justify-content-center pb-5 min-h-500">
            {step === 0 && (
                <Calculator
                    marketWarehouses={marketWarehouses}
                    onSubmit={onSubmit}
                    setDimension={setDimension}
                    setPound={setPound}
                    skuList={skuList}
                    dimension={dimension}
                    pound={pound}
                    selectedMarket={selectedMarket}
                    onChangeMarket={onChangeMarket}
                />
            )}
            {step === 1 && (
                <Quize
                    question={"While we are calculating your shipment we have a few quick questions..."}
                    buttons={[{ text: "LET’S GO" }]}
                    nextStep={nextStep}
                    customStep={monthButtons.length > 0 ? null : 3}
                    submitRequest={false}
                    onResetCalculator={onResetCalculator}
                />
            )}
            {step === 2 && monthButtons.length > 0 && (
                <Quize
                    question={"How long is this orders inventory cycle?"}
                    buttons={monthButtons}
                    nextStep={nextStep}
                    customStep={null}
                    submitRequest={true}
                    onResetCalculator={onResetCalculator}
                />
            )}
            {step === 3 && (
                <Quize
                    question={"Do you use a 3PL?"}
                    buttons={[{ text: "YES" }, { text: "NO" }]}
                    nextStep={nextStep}
                    customStep={null}
                    submitRequest={false}
                    onResetCalculator={onResetCalculator}
                />
            )}
            {step === 4 && (
                <Summary is3PL={stepData[0].data === "YES" ? true : false} selectedMarket={selectedMarket} calculatedData={calculatedData} onResetCalculator={onResetCalculator} />
            )}
            {step === "error" && <OversizeIssue onResetCalculator={onResetCalculator} />}
            {step === "delivery_error" && <DeliveryMethodIssue onResetCalculator={onResetCalculator} />}
        </div>
    );
};

export default CalculatorSection;
