import { useState, useEffect, FC } from "react";
import { Form, Button, ButtonGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { SkuListType, SubmitDataType } from "./CalculatorSection";
import { handleWheel } from "../../common/componentsUtils";
import CompactMarketsButtons from "../../Dashboard/common/ButtonGroup/CompactMarketButtons";

export type CalculatorPropsType = {
    marketWarehouses: string[] | null;
    onSubmit: (data: SubmitDataType) => void;
    setDimension: (value: boolean) => void;
    setPound: (value: boolean) => void;
    skuList: SkuListType[];
    pound: boolean;
    dimension: boolean;
    selectedMarket: string;
    onChangeMarket: (market: string) => void;
};

const Calculator: FC<CalculatorPropsType> = ({ marketWarehouses, onSubmit, setDimension, setPound, skuList, pound, dimension, selectedMarket, onChangeMarket }) => {
    const { register, handleSubmit, setValue } = useForm<SubmitDataType>({
        mode: "onChange",
        reValidateMode: "onChange",
    });

    const [mobile, setMobile] = useState(false);

    const handleResize = () => {
        if (window.innerWidth < 990) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    };

    // create an event listener
    useEffect(() => {
        window.addEventListener("resize", handleResize);
    });

    useEffect(() => {
        handleResize();
    }, []);

    useEffect(() => {
        if (marketWarehouses) {
            setValue("warehouse_id", marketWarehouses[0]);
        }
    }, [selectedMarket, marketWarehouses]);

    return (
        <div className="d-flex flex-column align-items-center justify-content-center w-100">
            {mobile ? (
                <div className="d-flex flex-column align-items-center justify-content-center w-100">
                    <div className="d-flex flex-column align-items-center justify-content-center">
                        <h3 className="text-center story-title w-100 px-3 ">Test Drive Our Rates... </h3>
                        <p className="p-landing-page px-3 text-center">Remember, the SKUdrop model works best with regular weekly shipments</p>
                    </div>
                    <div className="d-flex flex-column align-items-start justify-content-center pt-5 px-3 pb-4 border position-relative min-h-350 w-9 bg-white">
                        <div className="d-flex flex-wrap align-items-center justify-content-center mb-3 gap-3">
                            <h3 className="mb-0 text-center">Select your market</h3>
                            <CompactMarketsButtons tabState={selectedMarket} setTabState={onChangeMarket} />
                        </div>
                        <h3 className="mb-2 text-center">Enter your carton details below</h3>
                        <Form className="d-flex flex-row align-items-start justify-content-start w-100 mobile-opt" noValidate onSubmit={handleSubmit(onSubmit)}>
                            <div className="w-100">
                                <div className="d-flex flex-row align-items-center justify-content-evenly my-3">
                                    <div className="d-flex align-items-center justify-content-center position-relative middle-line-calculator w-140px  m-0">
                                        <div className="d-flex flex-row align-items-center justify-content-center position-absolute bg-white px-2">
                                            <p className="fz-13 m-0"> cm </p>
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                checked={dimension}
                                                onChange={() => {
                                                    setDimension(!dimension);
                                                }}
                                            />
                                            <p className="fz-13 m-0"> in </p>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-center w-140px ">
                                        <p className="fz-13 m-0"> kg </p>
                                        <Form.Check
                                            type="switch"
                                            id="switch-weight"
                                            checked={pound}
                                            onChange={() => {
                                                setPound(!pound);
                                            }}
                                        />
                                        <p className="fz-13 m-0"> lb </p>
                                    </div>
                                </div>
                                {skuList &&
                                    skuList.map((sku, index) => (
                                        <div key={index} className="d-flex flex-column align-items-center justify-content-center">
                                            <div className="d-flex flex-column align-items-center justify-content-evenly w-100">
                                                <div className="d-flex flex-row align-items-center justify-content-evenly w-100">
                                                    <div className="d-flex flex-row align-items-center justify-content-center position-relative my-4">
                                                        <p className="select-label w-140px ">Carton length</p>
                                                        <Form.Control
                                                            type="number"
                                                            onWheel={handleWheel}
                                                            defaultValue={"0"}
                                                            className="select-calculator w-140px "
                                                            {...register(`length_${index}` as keyof SubmitDataType, {
                                                                required: true,
                                                                valueAsNumber: true,
                                                                min: { value: 1, message: "Number must be greater than 0" },
                                                                max: { value: 243, message: "Number must be lower than 243" },
                                                            })}
                                                        />
                                                    </div>
                                                    <div className="d-flex flex-row align-items-center justify-content-center position-relative my-4">
                                                        <p className="select-label w-140px ">Carton width</p>
                                                        <Form.Control
                                                            type="number"
                                                            onWheel={handleWheel}
                                                            defaultValue={"0"}
                                                            className="select-calculator w-140px "
                                                            {...register(`width_${index}` as keyof SubmitDataType, {
                                                                required: true,
                                                                valueAsNumber: true,
                                                                min: { value: 1, message: "Number must be greater than 0" },
                                                                max: { value: 243, message: "Number must be lower than 243" },
                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-row align-items-center justify-content-evenly w-100">
                                                    <div className="d-flex flex-row align-items-center justify-content-center position-relative my-4">
                                                        <p className="select-label w-140px ">Carton height</p>
                                                        <Form.Control
                                                            type="number"
                                                            onWheel={handleWheel}
                                                            defaultValue={"0"}
                                                            className="select-calculator w-140px "
                                                            {...register(`height_${index}` as keyof SubmitDataType, {
                                                                required: true,
                                                                valueAsNumber: true,
                                                                min: { value: 1, message: "Number must be greater than 0" },
                                                                max: { value: 243, message: "Number must be lower than 243" },
                                                            })}
                                                        />
                                                    </div>
                                                    <div className="d-flex flex-row align-items-center justify-content-center position-relative my-4">
                                                        <p className="select-label w-140px ">Carton weight</p>
                                                        <Form.Control
                                                            type="number"
                                                            onWheel={handleWheel}
                                                            defaultValue={"0"}
                                                            className="select-calculator w-140px "
                                                            {...register(`weight_${index}` as keyof SubmitDataType, {
                                                                required: true,
                                                                valueAsNumber: true,
                                                                min: { value: 1, message: "Number must be greater than 0" },
                                                                max: { value: 40, message: "Number must be lower than 40" },
                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-row align-items-center justify-content-evenly w-100">
                                                    <div className="d-flex flex-row align-items-center justify-content-center position-relative my-4">
                                                        <p className="select-label w-140px ">Number of cartons</p>
                                                        <Form.Control
                                                            type="number"
                                                            onWheel={handleWheel}
                                                            defaultValue={"0"}
                                                            className="select-calculator w-140px "
                                                            {...register(`number_of_cartons_${index}` as keyof SubmitDataType, {
                                                                required: true,
                                                                valueAsNumber: true,
                                                                min: { value: 1, message: "Number must be greater than 0" },
                                                            })}
                                                        />
                                                    </div>
                                                    <div className="d-flex flex-row align-items-center justify-content-center position-relative my-4">
                                                        <p className="select-label w-185px">Select FBA warehouse</p>
                                                        {marketWarehouses && (
                                                            <Form.Select defaultValue={marketWarehouses[0]} className="select-calculator w-140px" {...register("warehouse_id")}>
                                                                {marketWarehouses.map((item, index) => (
                                                                    <option key={item + index} value={item}>
                                                                        {item}
                                                                    </option>
                                                                ))}
                                                            </Form.Select>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                            <div className="d-flex flex-row align-items-start justify-content-start pt-72px warehouse-btn-mobile">
                                <div className="d-flex flex-column align-items-center justify-content-center position-relative">
                                    <p className="select-label ml-4 w-200px">{`From China direct to Amazon ${selectedMarket}`}</p>
                                    <Button variant="primary" type="submit" className="calculate-btn ml-4">
                                        Calculate
                                    </Button>
                                    <p className="mb-0 font-weight-bold ml-4 fz-15 mt-2 mb-5">We do not do DDP shipping</p>
                                </div>
                            </div>
                        </Form>
                        <p className="fz-13 mb-0 absolute-bottom-right text-center">Note: Shipping rates do not include tariffs, taxes or duties.</p>
                    </div>
                </div>
            ) : (
                <div className="d-flex flex-column align-items-center justify-content-center w-100 pt-4">
                    <div
                        aria-label="calculator-wrapper"
                        className="d-flex flex-column align-items-start justify-content-center px-3 pb-4 mt-4 border calculator-wrapper position-relative min-h-350 bg-white"
                    >
                        <div className="d-flex flex-column align-items-center justify-content-center w-100 pt-5 pb-4">
                            <h1 className="text-center story-title w-100 px-3" data-testid="test-drive-title">
                                Test Drive Our Rates...{" "}
                            </h1>
                            <p className="p-landing-page px-3 text-center">Remember, the SKUdrop model works best with regular weekly shipments</p>
                        </div>
                        <div className="d-flex align-items-center justify-content-start mb-3">
                            <h3 className="ml-50px mb-0 me-3">Select your market</h3>
                            <CompactMarketsButtons tabState={selectedMarket} setTabState={onChangeMarket} />
                        </div>
                        <h3 className="ml-50px mb-2">Enter your carton details below</h3>
                        <Form className="d-flex flex-row align-items-start justify-content-center w-100 mobile-opt" noValidate onSubmit={handleSubmit(onSubmit)}>
                            <div>
                                <div className="d-flex flex-row align-items-center justify-content-start">
                                    <div className="d-flex align-items-center justify-content-center position-relative middle-line-calculator">
                                        <div className="d-flex flex-row align-items-center justify-content-center position-absolute bg-white px-2">
                                            <p className="fz-13 m-0"> cm </p>
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                checked={dimension}
                                                onChange={() => {
                                                    setDimension(!dimension);
                                                }}
                                            />
                                            <p className="fz-13 m-0"> in </p>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-center w-200px">
                                        <p className="fz-13 m-0"> kg </p>
                                        <Form.Check
                                            type="switch"
                                            id="switch-weight"
                                            checked={pound}
                                            onChange={() => {
                                                setPound(!pound);
                                            }}
                                        />
                                        <p className="fz-13 m-0"> lb </p>
                                    </div>
                                </div>
                                {skuList &&
                                    skuList.map((sku, index) => (
                                        <div key={index} className="select-container mt-5">
                                            <div className="d-flex flex-row align-items-center justify-content-start">
                                                <p className="mb-0 mr-3 fz-13">{sku.name}</p>
                                            </div>
                                            <div className="d-flex flex-row align-items-center justify-content-start position-relative">
                                                <p className="select-label">Carton length</p>
                                                <Form.Control
                                                    type="number"
                                                    onWheel={handleWheel}
                                                    defaultValue={"0"}
                                                    className="select-calculator align-middle"
                                                    data-testid={`calculator-carton-length`}
                                                    {...register(`length_${index}` as keyof SubmitDataType, {
                                                        required: true,
                                                        valueAsNumber: true,
                                                        min: { value: 1, message: "Number must be greater than 0" },
                                                        max: { value: 243, message: "Number must be lower than 243" },
                                                    })}
                                                />
                                                <p className="mb-0 font-weight-bold mx-4">X</p>
                                            </div>

                                            <div className="d-flex flex-row align-items-center justify-content-start position-relative">
                                                <p className="select-label">Carton width</p>
                                                <Form.Control
                                                    type="number"
                                                    onWheel={handleWheel}
                                                    defaultValue={"0"}
                                                    className="select-calculator"
                                                    data-testid={`calculator-carton-width`}
                                                    {...register(`width_${index}` as keyof SubmitDataType, {
                                                        required: true,
                                                        valueAsNumber: true,
                                                        min: { value: 1, message: "Number must be greater than 0" },
                                                        max: { value: 243, message: "Number must be lower than 243" },
                                                    })}
                                                />

                                                <p className="mb-0 font-weight-bold mx-4">X</p>
                                            </div>
                                            <div className="d-flex flex-row align-items-center justify-content-start position-relative">
                                                <p className="select-label mr-4">Carton height</p>
                                                <Form.Control
                                                    type="number"
                                                    onWheel={handleWheel}
                                                    defaultValue={"0"}
                                                    className="select-calculator mr-4"
                                                    data-testid={`calculator-carton-height`}
                                                    {...register(`height_${index}` as keyof SubmitDataType, {
                                                        required: true,
                                                        valueAsNumber: true,
                                                        min: { value: 1, message: "Number must be greater than 0" },
                                                        max: { value: 243, message: "Number must be lower than 243" },
                                                    })}
                                                />
                                            </div>
                                            <div className="d-flex flex-row align-items-center justify-content-start position-relative">
                                                <p className="select-label mx-4">Carton weight</p>
                                                <Form.Control
                                                    type="number"
                                                    onWheel={handleWheel}
                                                    defaultValue={"0"}
                                                    className="select-calculator mx-4"
                                                    data-testid={`calculator-carton-weight`}
                                                    {...register(`weight_${index}` as keyof SubmitDataType, {
                                                        required: true,
                                                        valueAsNumber: true,
                                                        min: { value: 1, message: "Number must be greater than 0" },
                                                        max: { value: 243, message: "Number must be lower than 243" },
                                                    })}
                                                />
                                            </div>
                                            <div className="d-flex flex-row align-items-center justify-content-start position-relative">
                                                <p className="select-label mx-4">Number of cartons</p>
                                                <Form.Control
                                                    type="number"
                                                    onWheel={handleWheel}
                                                    defaultValue={"0"}
                                                    className="select-calculator mx-4"
                                                    data-testid={`calculator-number-of-cartons`}
                                                    {...register(`number_of_cartons_${index}` as keyof SubmitDataType, {
                                                        required: true,
                                                        valueAsNumber: true,
                                                        min: { value: 1, message: "Number must be greater than 0" },
                                                    })}
                                                />
                                            </div>
                                        </div>
                                    ))}
                            </div>
                            <div className="d-flex flex-row align-items-start justify-content-start pt-72px warehouse-btn-mobile">
                                <div className="d-flex flex-row align-items-center justify-content-center position-relative">
                                    <p className="select-label w-185px">Select FBA warehouse</p>
                                    {marketWarehouses && (
                                        <Form.Select
                                            defaultValue={marketWarehouses[0]}
                                            className="select-calculator w-140px"
                                            data-testid={`calculator-fba-warehouse`}
                                            {...register("warehouse_id")}
                                        >
                                            {marketWarehouses.map((item, index) => (
                                                <option key={item + index} value={item}>
                                                    {item}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    )}
                                </div>
                                <div className="d-flex flex-column align-items-center justify-content-center position-relative">
                                    <p className="select-label ml-4 w-200px">{`From China direct to Amazon ${selectedMarket}`}</p>
                                    <Button name="calculate" variant="primary" type="submit" className="calculate-btn ml-4">
                                        Calculate
                                    </Button>
                                    <p className="mb-0 font-weight-bold ml-4 fz-15 mt-2">We do not do DDP shipping</p>
                                </div>
                            </div>
                        </Form>
                        <p className="fz-13 mb-0 absolute-bottom-right">Note: Shipping rates do not include tariffs, taxes or duties.</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Calculator;
