import { Container } from "react-bootstrap";

import Feature from "./Feature";

import feature_1 from "../../../assets/img/LandingPage/FeaturesSection/feature_1.png";
import feature_2 from "../../../assets/img/LandingPage/FeaturesSection/feature_2.png";
import feature_3 from "../../../assets/img/LandingPage/FeaturesSection/feature_3.png";
import feature_4 from "../../../assets/img/LandingPage/FeaturesSection/feature_4.png";
import feature_5 from "../../../assets/img/LandingPage/FeaturesSection/feature_5.png";
import feature_6 from "../../../assets/img/LandingPage/FeaturesSection/feature_6.png";
import feature_7 from "../../../assets/img/LandingPage/FeaturesSection/feature_7.png";
import feature_8 from "../../../assets/img/LandingPage/FeaturesSection/feature_8.png";

const FeatureSection = () => {
    return (
        <div className=" py-5 feature-bg " id="features">
            <h1 className="section-name">Our Features</h1>
            <Container className="d-flex justify-content-start align-items-start flex-row flex-wrap">
                <Feature
                    img={feature_1}
                    headline="Make your supply chain more efficient"
                    text="We’ve discovered when shipping smaller regular carton quantities directly into Amazon, profit and margins increase."
                />

                <Feature img={feature_2} headline="No need for 3PL’s in the US" text="Remove USA 3PL costs and eliminate the double handling of your products." />

                <Feature img={feature_3} headline="Dirt cheap storage" text="Store and prep in our Chinese warehouses for a fraction of the cost of US 3PL’s." />

                <Feature
                    img={feature_4}
                    headline="Stay cash liquid"
                    text="Don’t pay huge shipping costs upfront when production is finished. Instead, use that money to do bigger orders, get better unit prices and scale."
                />

                <Feature
                    img={feature_5}
                    headline="Shipments are 100% insured"
                    text="Door to door, your products are covered for full commercial invoice value when using SKUdrop’s shipping options."
                />

                <Feature
                    img={feature_6}
                    headline="User friendly inventory dashboard"
                    text="Our platform lets you manage and oversee your entire e-comm supply chain in an instant."
                />

                <Feature
                    img={feature_7}
                    headline="Ship to multiple global markets"
                    text="Effortlessly distribute your cartons across multiple markets with the click of a button."
                />

                <Feature
                    img={feature_8}
                    headline="Cheap fees to maximise profit"
                    text="Zero receiving fees. $2.75 USD labelling fees per carton and 0.49c USD per cubic meter a day for storage."
                />
            </Container>
        </div>
    );
};

export default FeatureSection;
