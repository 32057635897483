import ReactGA from "react-ga";
import { useEffect } from "react";
import "./CaseStudy.css";
import NavBar from "./NavBar/NavBar";
import Footer from "./Footer/Footer";
import BanerSection from "./BanerSection/BanerSection";
import CaseStudyHero1 from "./StudyHero/CaseStudyHero1";
import CaseStudyTable1 from "./DataTable/CaseStudyTable1";
import CaseStudyResults1 from "./Results/CaseStudyResults1";

const CaseStudy1 = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <>
            <ScrollToTopOnMount />
            <NavBar />
            <CaseStudyHero1 />
            <CaseStudyTable1 />
            <CaseStudyResults1 />
            <BanerSection />
            <Footer />
        </>
    );
};

function ScrollToTopOnMount() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return null;
}

export default CaseStudy1;
