import { FC, useState } from "react";
import Accordion from "react-bootstrap/Accordion";

export type FaqsPropsType = {
    text: string;
    description?: string;
    description2?: string;
    description3?: string;
    description4?: string;
};

const Faqs: FC<FaqsPropsType> = ({ text, description, description2, description3, description4 }) => {
    const [open, SetOpen] = useState(false);
    return (
        <div className="d-flex flex-row justify-content-between align-items-center f my-3">
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="1" className={open ? "accordion-item-border" : ""}>
                    <Accordion.Header onClick={() => SetOpen(!open)}>{text}</Accordion.Header>
                    <Accordion.Body>
                        {description}
                        {description2 && <br />}
                        {description2 && <br />}
                        {description2 && description2}
                        {description3 && <br />}
                        {description3 && <br />}
                        {description3 && description3}
                        {description4 && <br />}
                        {description4 && <br />}
                        {description4 && description4}
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    );
};

export default Faqs;
