import React from "react";
import welcomeImage from "../../../assets/img/LandingPage/WelcomeSection/welcome.png";

const WelcomSection = () => {
    return (
        <div className="welcome-bg d-flex align-items-center justify-content-center">
            <div className="basis-container h-100 mobile-align-items">
                <div className="first-block-basis align-items-center">
                    <h3 className="welcome-title">
                        Welcome to Your New
                        <br />
                        Supply Chain Partner
                    </h3>
                </div>
                <div className="second-block-basis">
                    <img className="story-image" src={welcomeImage} alt="" />
                </div>
            </div>
        </div>
    );
};

export default WelcomSection;
