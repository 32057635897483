import React, { FC } from "react";
import { TPricingSimpleInfo } from "./common/pricingInfo";

export type PricingSimpleCardPropsType = {
    cardInfo: TPricingSimpleInfo;
};

const PricingSimpleCard: FC<PricingSimpleCardPropsType> = ({ cardInfo }) => {
    return (
        <div className="pricing-card-container">
            <div className="pricing-card-color-title">
                <h2 className="pricing-card-color-title-header">{cardInfo.header}</h2>
                <p className="pricing-card-color-title-subheader">{cardInfo.subHeader}</p>
            </div>
            <div className="pricing-simple-card-info-container">
                {cardInfo.priceDetails.map((info, idx) => (
                    <React.Fragment key={cardInfo.header + info.name}>
                        <div className="pricing-simple-card-info-row">
                            <p className="pricing-simple-card-info-name">{info.name}</p>
                            <h2 className="pricing-simple-card-info-price">
                                {info.price}
                                {info.useСurrency && <span className="pricing-simple-card-info-currency">USD</span>}
                            </h2>
                            {info.comment && <p className="pricing-simple-card-info-comment">{info.comment}</p>}
                        </div>
                        {idx !== cardInfo.priceDetails.length - 1 && <div className="pricing-simple-card-info-line" />}
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};

export default PricingSimpleCard;
