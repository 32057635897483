import { FC } from "react";
import { TPricingSpecialInfo } from "./common/pricingInfo";

import { BiCheck } from "react-icons/bi";

export type PricingSpecialCardPropsType = {
    cardInfo: TPricingSpecialInfo;
    openContactModal: () => void;
};

const PricingSpecialCard: FC<PricingSpecialCardPropsType> = ({ cardInfo, openContactModal }) => {
    return (
        <div className="pricing-card-container">
            <div className="pricing-card-color-title">
                <h2 className="pricing-card-color-title-header">{cardInfo.header}</h2>
                <p className="pricing-card-color-title-subheader">{cardInfo.subHeader}</p>
            </div>
            <div className="pricing-special-card-info-container">
                <p className="pricing-special-card-info-description">{cardInfo.description}</p>
                <div className="d-flex justify-content-center w-100">
                    <div className="pricing-special-card-info-line" />
                </div>
                {cardInfo.features.map((info, idx) => (
                    <div key={idx} className="d-flex align-items-center w-100">
                        <BiCheck size={35} fill="#4d4d4d" />
                        <p className="pricing-special-card-info-feature">{info}</p>
                    </div>
                ))}
                <button className="pricing-special-card-contact-button" onClick={openContactModal}>
                    Contact sales
                </button>
            </div>
        </div>
    );
};

export default PricingSpecialCard;
