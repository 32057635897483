import React from "react";
import Results from "../common/Results";

const CaseStudyResults2 = () => {
    return (
        <>
            <Results title="CASE STUDY #2 RESULTS" number1="$2,474" number2="31.9%" number3="42.7% " />
        </>
    );
};

export default CaseStudyResults2;
