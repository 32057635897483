import React from "react";
import DualBlock from "../common/DualBlock";
import Dual_1 from "../../../assets/img/LandingPage/DualSection/dual_1.png";
import Dual_2 from "../../../assets/img/LandingPage/DualSection/dual_2.png";
import UKShippingSection from "../UKShippingSection/UKShippingSection";

const FirstDual = () => {
    return (
        <>
            <DualBlock
                reverse={true}
                title="Store in China"
                description1={
                    "Do you know what storing your goods in China means? Apart from having a central hub to distribute your products to multiple Amazon markets, you also save money on storage when compared to 3PL fees in the US. "
                }
                description2={
                    "By storing your cartons in the SKUdrop warehouses, you’ll be saving around 40% on your US storage costs. Plus, those receiving fees you get charged in the US don’t exist when you ship your cartons to us. We happily receive them for FREE. "
                }
                buttonTitle="Get Started"
                image={Dual_1}
            />
            <UKShippingSection />
            <DualBlock
                reverse={false}
                title="Save time"
                description1={
                    "Managing your supply chain can be time consuming. A great example of this is creating shipping plans for multiple SKU’s. If Amazon provides multiple FBA warehouses in your shipping plan for each SKU, you then need to download and manage your FBA labels, fill out spreadsheets, and send emails to your 3PL with labels and forms attached. "
                }
                description2={
                    "This all takes time and effort. With SKUdrop, we give you your time back. With the touch of a button, you can create shipping plans for multiple SKU’s, get competitive shipping quotes, and deliver your FBA labels to our warehouse in under 3 seconds."
                }
                buttonTitle="Get Started"
                image={Dual_2}
            />
        </>
    );
};

export default FirstDual;
