import axios from "axios";
import Cookies from "universal-cookie";

import {
    SetIsLoadingType,
    SetStatisticsChartsType,
    SetStatisticsInfoType,
    StatisticsActionTypes,
    StatisticsChartsResponseDataType,
    StatisticsInfoResponseDataType,
    ThunkType,
} from "./types/StatisticsTypes/statistics.types";

import { statisticsAPI } from "../api/statisticsAPI";
import { displayError } from "../hooks/useErrorHandler";
import { PeriodDetailFilterType } from "../components/DashboardPortal/AirShipping/AirShippingTab";
import { TDownloadStatisticFileData } from "../components/DashboardWarehouse/Statistics/StatisticsInfo/StatisticsInfo";

export const SET_STATISTICS_INFO = "SET_STATISTICS_INFO";
export const SET_STATISTICS_CHARTS = "SET_STATISTICS_CHARTS";
export const SET_IS_LOADING = "SET_IS_LOADING";

type InitialStateStatisticsType = {
    isLoading: boolean;
    statisticsInfo: null | StatisticsInfoResponseDataType;
    statisticsCharts: null | StatisticsChartsResponseDataType;
};

const initialState: InitialStateStatisticsType = {
    isLoading: false,
    statisticsInfo: null,
    statisticsCharts: null,
};

const statisticsReducer = (state = initialState, action: StatisticsActionTypes): InitialStateStatisticsType => {
    switch (action.type) {
        case SET_STATISTICS_INFO: {
            return {
                ...state,
                statisticsInfo: action.data,
            };
        }
        case SET_IS_LOADING: {
            return {
                ...state,
                isLoading: action.data,
            };
        }
        case SET_STATISTICS_CHARTS: {
            return {
                ...state,
                statisticsCharts: action.data,
            };
        }
        default: {
            return state;
        }
    }
};

export const SetLoading = (loading: boolean): SetIsLoadingType => ({
    type: SET_IS_LOADING,
    data: loading,
});
export const SetStatisticsInfo = (info: StatisticsInfoResponseDataType): SetStatisticsInfoType => ({
    type: SET_STATISTICS_INFO,
    data: info,
});
export const SetStatisticsCharts = (charts: StatisticsChartsResponseDataType): SetStatisticsChartsType => ({
    type: SET_STATISTICS_CHARTS,
    data: charts,
});

export const getStatisticsInfo = (cookies: Cookies, periodFilter: PeriodDetailFilterType): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            const response = await statisticsAPI.getStatisticsInfo(cookies, periodFilter);
            dispatch(SetStatisticsInfo(response.data));
            dispatch(SetLoading(false));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getStatisticsCharts = (cookies: Cookies, periodFilter: PeriodDetailFilterType): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            const response = await statisticsAPI.getStatisticsCharts(cookies, periodFilter);
            dispatch(SetStatisticsCharts(response.data));
            dispatch(SetLoading(false));
        } catch (e) {
            console.log(e);
        }
    };
};

export const downloadStatisticsFile = (cookies: Cookies, data: TDownloadStatisticFileData): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            const response = await statisticsAPI.downloadStatisticsFile(cookies, data);
            window.open(response.data.url);
            dispatch(SetLoading(false));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoading(false));
                displayError(e.response.data, "Something went wrong while downloading the statistics file");
            }
        }
    };
};

export default statisticsReducer;
