import { Container, Nav, Navbar, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { BiMenu } from "react-icons/bi";
import { Link } from "react-scroll";

import skuDropLogo from "../../../assets/img/skudrop-logo.png";

const NavBar = () => {
    const navigate = useNavigate();

    const margins = [
        { id: 1, title: "Features", link: "features" },
        { id: 2, title: "Case Studies", link: "case-study" },
        { id: 3, title: "Our Fees", link: "fees" },
        { id: 4, title: "Get a Shipping Estimate", link: "calculator" },
        { id: 5, title: "FAQ’s", link: "faq" },
    ];

    return (
        <Navbar collapseOnSelect expand="lg" variant="dark" className="p-0 w-100 nav-bg">
            <Container className="no-bg max-width-1920">
                <Navbar.Brand href={window.location.pathname === "/" ? "#home" : "/"}>
                    <img src={skuDropLogo} className="d-inline-block align-top logo-width" alt="React Bootstrap logo" />
                </Navbar.Brand>
                <BiMenu onClick={() => document.getElementById("menu")?.click()} className="menu-icon" />
                <Navbar.Toggle id="menu" aria-controls="basic-navbar-nav" style={{ display: "none" }} />
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                    <Nav className="mr-4 nav-list">
                        {margins.map((item) => (
                            <Link
                                to={item.link}
                                smooth={true}
                                duration={1000}
                                offset={0}
                                style={{
                                    textDecoration: "none",
                                    cursor: "pointer",
                                }}
                                key={item.id}
                            >
                                {item.title}
                            </Link>
                        ))}
                    </Nav>
                    <Nav>
                        <Button name="landing-page_signup-button" className="lp-xl" onClick={() => navigate("/register")}>
                            SIGN UP
                        </Button>
                    </Nav>
                    <Nav>
                        <Button name="landing-page_login-button" className="lp-xl ml-10" onClick={() => navigate("/login")}>
                            LOG IN
                        </Button>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default NavBar;
