import React from "react";

const GuaranteeSection = () => {
    return (
        <div className="d-flex justify-content-center align-items-center guarantee-bg">
            <div className="pl-section w-100">
                <h1 className="guarantee-first-title">Our supply chain money saving</h1>
                <h1 className="guarantee-second-title">GUARANTEE</h1>
                <p className="story-text text-center guarantee-max-width guarentee-middle-text">
                    We are confident that SKUdrop will save you money when it comes to your total supply chain process.
                </p>
                <p className="story-text text-center bold guarantee-max-width guarentee-middle-text">We stand by that statement. </p>
                <p className="story-text text-center guarantee-max-width">
                    If you currently ship all your cartons from China to a US 3PL, SKUdrop wins this battle EVERY SINGLE TIME!
                    <br />
                    When using SKUdrop, you no longer need to pay for bonds, container drop off fees (to your supplier’s factory), container delivery fees (port to 3PL), 3PL
                    receiving, storage and prep fees, and Amazon SPD shipping costs.
                    <br />
                    All our shipping quotes are DOOR TO DOOR, and we have the cheapest storage on the planet.
                </p>
            </div>
        </div>
    );
};

export default GuaranteeSection;
