export type TContactInfoObj =
    | {
          id: number;
          title: string[];
          question: string;
          type: "input";
          input_type: "text" | "email";
          value_key: "name" | "email";
          placeholder: string;
      }
    | {
          id: number;
          title: string[];
          question: string;
          type: "radio";
          value_key: "over_141_cbm" | "oversized_products" | "with_ddp_terms" | "delivery_method" | "sales_market";
          radioNames: { label: string; value: string }[];
      };

export type TContactDataValues = "name" | "email" | "over_141_cbm" | "oversized_products" | "with_ddp_terms" | "delivery_method" | "sales_market";

export const contactInfo: TContactInfoObj[] = [
    {
        id: 1,
        title: ["Contact sales about our", "Enterprise account"],
        question: "Please enter your name",
        type: "input",
        input_type: "text",
        value_key: "name",
        placeholder: "Enter name here",
    },
    {
        id: 2,
        title: ["Contact sales about our", "Enterprise account"],
        question: "Please enter your email address",
        type: "input",
        input_type: "email",
        value_key: "email",
        placeholder: "Enter email address here",
    },
    {
        id: 3,
        title: ["Storage", "volume"],
        question: "Do you have over 141 CBM and are looking for a storage facility for your Amazon business in China?",
        type: "radio",
        value_key: "over_141_cbm",
        radioNames: [
            { label: "YES", value: "Yes" },
            { label: "NO", value: "No" },
        ],
    },
    {
        id: 4,
        title: ["Product", "size"],
        question: "Do you sell any Amazon oversized products?",
        type: "radio",
        value_key: "oversized_products",
        radioNames: [
            { label: "YES", value: "Yes" },
            { label: "NO", value: "No" },
        ],
    },
    {
        id: 5,
        title: ["Shipping", "incoterm"],
        question: "Do you purchase and ship any of your goods with DDP terms?",
        type: "radio",
        value_key: "with_ddp_terms",
        radioNames: [
            { label: "YES", value: "Yes" },
            { label: "NO", value: "No" },
        ],
    },
    {
        id: 6,
        title: ["Supply chain", "strategy"],
        question: "Do you use a US 3PL, send directly to Amazon or use both methods?",
        type: "radio",
        value_key: "delivery_method",
        radioNames: [
            { label: "3PL", value: "3PL" },
            { label: "DIRECT", value: "Direct" },
            { label: "BOTH", value: "3PL and Direct" },
        ],
    },
    {
        id: 7,
        title: ["Sourcing and", "marketplaces"],
        question: "Do you source your products in China and sell on Amazon US or UK marketplaces?",
        type: "radio",
        value_key: "sales_market",
        radioNames: [
            { label: "YES", value: "Yes" },
            { label: "NO", value: "No" },
        ],
    },
];
