import { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Form, Modal } from "react-bootstrap";

import { useAppDispatch } from "../../../../redux/ReduxStore";
import { TContactDataValues, contactInfo } from "./contactInfo";
import { PostContactSales } from "../../../../redux/authReducer";
import { TPostContactSalesData } from "../../../../redux/types/AuthTypes/auth.types";
import { EMAIL_REGEX, INVALIDATE_SPACES_REGEX } from "../../../DashboardWarehouse/common/Constants";

import warningImg from "../../../../assets/img/warning.png";
import doneImg from "../../../../assets/img/done_orange.png";

export interface TConfirmCustomerRatesModalProps {
    showModal: boolean;
    handleClose: () => void;
}

const ContactSalesModal = ({ showModal, handleClose }: TConfirmCustomerRatesModalProps) => {
    const dispatch = useAppDispatch();

    const {
        register,
        trigger,
        reset,
        getValues,
        handleSubmit,
        formState: { errors },
    } = useForm<TPostContactSalesData>({ mode: "onBlur" });

    const [step, setStep] = useState(1);

    const handleCloseModal = () => {
        handleClose();
        reset();
        setStep(1);
    };

    const handleConfirmStep = (key: TContactDataValues) => {
        trigger(key).then((isValid) => {
            if (isValid && key === "over_141_cbm" && getValues("over_141_cbm") === "No") {
                setStep(0);
                return;
            }
            if (isValid) {
                setStep(step + 1);
            }
        });
    };

    const handleContactSales = (data: TPostContactSalesData) => {
        dispatch(PostContactSales(data));
        setStep(10);
    };

    return (
        <Modal dialogClassName="contact-sales-modal" centered show={showModal} onHide={handleCloseModal}>
            <Modal.Header className="contact-sales-modal-header" closeButton />
            <Modal.Body>
                {contactInfo.map((info, idx) =>
                    step === info.id ? (
                        <div key={info.value_key + idx} className="contact-sales-modal-body">
                            <div className="d-flex flex-column align-items-center justify-content-center w-100">
                                {info.title.map((title, idx) => (
                                    <h4 key={idx} className="m-0 text-center">
                                        {title}
                                    </h4>
                                ))}
                            </div>
                            <div className="contact-sales-modal-question-container">
                                {info.type === "input" ? (
                                    <div className="w-100 pb-3">
                                        <Form.Label className="w-100 text-center fz-16">{info.question}</Form.Label>
                                        <Form.Control
                                            className={`w-100 ${errors[info.value_key] && "border-danger"}`}
                                            type={info.input_type}
                                            placeholder={info.placeholder}
                                            {...register(info.value_key, { required: true, pattern: info.input_type === "email" ? EMAIL_REGEX : INVALIDATE_SPACES_REGEX })}
                                        />
                                        {errors[info.value_key] && (
                                            <p className="contact-sales-modal-error-message">
                                                {info.input_type === "email" ? "Please enter valid email" : "This field is required"}
                                            </p>
                                        )}
                                    </div>
                                ) : (
                                    <div className="w-100">
                                        <Form.Label className="w-100 text-center fz-16">{info.question}</Form.Label>
                                        <div className="d-flex align-items-center justify-content-center mt-2">
                                            {info.radioNames.map((obj, idx) => (
                                                <div key={idx + obj.value} style={{ minWidth: "60px" }} className="d-flex flex-column align-items-center">
                                                    <Form.Check
                                                        type="radio"
                                                        value={obj.value}
                                                        radioGroup={`${info.value_key}_group`}
                                                        {...register(info.value_key, { required: true })}
                                                    />
                                                    <p className="m-0 fz-16">{obj.label}</p>
                                                </div>
                                            ))}
                                        </div>
                                        {errors[info.value_key] && <p className="contact-sales-modal-error-message">Please select an option</p>}
                                    </div>
                                )}
                            </div>
                            {step < contactInfo.length ? (
                                <Button variant="outline-primary contact-sales-modal-button" onClick={() => handleConfirmStep(info.value_key)}>
                                    <span className="contact-sales-modal-button-text">Next</span>
                                </Button>
                            ) : (
                                <Button variant="outline-primary contact-sales-modal-button" onClick={handleSubmit(handleContactSales)}>
                                    <span className="contact-sales-modal-button-text">Submit to the sales team</span>
                                </Button>
                            )}
                        </div>
                    ) : null
                )}
                {step === 0 ? (
                    <div className="contact-sales-done-body">
                        <img style={{ width: "85px" }} src={warningImg} alt="done-orange-img" />
                        <p className="m-0 mt-4 text-center fz-16">
                            Unfortunately, our Enterprise offering is
                            <br />
                            for sellers with over 141 CBM.
                        </p>
                        <p className="m-0 my-3 text-center fz-16">
                            Please check out our tiered pricing
                            <br />
                            options for your specific sized business.
                        </p>
                    </div>
                ) : null}
                {step === 10 ? (
                    <div className="contact-sales-done-body">
                        <img style={{ width: "85px" }} src={doneImg} alt="done-orange-img" />
                        <h6 className="m-0 mt-4 text-center">WE HAVE RECEIVED ALL YOUR DETAILS</h6>
                        <p className="m-0 mt-3 mb-4 text-center fz-16">A SKUdrop team member will reach out shortly if you qualify to arrange a time to connect</p>
                    </div>
                ) : null}
            </Modal.Body>
        </Modal>
    );
};

export default ContactSalesModal;
