import conect_with_us_women from "../../../assets/img/LandingPage/ConnectWithUs/Call-Centre-Transparent-Images.webp";

const ConnectWithUs = () => {
    const handleClick = () => {
        window.open("https://landingpages.skudrop.com/skudrop-call");
    };

    return (
        <div id="get-in-touch" className="connect-with-us-section">
            <div className="connect-with-us-image-container">
                <img src={conect_with_us_women} alt="image" className="connect-with-us-image" />
            </div>
            <div className="connect-with-us-content">
                <div className="connect-with-us-text-wrapper ">
                    <p className="connect-with-us-second-title">Connect with our team</p>
                    <p className="connect-with-us-text">Find out how you can start saving time and money across your entire supply chain today</p>
                </div>
                <button onClick={handleClick} className="connect-with-us-btn">
                    Connect with us
                </button>
            </div>
        </div>
    );
};

export default ConnectWithUs;
