import { FC } from "react";
import { useNavigate } from "react-router-dom";

export type DualBlockPropsType = {
    image: string;
    title: string;
    description1: string;
    description2: string;
    description3?: string;
    description4?: string;
    buttonTitle: string;
    reverse: boolean;
};

const DualBlock: FC<DualBlockPropsType> = ({ image, title, description1, description2, description3, description4, buttonTitle, reverse }) => {
    const navigate = useNavigate();
    return (
        <div className="py-10 feature-bg d-flex align-items-center justify-content-center">
            <div className={reverse ? "basis-container flex-row-reverse" : "basis-container"}>
                <div className="first-block-basis align-items-center">
                    <h3 className="story-title">{title}</h3>
                    <p className="story-text mb-0">
                        {description1}
                        {description2 && <br />}
                        {description2 && <br />}
                        {description2 && description2}
                        {description3 && <br />}
                        {description3 && <br />}
                        {description3 && description3}
                        {description4 && <br />}
                        {description4 && <br />}
                        {description4 && description4}
                    </p>
                    <div className="w-80 story-buton-container">
                        <button className="lp_button mt-4" onClick={() => navigate("/register")}>
                            {buttonTitle}
                        </button>
                    </div>
                </div>
                <div className={title === "Simplified dashboard management" ? "second-block-basis mobile-mb-30 " : "second-block-basis"}>
                    <img className={title === "Keep more money in your pocket" ? "w-100 p-2" : "w-80"} src={image} alt="" />
                </div>
            </div>
        </div>
    );
};

export default DualBlock;
