import ReactGA from "react-ga";
import { useEffect } from "react";
import NavBar from "./NavBar/NavBar";
import Footer from "./Footer/Footer";
import BanerSection from "./BanerSection/BanerSection";
import CaseStudyHero2 from "./StudyHero/CaseStudyHero2";
import CaseStudyTable2 from "./DataTable/CaseStudyTable2";
import CaseStudyResults2 from "./Results/CaseStudyResults2";

const CaseStudy2 = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <>
            <ScrollToTopOnMount />
            <NavBar />
            <CaseStudyHero2 />
            <CaseStudyTable2 />
            <CaseStudyResults2 />
            <BanerSection />
            <Footer />
        </>
    );
};

function ScrollToTopOnMount() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return null;
}

export default CaseStudy2;
