import React from "react";
import DualBlock from "../common/DualBlock";
import Dual_3 from "../../../assets/img/LandingPage/DualSection/dual_3.png";
import Dual_4 from "../../../assets/img/LandingPage/DualSection/dual_4.png";
import Dual_5 from "../../../assets/img/LandingPage/DualSection/dual_5.png";

const SecondDual = () => {
    return (
        <>
            {/* <DualBlock 
            reverse={true} 
            title="The power of freight consolidation at your fingertips" 
            description1={"The holy grail of freight management is combining multiple shipments into one large shipment. Think of it like carpooling, but for freight."} 
            description2={"For example, when people use their cars individually to get to work, they would all have to pay for their own tolls and fuel. But carpooling “shares” these costs across more people which results in money being saved."} 
            description3={"This is what we do at SKUdrop, we have cracked the freight consolidation code and can pass on huge cost saving benefits as well as freight efficiencies to you."}
            description4={"Now you can share the costs of containers, deconsolidation and delivery with your fellow Amazon sellers which means one thing, YOU SAVE MONEY, MAKE MORE PROFIT, and increase SHIPPING EFFICIENCIES to get your goods to an Amazon FBA warehouse much faster."}
            buttonTitle="Get Started" 
            image={Dual_5} 
        /> */}
            <DualBlock
                reverse={false}
                title="Keep more money in your pocket"
                description1={
                    "We are on auto pilot when it comes to our supply chain. Traditionally we get our goods produced in China, get our freight forwarder to ship our entire shipment to the US, then store them all in a 3PL. "
                }
                description2={
                    "This way of doing things means you need to outlay LARGE AMOUNTS OF CASH UPFRONT to get all your goods to the US. Plus, you have the extra SPD delivery costs from your 3PL to Amazon. Do you realise how much money you’re leaking in your supply chain?"
                }
                description3={
                    "SKUdrop keeps more money in your pocket. Why? Because our solution involves doing regular DIRECT TO AMAZON shipments each week, resulting in SPENDING LESS CASH UPFRONT, keeping MORE MONEY IN YOUR BANK ACCOUNT and ELIMINATING extra SPD shipping costs. "
                }
                description4={"With SKUdrop, there’s no need to ship ALL your goods to the US in one bulk shipment."}
                buttonTitle="Get Started"
                image={Dual_3}
            />
            <DualBlock
                reverse={true}
                title="Simplified dashboard management"
                description1={"Managing your storage, creating shipping plans, sending FBA labels to your 3PL for SPD shipping and stock control can be very complex. "}
                description2={
                    "The SKUdrop dashboard simplifies the way we manage the above processes. In one glance, you can instantly see what goods you’re storing, how many cubic meters you have at our warehouse, what’s cartons are incoming, what’s in transit and more. "
                }
                buttonTitle="Get Started"
                image={Dual_4}
            />
        </>
    );
};

export default SecondDual;
