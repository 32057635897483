import { FC } from "react";

export type StudyHeroPropsType = {
    label: string;
    title: string;
    description: string;
    subTitle: string;
};

const StudyHero: FC<StudyHeroPropsType> = ({ label, title, description, subTitle }) => {
    return (
        <div className="py-5 study-hero">
            <div className=" study-hero-block">
                <h1 className="study-hero-label">{label}</h1>
                <h1 className="study-hero-label primary-color">{title}</h1>
                <p className="study-hero-description">{description}</p>
                <h3 className="study-hero-subtitle">{subTitle}</h3>
            </div>
        </div>
    );
};

export default StudyHero;
