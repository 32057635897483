import ReactGA from "react-ga";
import { useEffect } from "react";
import welcomePng from "../../../../assets/img/LandingPage/WelcomeSection/welcome.png";

const MaintenancePage = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <div className="block-wrapper">
            <div className="logo-wrapper">
                <img width={300} src={welcomePng} alt="" />
            </div>
            <div className="d-flex flex-column">
                <span className="maintenance-text">Our app is currently down for maintenance.</span>
                <span className="maintenance-text-under">Please try again later, estimated time of finish in 2 hours</span>
            </div>
        </div>
    );
};

export default MaintenancePage;
