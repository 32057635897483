import { useState, useEffect, FC } from "react";
import { Form, Table } from "react-bootstrap";
import { CheckNumberAfterPoint } from "../../DashboardWarehouse/pastOrdersList/common/NumbersAfterPoint";
import { VscDebugRestart } from "react-icons/vsc";
import { CalculatorDataType } from "../../../redux/types/AuthTypes/auth.types";

export type SummaryPropsType = {
    is3PL: boolean;
    calculatedData: CalculatorDataType | null;
    onResetCalculator: () => void;
    selectedMarket: string;
};

const Summary: FC<SummaryPropsType> = ({ is3PL, calculatedData, onResetCalculator, selectedMarket }) => {
    const [priceMode, setPriceMode] = useState(false);

    const [mobile, setMobile] = useState(false);

    const handleResize = () => {
        if (window.innerWidth < 990) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    };

    // create an event listener
    useEffect(() => {
        window.addEventListener("resize", handleResize);
    });

    useEffect(() => {
        handleResize();
    }, []);

    const summaryList =
        calculatedData &&
        calculatedData.cartons.map((item, index) => {
            return selectedMarket === "US" ? (
                <tr key={item.cbm_measure + index}>
                    <td valign="middle" className="text-center py-3">
                        {!calculatedData.weekly_price || priceMode
                            ? CheckNumberAfterPoint(item.number_of_cartons)
                            : CheckNumberAfterPoint(item?.week_number_of_cartons ? item.week_number_of_cartons : "")}
                    </td>
                    <td valign="middle" className="text-center py-3">
                        {!calculatedData.weekly_price || priceMode
                            ? CheckNumberAfterPoint(calculatedData.total_quantity_of_shipments)
                            : CheckNumberAfterPoint(calculatedData?.quantity_of_week_shipments ? calculatedData.quantity_of_week_shipments : "")}
                    </td>
                    <td valign="middle" className="text-center py-3">
                        {!calculatedData.weekly_price || priceMode
                            ? CheckNumberAfterPoint(item.cubic_value)
                            : CheckNumberAfterPoint(item?.week_cubic_value ? item.week_cubic_value : "")}
                    </td>
                    <td valign="middle" className="text-center py-3">
                        {!calculatedData.weekly_price || priceMode
                            ? CheckNumberAfterPoint(item.total_weight) + " " + item.weight_measure
                            : CheckNumberAfterPoint(item?.week_weight ? item.week_weight : "") + " " + item.week_weight_measure}
                    </td>
                    <td valign="middle" className="text-center py-3">
                        {calculatedData?.warehouse_id}
                    </td>
                    <td valign="middle" className="text-center py-3">
                        Regular SEA
                    </td>
                </tr>
            ) : (
                <tr key={item.cbm_measure + index}>
                    <td valign="middle" className="text-center py-3">
                        {!calculatedData?.week_sea_rate || priceMode
                            ? CheckNumberAfterPoint(item.number_of_cartons)
                            : CheckNumberAfterPoint(item?.week_number_of_cartons ? item.week_number_of_cartons : "")}
                    </td>
                    <td valign="middle" className="text-center py-3">
                        {!calculatedData?.week_sea_rate || priceMode
                            ? CheckNumberAfterPoint(calculatedData.total_quantity_of_shipments)
                            : CheckNumberAfterPoint(calculatedData?.quantity_of_week_shipments ? calculatedData.quantity_of_week_shipments : "")}
                    </td>
                    <td valign="middle" className="text-center py-3">
                        {!calculatedData?.week_sea_rate || priceMode
                            ? CheckNumberAfterPoint(item.cubic_value)
                            : CheckNumberAfterPoint(item?.week_cubic_value ? item.week_cubic_value : "")}
                    </td>
                    <td valign="middle" className="text-center py-3">
                        {!calculatedData?.week_sea_rate || priceMode
                            ? CheckNumberAfterPoint(item.total_weight) + " " + item.weight_measure
                            : CheckNumberAfterPoint(item?.week_weight ? item.week_weight : "") + " " + item.week_weight_measure}
                    </td>
                    <td valign="middle" className="text-center py-3">
                        {calculatedData?.warehouse_id}
                    </td>
                    <td valign="middle" className="text-center py-3">
                        Regular SEA
                    </td>
                </tr>
            );
        });

    return (
        <div className="d-flex flex-column align-items-center justify-content-center w-100">
            {mobile ? (
                <div className="d-flex flex-column align-items-center justify-content-center w-100">
                    <div className="d-flex flex-column align-items-center justify-content-center">
                        <h3 className="text-center story-title w-100 px-3">Test Drive Our Rates... </h3>
                        <p className="p-landing-page px-3 text-center">Remember, the SKUdrop model works best with regular weekly shipments</p>
                    </div>
                    <div className="d-flex flex-column align-items-start justify-content-center pt-5 px-3 pb-4 border w-9 min-h-350 position-relative bg-white">
                        <div className="position-absolute reset-calculator" onClick={() => onResetCalculator()}>
                            <VscDebugRestart style={{ width: 25, height: 25, color: "#1B2B4A" }} title="Reset calculator" />
                        </div>
                        <h3 className="text-center">Cost of your shipment using SKUdrop</h3>
                        <p className="fz-13 text-center">SKUdrop keeps more cash in your pocket with our weekly shipping method. All at the same price as your upfront costs.</p>
                        <div className="d-flex flex-row align-items-start justify-content-start w-100">
                            <div className="d-flex flex-column w-100 align-items-center justify-content-start">
                                <Table striped id="summary_mob_1" className="mb-0">
                                    <thead>
                                        <tr>
                                            <th data-type="string" className="text-center py-3">
                                                Cartons
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody id="tBody_mob_1">
                                        <tr>
                                            {calculatedData &&
                                                (selectedMarket === "US" ? (
                                                    <td valign="middle" className="text-center py-3">
                                                        {!calculatedData?.weekly_price || priceMode
                                                            ? CheckNumberAfterPoint(calculatedData.cartons[0].number_of_cartons)
                                                            : CheckNumberAfterPoint(
                                                                  calculatedData.cartons[0]?.week_number_of_cartons ? calculatedData.cartons[0].week_number_of_cartons : ""
                                                              )}
                                                    </td>
                                                ) : (
                                                    <td valign="middle" className="text-center py-3">
                                                        {!calculatedData?.week_sea_rate || priceMode
                                                            ? CheckNumberAfterPoint(calculatedData.cartons[0].number_of_cartons)
                                                            : CheckNumberAfterPoint(
                                                                  calculatedData.cartons[0]?.week_number_of_cartons ? calculatedData.cartons[0].week_number_of_cartons : ""
                                                              )}
                                                    </td>
                                                ))}
                                        </tr>
                                    </tbody>
                                </Table>
                                <Table striped id="summary_mob_2" className="mb-0">
                                    <thead>
                                        <tr>
                                            <th data-type="string" className="text-center py-3">
                                                Total shipments
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody id="tBody_mob_2">
                                        <tr>
                                            {calculatedData &&
                                                (selectedMarket === "US" ? (
                                                    <td valign="middle" className="text-center py-3">
                                                        {!calculatedData?.weekly_price || priceMode
                                                            ? CheckNumberAfterPoint(calculatedData.total_quantity_of_shipments)
                                                            : CheckNumberAfterPoint(calculatedData?.quantity_of_week_shipments ? calculatedData.quantity_of_week_shipments : "")}
                                                    </td>
                                                ) : (
                                                    <td valign="middle" className="text-center py-3">
                                                        {!calculatedData?.week_sea_rate || priceMode
                                                            ? CheckNumberAfterPoint(calculatedData.total_quantity_of_shipments)
                                                            : CheckNumberAfterPoint(calculatedData?.quantity_of_week_shipments ? calculatedData.quantity_of_week_shipments : "")}
                                                    </td>
                                                ))}
                                        </tr>
                                    </tbody>
                                </Table>
                                <Table striped id="summary_mob_3" className="mb-0">
                                    <thead>
                                        <tr>
                                            {calculatedData &&
                                                (selectedMarket === "US" ? (
                                                    <th data-type="string" className="text-center py-3">
                                                        {!calculatedData?.weekly_price || priceMode
                                                            ? calculatedData.cartons[0].cbm_measure
                                                            : calculatedData && calculatedData.cartons[0].week_cbm_measure}
                                                    </th>
                                                ) : (
                                                    <th data-type="string" className="text-center py-3">
                                                        {!calculatedData?.week_sea_rate || priceMode
                                                            ? calculatedData.cartons[0].cbm_measure
                                                            : calculatedData && calculatedData.cartons[0].week_cbm_measure}
                                                    </th>
                                                ))}
                                        </tr>
                                    </thead>
                                    <tbody id="tBody_mob_3">
                                        <tr>
                                            {calculatedData &&
                                                (selectedMarket === "US" ? (
                                                    <td valign="middle" className="text-center py-3">
                                                        {!calculatedData?.weekly_price || priceMode
                                                            ? CheckNumberAfterPoint(calculatedData.cartons[0].cubic_value)
                                                            : CheckNumberAfterPoint(calculatedData.cartons[0]?.week_cubic_value ? calculatedData.cartons[0].week_cubic_value : "")}
                                                    </td>
                                                ) : (
                                                    <td valign="middle" className="text-center py-3">
                                                        {!calculatedData?.week_sea_rate || priceMode
                                                            ? CheckNumberAfterPoint(calculatedData.cartons[0].cubic_value)
                                                            : CheckNumberAfterPoint(calculatedData.cartons[0]?.week_cubic_value ? calculatedData.cartons[0].week_cubic_value : "")}
                                                    </td>
                                                ))}
                                        </tr>
                                    </tbody>
                                </Table>
                                <Table striped id="summary_mob_4" className="mb-0">
                                    <thead>
                                        <tr>
                                            <th data-type="string" className="text-center py-3">
                                                Weight
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody id="tBody_mob_4">
                                        <tr>
                                            {calculatedData &&
                                                (selectedMarket === "US" ? (
                                                    <td valign="middle" className="text-center py-3">
                                                        {!calculatedData?.weekly_price || priceMode
                                                            ? CheckNumberAfterPoint(calculatedData.cartons[0].total_weight) + " " + calculatedData.cartons[0].weight_measure
                                                            : CheckNumberAfterPoint(calculatedData?.cartons[0]?.week_weight ? calculatedData?.cartons[0].week_weight : "") +
                                                              " " +
                                                              calculatedData?.cartons[0].week_weight_measure}
                                                    </td>
                                                ) : (
                                                    <td valign="middle" className="text-center py-3">
                                                        {!calculatedData?.week_sea_rate || priceMode
                                                            ? CheckNumberAfterPoint(calculatedData.cartons[0].total_weight) + " " + calculatedData.cartons[0].weight_measure
                                                            : CheckNumberAfterPoint(calculatedData?.cartons[0]?.week_weight ? calculatedData?.cartons[0].week_weight : "") +
                                                              " " +
                                                              calculatedData?.cartons[0].week_weight_measure}
                                                    </td>
                                                ))}
                                        </tr>
                                    </tbody>
                                </Table>
                                <Table striped id="summary_mob_5">
                                    <thead>
                                        <tr>
                                            <th data-type="string" className="text-center py-3">
                                                FBA warehouse
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody id="tBody_mob_5">
                                        <tr>
                                            <td valign="middle" className="text-center py-3">
                                                {calculatedData?.warehouse_id}
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <Table striped id="summary_mob_6">
                                    <thead>
                                        <tr>
                                            <th data-type="string" className="text-center py-3">
                                                Shipping service
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody id="tBody_mob_5">
                                        <tr>
                                            <td valign="middle" className="text-center py-3">
                                                Regular SEA
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                                <div className="d-flex flex-column align-items-center justify-content-center my-5">
                                    {selectedMarket === "US" ? (
                                        calculatedData?.final_price ? (
                                            <>
                                                <p className="header-title text-center table-header-style mb-0">
                                                    {!calculatedData?.weekly_price || priceMode ? "Total sea cost" : "Weekly sea cost"}
                                                </p>
                                                <h1 aria-label="calculated-price" className="cost-title mb-0">
                                                    ${!calculatedData?.weekly_price || priceMode ? calculatedData?.final_price : calculatedData?.weekly_price}
                                                </h1>
                                            </>
                                        ) : (
                                            calculatedData?.air_price && (
                                                <>
                                                    <p className="header-title text-center table-header-style mb-0">
                                                        {!calculatedData?.weekly_air_price || priceMode ? "Total air cost" : "Weekly air cost"}
                                                    </p>
                                                    <h1 aria-label="calculated-price" className="cost-title mb-0">
                                                        $
                                                        {!calculatedData?.weekly_air_price || priceMode
                                                            ? calculatedData?.air_price?.toFixed(2)
                                                            : calculatedData?.weekly_air_price?.toFixed(2)}
                                                    </h1>
                                                </>
                                            )
                                        )
                                    ) : calculatedData?.sea_rate ? (
                                        <>
                                            <p className="header-title text-center table-header-style mb-0">
                                                {!calculatedData?.week_sea_rate || priceMode ? "Total sea cost" : "Weekly sea cost"}
                                            </p>
                                            <h1 aria-label="calculated-price" className="cost-title mb-0">
                                                ${!calculatedData?.week_sea_rate || priceMode ? calculatedData?.sea_rate?.toFixed(2) : calculatedData?.week_sea_rate?.toFixed(2)}
                                            </h1>
                                        </>
                                    ) : (
                                        calculatedData?.truck_rate && (
                                            <>
                                                <p className="header-title text-center table-header-style mb-0">
                                                    {!calculatedData?.week_truck_rate || priceMode ? "Total truck cost" : "Weekly truck cost"}
                                                </p>
                                                <h1 aria-label="calculated-price" className="cost-title mb-0">
                                                    $
                                                    {!calculatedData?.week_truck_rate || priceMode
                                                        ? calculatedData?.truck_rate?.toFixed(2)
                                                        : calculatedData?.week_truck_rate?.toFixed(2)}
                                                </h1>
                                            </>
                                        )
                                    )}
                                    {(calculatedData?.weekly_price || calculatedData?.week_sea_rate) && (
                                        <div className="d-flex align-items-center justify-content-center w-200px mb-2">
                                            <p className="fz-10 m-0">Weekly shipment</p>
                                            <Form.Check
                                                type="switch"
                                                id="switch-weight"
                                                checked={priceMode}
                                                onChange={() => {
                                                    setPriceMode(!priceMode);
                                                }}
                                            />
                                            <p className="fz-10 m-0">Full shipment</p>
                                        </div>
                                    )}
                                    <p className="fz-10 mb-1 font-weight-bold text-center line-height-14">Current live pricing updated weekly</p>
                                    <p className="fz-10 mb-1 text-center line-height-14">Excluding tariffs, prep fees & storage</p>
                                </div>
                                <div className="d-flex flex-column align-items-start justify-content-start w-100">
                                    <h5>The benefits of using SKUdrop</h5>
                                    <p className="p-landing-page">
                                        Our shipping rates algorithm has over 1,168 data points designed to find you the best price. We compare CBM
                                        <br />
                                        vs KG pricing and also calculate the best last mile transport delivery costs into Amazon FBA.
                                    </p>
                                    {is3PL && (
                                        <>
                                            <h5>Reduce expensive US 3PL costs</h5>
                                            <p className="p-landing-page">
                                                SKUdrop is a direct to Amazon logistics solution. Our customers love how SKUdrop reduces the following 3PL costs:
                                            </p>
                                            <ul style={{ paddingLeft: "1rem" }} className="list-text-p">
                                                <li>DELIVERY COSTS TO 3PL’S</li>
                                                <li>RECEIVING FEES</li>
                                                <li>PALLET FEES</li>
                                                <li>ADMIN FEES</li>
                                                <li>PREP + LABELING FEES</li>
                                                <li>SUBSCRIPTIONS</li>
                                                <li>STORAGE FEES</li>
                                                <li>AMAZON SPD/LTL SHIPPING COSTS ARE COMPLETELY REMOVED</li>
                                            </ul>
                                        </>
                                    )}
                                    {!is3PL && (
                                        <>
                                            <h5>Did you know...</h5>
                                            <p>
                                                Over 1 year across standard size, <b>SKUdrop has 66.11% cheaper storage compared to Amazon’s storage costs.</b>
                                            </p>
                                            <h5>More reasons to use SKUdrop...</h5>
                                            <ul style={{ paddingLeft: "1rem" }} className="list-text-p">
                                                <li>There’s no need to spend all your money upfront for your shipment. Keep cash in your pocket by shipping weekly</li>
                                                <li>Fast shipping plan creation means after a few clicks your plan is done, you’ll have a quote, and we’ll have your labels</li>
                                                <li>What’s powerful about storing in our Chinese warehouse is if you have any product issues, your supplier is close by</li>
                                                <li>Our software system automates those time wasting emails you send back and forth with your freight forwarder</li>
                                                <li> We pay for your tariffs upfront to keep more cash in your pocket and we charge you later</li>
                                                <li>Our customers are saving time and money by tapping into our direct to Amazon logistics model</li>
                                            </ul>
                                        </>
                                    )}
                                    <p className="p-landing-page">
                                        Set up your SKUdrop account now and <b>eliminate paying for full shipments upfront forever.</b>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="d-flex flex-column align-items-center justify-content-center w-100">
                    <div className="d-flex flex-column align-items-center justify-content-center">
                        <h1 className="text-center story-title w-100 px-3">Test Drive Our Rates... </h1>
                        <p className="p-landing-page px-3 text-center">Remember, the SKUdrop model works best with regular weekly shipments</p>
                    </div>
                    <div className="d-flex flex-column align-items-start justify-content-center pt-5 px-5 pb-4 border calculator-wrapper min-h-350 position-relative bg-white">
                        <div aria-label="reset-calculator" className="position-absolute reset-calculator" onClick={() => onResetCalculator()} data-testid="calculator-reset">
                            <VscDebugRestart style={{ width: 25, height: 25, color: "#1B2B4A" }} title="Reset calculator" />
                        </div>
                        <h3>Cost of your shipment using SKUdrop</h3>
                        <p className="fz-13">SKUdrop keeps more cash in your pocket with our weekly shipping method. All at the same price as your upfront costs.</p>
                        <div className="d-flex flex-row align-items-start justify-content-start w-100">
                            <div className="d-flex flex-column w-100 align-items-start justify-content-start">
                                <Table striped id="summary">
                                    <thead>
                                        <tr>
                                            <th data-type="string" className="text-center ">
                                                Cartons
                                            </th>
                                            <th data-type="string" className="text-center ">
                                                Total shipments
                                            </th>
                                            <th data-type="string" className="text-center ">
                                                {!calculatedData?.weekly_price || !calculatedData?.week_sea_rate || priceMode
                                                    ? calculatedData?.cartons[0].cbm_measure
                                                    : calculatedData.cartons[0].week_cbm_measure}
                                            </th>
                                            <th data-type="string" className="text-center ">
                                                Weight
                                            </th>
                                            <th data-type="string" className="text-center">
                                                FBA warehouse
                                            </th>
                                            <th data-type="string" className="text-center ">
                                                Shipping service
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody id="tBody">{summaryList}</tbody>
                                </Table>
                                <div className="d-flex flex-column align-items-start justify-content-start w-100">
                                    <h5>The benefits of using SKUdrop</h5>
                                    <p className="p-landing-page">
                                        Our shipping rates algorithm has over 1,168 data points designed to find you the best price. We compare CBM
                                        <br />
                                        vs KG pricing and also calculate the best last mile transport delivery costs into Amazon FBA.
                                    </p>
                                    {is3PL && (
                                        <>
                                            <h5 about="3pl-true">Reduce expensive US 3PL costs</h5>
                                            <p className="p-landing-page">
                                                SKUdrop is a direct to Amazon logistics solution. Our customers love how SKUdrop reduces the following 3PL costs:
                                            </p>
                                            <ul style={{ paddingLeft: "1rem" }} className="list-text-p">
                                                <li>DELIVERY COSTS TO 3PL’S</li>
                                                <li>RECEIVING FEES</li>
                                                <li>PALLET FEES</li>
                                                <li>ADMIN FEES</li>
                                                <li>PREP + LABELING FEES</li>
                                                <li>SUBSCRIPTIONS</li>
                                                <li>STORAGE FEES</li>
                                                <li>AMAZON SPD/LTL SHIPPING COSTS ARE COMPLETELY REMOVED</li>
                                            </ul>
                                        </>
                                    )}
                                    {!is3PL && (
                                        <>
                                            <h5 about="3pl-false">Did you know...</h5>
                                            <p>
                                                Over 1 year across standard size, <b>SKUdrop has 66.11% cheaper storage compared to Amazon’s storage costs.</b>
                                            </p>
                                            <h5>More reasons to use SKUdrop...</h5>
                                            <ul style={{ paddingLeft: "1rem" }} className="list-text-p">
                                                <li>There’s no need to spend all your money upfront for your shipment. Keep cash in your pocket by shipping weekly</li>
                                                <li>Fast shipping plan creation means after a few clicks your plan is done, you’ll have a quote, and we’ll have your labels</li>
                                                <li>What’s powerful about storing in our Chinese warehouse is if you have any product issues, your supplier is close by</li>
                                                <li>Our software system automates those time wasting emails you send back and forth with your freight forwarder</li>
                                                <li> We pay for your tariffs upfront to keep more cash in your pocket and we charge you later</li>
                                                <li>Our customers are saving time and money by tapping into our direct to Amazon logistics model</li>
                                            </ul>
                                        </>
                                    )}
                                    <p className="p-landing-page">
                                        Set up your SKUdrop account now and <b>eliminate paying for full shipments upfront forever.</b>
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex flex-column align-items-center justify-content-center ml-3">
                                {selectedMarket === "US" ? (
                                    calculatedData?.final_price ? (
                                        <>
                                            <p className="header-title text-center table-header-style mb-0" data-testid="calculator-total-price">
                                                {!calculatedData?.weekly_price || priceMode ? "Total sea cost" : "Weekly sea cost"}
                                            </p>
                                            <h1 aria-label="calculated-price" className="cost-title mb-0">
                                                ${!calculatedData?.weekly_price || priceMode ? calculatedData?.final_price : calculatedData?.weekly_price}
                                            </h1>
                                        </>
                                    ) : (
                                        calculatedData?.air_price && (
                                            <>
                                                <p className="header-title text-center table-header-style mb-0">
                                                    {!calculatedData?.weekly_air_price || priceMode ? "Total air cost" : "Weekly air cost"}
                                                </p>
                                                <h1 aria-label="calculated-price" className="cost-title mb-0">
                                                    $
                                                    {!calculatedData?.weekly_air_price || priceMode
                                                        ? calculatedData?.air_price?.toFixed(2)
                                                        : calculatedData?.weekly_air_price?.toFixed(2)}
                                                </h1>
                                            </>
                                        )
                                    )
                                ) : calculatedData?.sea_rate ? (
                                    <>
                                        <p className="header-title text-center table-header-style mb-0" data-testid="calculator-total-price">
                                            {!calculatedData?.week_sea_rate || priceMode ? "Total sea cost" : "Weekly sea cost"}
                                        </p>
                                        <h1 aria-label="calculated-price" className="cost-title mb-0">
                                            ${!calculatedData?.week_sea_rate || priceMode ? calculatedData?.sea_rate?.toFixed(2) : calculatedData?.week_sea_rate?.toFixed(2)}
                                        </h1>
                                    </>
                                ) : (
                                    calculatedData?.truck_rate && (
                                        <>
                                            <p className="header-title text-center table-header-style mb-0">
                                                {!calculatedData?.week_truck_rate || priceMode ? "Total truck cost" : "Weekly truck cost"}
                                            </p>
                                            <h1 aria-label="calculated-price" className="cost-title mb-0">
                                                $
                                                {!calculatedData?.week_truck_rate || priceMode
                                                    ? calculatedData?.truck_rate?.toFixed(2)
                                                    : calculatedData?.week_truck_rate?.toFixed(2)}
                                            </h1>
                                        </>
                                    )
                                )}
                                {(calculatedData?.weekly_price || calculatedData?.week_sea_rate) && (
                                    <div className="d-flex align-items-center justify-content-center w-200px mb-2">
                                        <p className="fz-10 m-0">Weekly shipment</p>
                                        <Form.Check
                                            type="switch"
                                            id="switch-weight"
                                            checked={priceMode}
                                            onChange={() => {
                                                setPriceMode(!priceMode);
                                            }}
                                            data-testid="calculator-checkbox-switch"
                                        />
                                        <p className="fz-10 m-0">Full shipment</p>
                                    </div>
                                )}
                                <p className="fz-10 mb-1 font-weight-bold text-center line-height-14">Current live pricing updated weekly</p>
                                <p className="fz-10 mb-1 text-center line-height-14">Excluding tariffs, prep fees & storage</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Summary;
