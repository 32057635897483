import ReactGA from "react-ga";
import React, { useEffect } from "react";
import Footer from "../Footer/Footer";
import NavBar from "../NavBar/NavBar";
import * as Scroll from "react-scroll";

const Policy = () => {
    let scroll = Scroll.animateScroll;

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    useEffect(() => {
        scroll.scrollToTop();
    }, [scroll]);

    return (
        <div style={{ backgroundColor: "#FAFBFE" }}>
            <NavBar />
            <div className="terms-container" style={{ backgroundColor: "#FAFBFE" }}>
                <div className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in", pageBreakAfter: "avoid" }}>
                    <h1 style={{ fontSize: "30.0pt", textAlign: "center" }} lang="EN-GB">
                        SKUdrop Limited - PRIVACY POLICY
                    </h1>
                </div>

                <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                    <span lang="EN-GB">
                        We at SKUdrop respect your privacy and are committed to protecting it through our compliance with this privacy policy (“Policy”) and the{" "}
                        <i>Privacy Act 1988</i>
                        (“Privacy Act”).{" "}
                    </span>
                </p>

                <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                    <span lang="EN-GB">
                        This Privacy Policy (“Privacy Policy”) is incorporated into the SKUDROP General Terms as published on the SKUDROP Website (“Terms”). Where there is any
                        conflict or material inconsistency between this Privacy Policy and the Terms, the Terms shall prevail to the extent of any inconsistency.{" "}
                    </span>
                </p>

                <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                    <span lang="EN-GB">
                        This Privacy Policy describes the types of information we may collect from you or that you may provide (“Personal Information”) on the SKUdrop.com website
                        (“Website”), “SKUdrop” mobile application (“Mobile Application”), and any of their related products and services (collectively, “Services”), and our
                        practices for collecting, using, maintaining, protecting, and disclosing that Personal Information. It also describes the choices available to you regarding
                        our use of your Personal Information and how you can access and update it.
                    </span>
                </p>

                <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                    <span lang="EN-GB">
                        By accessing and using the Services, you acknowledge that you have read, understood, and agree to be bound by the terms of this Policy. This Policy does not
                        apply to the practices of companies that we do not own or control, or to individuals that we do not employ or manage.{" "}
                    </span>
                </p>

                <p className="MsoNormal" style={{ marginLeft: "28.3pt", textIndent: "-28.3pt", lineHeight: "115%" }}>
                    <b>
                        <span lang="EN-GB">
                            1.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                    </b>
                    <b>
                        <span lang="EN-GB">What Personal Information do we Collect?</span>
                    </b>
                </p>
                <p
                    className="MsoNormal"
                    style={{
                        marginLeft: "63.75pt",
                        textIndent: "-63.75pt",
                        lineHeight: "115%",
                    }}
                >
                    <span style={{ fontFamily: '"Arial",sans-serif' }} lang="EN-GB">
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        1.1.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <span lang="EN-GB">
                        In the course of providing services to you we may collect personal information in order to provide features of the service and improve the range of products
                        and services we can make available to you.{" "}
                    </span>
                </p>
                <p
                    className="MsoNormal"
                    style={{
                        marginLeft: "63.75pt",
                        textIndent: "-63.75pt",
                        lineHeight: "115%",
                    }}
                >
                    <span style={{ fontFamily: '"Arial",sans-serif' }} lang="EN-GB">
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        1.2.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <span lang="EN-GB">We may collect information you provide to us as set out in the examples below: </span>
                </p>

                <table
                    className="MsoNormalTable"
                    style={{
                        marginLeft: "29.75pt",
                        borderCollapse: "collapse",
                        border: "none",
                    }}
                    width={570}
                    cellSpacing={0}
                    cellPadding={0}
                    border={1}
                >
                    <tbody>
                        <tr style={{ height: "21.0pt" }}>
                            <td
                                style={{
                                    width: "192.0pt",
                                    border: "solid black 1.0pt",
                                    background: "#F3F3F3",
                                    padding: "5.0pt 5.0pt 5.0pt 5.0pt",
                                    height: "21.0pt",
                                }}
                                width={256}
                                valign="top"
                            >
                                <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                    <span style={{ fontSize: "10.0pt" }} lang="EN-GB">
                                        Source of Personal Information
                                    </span>
                                </p>
                            </td>
                            <td
                                style={{
                                    width: "235.5pt",
                                    border: "solid black 1.0pt",
                                    borderLeft: "none",
                                    background: "#F3F3F3",
                                    padding: "5.0pt 5.0pt 5.0pt 5.0pt",
                                    height: "21.0pt",
                                }}
                                width={314}
                                valign="top"
                            >
                                <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                    <span style={{ fontSize: "10.0pt", color: "black" }} lang="EN-GB">
                                        Examples of information:{" "}
                                    </span>
                                </p>
                            </td>
                        </tr>
                        <tr style={{ height: "21.0pt" }}>
                            <td
                                style={{
                                    width: "192.0pt",
                                    border: "solid black 1.0pt",
                                    borderTop: "none",
                                    padding: "5.0pt 5.0pt 5.0pt 5.0pt",
                                    height: "21.0pt",
                                }}
                                width={256}
                                valign="top"
                            >
                                <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                    <span style={{ fontSize: "10.0pt" }} lang="EN-GB">
                                        Information you provide to us:
                                    </span>
                                </p>
                            </td>
                            <td
                                style={{
                                    width: "235.5pt",
                                    borderTop: "none",
                                    borderLeft: "none",
                                    borderBottom: "solid black 1.0pt",
                                    borderRight: "solid black 1.0pt",
                                    padding: "5.0pt 5.0pt 5.0pt 5.0pt",
                                    height: "21.0pt",
                                }}
                                width={314}
                                valign="top"
                            >
                                <p
                                    className="MsoNormal"
                                    style={{
                                        marginLeft: "21.25pt",
                                        textIndent: "-14.15pt",
                                        lineHeight: "115%",
                                    }}
                                >
                                    <span style={{ fontSize: "10.0pt", lineHeight: "115%" }} lang="EN-GB">
                                        ●<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                                    </span>
                                    <span style={{ fontSize: "10.0pt", lineHeight: "115%" }} lang="EN-GB">
                                        information such as your name, address and phone numbers;{" "}
                                    </span>
                                </p>
                                <p
                                    className="MsoNormal"
                                    style={{
                                        marginLeft: "21.25pt",
                                        textIndent: "-14.15pt",
                                        lineHeight: "115%",
                                    }}
                                >
                                    <span style={{ fontSize: "10.0pt", lineHeight: "115%" }} lang="EN-GB">
                                        ●<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                                    </span>
                                    <span style={{ fontSize: "10.0pt", lineHeight: "115%" }} lang="EN-GB">
                                        payment details;{" "}
                                    </span>
                                </p>
                                <p
                                    className="MsoNormal"
                                    style={{
                                        marginLeft: "21.25pt",
                                        textIndent: "-14.15pt",
                                        lineHeight: "115%",
                                    }}
                                >
                                    <span style={{ fontSize: "10.0pt", lineHeight: "115%" }} lang="EN-GB">
                                        ●<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                                    </span>
                                    <span style={{ fontSize: "10.0pt", lineHeight: "115%" }} lang="EN-GB">
                                        Amazon Selling Partner ID;
                                    </span>
                                </p>
                                <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                    <span style={{ fontSize: "10.0pt" }} lang="EN-GB">
                                        &nbsp;
                                    </span>
                                </p>
                            </td>
                        </tr>
                        <tr style={{ height: "21.0pt" }}>
                            <td
                                style={{
                                    width: "192.0pt",
                                    border: "solid black 1.0pt",
                                    borderTop: "none",
                                    padding: "5.0pt 5.0pt 5.0pt 5.0pt",
                                    height: "21.0pt",
                                }}
                                width={256}
                                valign="top"
                            >
                                <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                    <span style={{ fontSize: "10.0pt" }} lang="EN-GB">
                                        Information automatically collected:{" "}
                                    </span>
                                </p>
                            </td>
                            <td
                                style={{
                                    width: "235.5pt",
                                    borderTop: "none",
                                    borderLeft: "none",
                                    borderBottom: "solid black 1.0pt",
                                    borderRight: "solid black 1.0pt",
                                    padding: "5.0pt 5.0pt 5.0pt 5.0pt",
                                    height: "21.0pt",
                                }}
                                width={314}
                                valign="top"
                            >
                                <p
                                    className="MsoNormal"
                                    style={{
                                        marginLeft: "21.25pt",
                                        textIndent: "-14.15pt",
                                        lineHeight: "115%",
                                    }}
                                >
                                    <span style={{ fontSize: "10.0pt", lineHeight: "115%" }} lang="EN-GB">
                                        ●<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                                    </span>
                                    <span style={{ fontSize: "10.0pt", lineHeight: "115%" }} lang="EN-GB">
                                        your login details, account password and email address;{" "}
                                    </span>
                                </p>
                                <p
                                    className="MsoNormal"
                                    style={{
                                        marginLeft: "21.25pt",
                                        textIndent: "-14.15pt",
                                        lineHeight: "115%",
                                    }}
                                >
                                    <span style={{ fontSize: "10.0pt", lineHeight: "115%" }} lang="EN-GB">
                                        ●<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                                    </span>
                                    <span style={{ fontSize: "10.0pt", lineHeight: "115%" }} lang="EN-GB">
                                        your Amazon Selling Partner ID;
                                    </span>
                                </p>
                                <p
                                    className="MsoNormal"
                                    style={{
                                        marginLeft: "21.25pt",
                                        textIndent: "-14.15pt",
                                        lineHeight: "115%",
                                    }}
                                >
                                    <span style={{ fontSize: "10.0pt", lineHeight: "115%" }} lang="EN-GB">
                                        ●<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                                    </span>
                                    <span style={{ fontSize: "10.0pt", lineHeight: "115%" }} lang="EN-GB">
                                        phone numbers used to call any support service.{" "}
                                    </span>
                                </p>
                                <p className="MsoNormal" style={{ marginLeft: ".5in", textIndent: "0in" }}>
                                    <span style={{ fontSize: "10.0pt" }} lang="EN-GB">
                                        &nbsp;
                                    </span>
                                </p>
                            </td>
                        </tr>
                        <tr style={{ height: "21.0pt" }}>
                            <td
                                style={{
                                    width: "192.0pt",
                                    border: "solid black 1.0pt",
                                    borderTop: "none",
                                    padding: "5.0pt 5.0pt 5.0pt 5.0pt",
                                    height: "21.0pt",
                                }}
                                width={256}
                                valign="top"
                            >
                                <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                    <span style={{ fontSize: "10.0pt" }} lang="EN-GB">
                                        Information from other sources:{" "}
                                    </span>
                                </p>
                            </td>
                            <td
                                style={{
                                    width: "235.5pt",
                                    borderTop: "none",
                                    borderLeft: "none",
                                    borderBottom: "solid black 1.0pt",
                                    borderRight: "solid black 1.0pt",
                                    padding: "5.0pt 5.0pt 5.0pt 5.0pt",
                                    height: "21.0pt",
                                }}
                                width={314}
                                valign="top"
                            >
                                <p
                                    className="MsoNormal"
                                    style={{
                                        marginLeft: "21.25pt",
                                        textIndent: "-14.15pt",
                                        lineHeight: "115%",
                                    }}
                                >
                                    <span style={{ fontSize: "10.0pt", lineHeight: "115%" }} lang="EN-GB">
                                        ●<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                                    </span>
                                    <span style={{ fontSize: "10.0pt", lineHeight: "115%" }} lang="EN-GB">
                                        delivery and address information from your manufacturer;{" "}
                                    </span>
                                </p>
                                <p
                                    className="MsoNormal"
                                    style={{
                                        marginLeft: "21.25pt",
                                        textIndent: "-14.15pt",
                                        lineHeight: "115%",
                                    }}
                                >
                                    <span style={{ fontSize: "10.0pt", lineHeight: "115%" }} lang="EN-GB">
                                        ●<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                                    </span>
                                    <span style={{ fontSize: "10.0pt", lineHeight: "115%" }} lang="EN-GB">
                                        account information.
                                    </span>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <p className="MsoNormal" style={{ marginLeft: "28.3pt", textIndent: "-28.3pt", lineHeight: "115%" }}>
                    <b>
                        <span lang="EN-GB">
                            2.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                    </b>
                    <b>
                        <span lang="EN-GB">Why do we Collect Personal Information?</span>
                    </b>
                </p>
                <p
                    className="MsoNormal"
                    style={{
                        marginLeft: "63.75pt",
                        textIndent: "-63.75pt",
                        lineHeight: "115%",
                    }}
                >
                    <span lang="EN-GB">
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        2.1.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <span lang="EN-GB">We may use your personal information in order to operate the Service and make improvements to our Service. </span>
                </p>
                <p
                    className="MsoNormal"
                    style={{
                        marginLeft: "63.75pt",
                        textIndent: "-63.75pt",
                        lineHeight: "115%",
                    }}
                >
                    <span lang="EN-GB">
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        2.2.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <span lang="EN-GB">
                        Processing your Personal Information depends on how you interact with the Services, where you are located in the world and if one of the following applies:{" "}
                    </span>
                </p>
                <p
                    className="MsoNormal"
                    style={{
                        marginLeft: "70.85pt",
                        textIndent: "-21.25pt",
                        lineHeight: "115%",
                    }}
                >
                    <span lang="EN-GB">
                        (a)
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <span lang="EN-GB">you have given your consent for one or more specific purposes; </span>
                </p>
                <p
                    className="MsoNormal"
                    style={{
                        marginLeft: "70.85pt",
                        textIndent: "-21.25pt",
                        lineHeight: "115%",
                    }}
                >
                    <span lang="EN-GB">
                        (b)
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <span lang="EN-GB">
                        provision of information is necessary for the performance of an agreement with you and/or for any pre-contractual obligations thereof;{" "}
                    </span>
                </p>
                <p
                    className="MsoNormal"
                    style={{
                        marginLeft: "70.85pt",
                        textIndent: "-21.25pt",
                        lineHeight: "115%",
                    }}
                >
                    <span lang="EN-GB">
                        (c)
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <span lang="EN-GB">where processing is necessary for compliance with a legal obligation to which you are subject; </span>
                </p>
                <p
                    className="MsoNormal"
                    style={{
                        marginLeft: "70.85pt",
                        textIndent: "-21.25pt",
                        lineHeight: "115%",
                    }}
                >
                    <span lang="EN-GB">
                        (d)
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <span lang="EN-GB">processing is related to a task that is carried out in the public interest or in the exercise of official authority vested in us; </span>
                </p>
                <p
                    className="MsoNormal"
                    style={{
                        marginLeft: "70.85pt",
                        textIndent: "-21.25pt",
                        lineHeight: "115%",
                    }}
                >
                    <span lang="EN-GB">
                        (e)
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <span lang="EN-GB">where processing is necessary for the purposes of the legitimate interests pursued by us or by a third party.</span>
                </p>
                <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-1.0in", lineHeight: "115%" }}>
                    <span lang="EN-GB">
                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                        2.3.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <span lang="EN-GB">The purposes for which we use your Personal Information include:</span>
                </p>
                <p className="MsoNormal" style={{ marginLeft: "70.85pt", lineHeight: "115%" }}>
                    <span lang="EN-GB">
                        (a)
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
                    </span>
                    <b>
                        <span lang="EN-GB">(Provision of Service)</span>
                    </b>
                    <span lang="EN-GB"> to provide you with the Service, handle orders, deliver products, payment processing and to communicate with you about the Service.</span>
                </p>
                <p className="MsoNormal" style={{ marginLeft: "70.85pt", lineHeight: "115%" }}>
                    <span lang="EN-GB">
                        (b)
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
                    </span>
                    <b>
                        <span lang="EN-GB">(Support)</span>
                    </b>
                    <span lang="EN-GB">
                        {" "}
                        We may use your Personal Information to improve the usability, functionality, reliability and to fix errors in the Services. We may also use your Personal
                        Information to communicate with you or answer support requests that you submit.{" "}
                    </span>
                </p>
                <p className="MsoNormal" style={{ marginLeft: "70.85pt", lineHeight: "115%" }}>
                    <span lang="EN-GB">
                        (c)
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <b>
                        <span lang="EN-GB">(Suggestions)</span>
                    </b>
                    <span lang="EN-GB">
                        {" "}
                        In certain circumstances we may use your Personal Information to recommend additional features, products or services that we believe will be of interest to
                        you.{" "}
                    </span>
                </p>
                <p className="MsoNormal" style={{ marginLeft: "70.85pt", lineHeight: "115%" }}>
                    <span lang="EN-GB">
                        (d)
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
                    </span>
                    <b>
                        <span lang="EN-GB">(Compliance with Law)</span>
                    </b>
                    <span lang="EN-GB">
                        {" "}
                        We may collect and use your Personal Information where we are required to do so by any applicable Law or Regulation, for example where we retain your
                        information to comply with any Know-Your-Customer (“KYC”) requirements of any Law or Regulation.{" "}
                    </span>
                </p>
                <p className="MsoNormal" style={{ marginLeft: "70.85pt", lineHeight: "115%" }}>
                    <span lang="EN-GB">
                        (e)
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp; </span>
                    </span>
                    <b>
                        <span lang="EN-GB">(Breach of Law and Fraud Prevention)</span>
                    </b>
                    <span lang="EN-GB"> We may also use your Personal Information to detect, prevent and report fraud, crime or breaches of any Law or Regulation.</span>
                </p>
                <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                    <b>
                        <span lang="EN-GB">&nbsp;</span>
                    </b>
                </p>
                <p className="MsoNormal" style={{ marginLeft: "28.3pt", textIndent: "-28.3pt", lineHeight: "115%" }}>
                    <b>
                        <span lang="EN-GB">
                            <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;</span>3.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                    </b>
                    <b>
                        <span lang="EN-GB">Who do we share your information with?</span>
                    </b>
                </p>
                <p
                    className="MsoNormal"
                    style={{
                        marginLeft: "63.75pt",
                        textIndent: "-63.75pt",
                        lineHeight: "115%",
                    }}
                >
                    <span lang="EN-GB">
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        3.1.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <b>
                        <span lang="EN-GB">Third Party Transactions.</span>
                    </b>
                    <span lang="EN-GB">
                        {" "}
                        We may share your information with our affiliates, third-party contractors and service providers (collectively, “Service Providers”) where necessary to
                        complete any transaction or provide any Service you have requested. We will not share any personally identifiable information with third parties and will
                        not share any information with unaffiliated third parties. These service providers may not be located in Australia.
                    </span>
                </p>
                <p
                    className="MsoNormal"
                    style={{
                        marginLeft: "63.75pt",
                        textIndent: "-63.75pt",
                        lineHeight: "115%",
                    }}
                >
                    <span lang="EN-GB">
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        3.2.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <b>
                        <span lang="EN-GB">Business Sale.</span>
                    </b>
                    <span lang="EN-GB">
                        {" "}
                        In circumstances where SKUdrop enters into negotiations regarding a Business Sale or enters into a contract for Business Sale, we may transfer your Personal
                        Information to the purchaser of the business as a component of the assets transferred to the Purchaser.{" "}
                    </span>
                </p>
                <p
                    className="MsoNormal"
                    style={{
                        marginLeft: "63.75pt",
                        textIndent: "-63.75pt",
                        lineHeight: "115%",
                    }}
                >
                    <span lang="EN-GB">
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        3.3.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <b>
                        <span lang="EN-GB">Legal Compliance.</span>
                    </b>
                    <span lang="EN-GB">
                        We reserve the right to release your account details and other Personal Information when we believe such release is necessary or appropriate to comply with
                        any applicable Law or Regulation or enforce these terms and other agreements.
                    </span>
                </p>
                <p
                    className="MsoNormal"
                    style={{
                        marginLeft: "63.75pt",
                        textIndent: "-63.75pt",
                        lineHeight: "115%",
                    }}
                >
                    <span lang="EN-GB">
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        3.4.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <b>
                        <span lang="EN-GB">Third Party Service Providers.</span>
                    </b>
                    <span lang="EN-GB">
                        {" "}
                        Service Providers are given only the information they require in order to perform their functions and are not authorised to use or disclose your Personal
                        Information except to the extent necessary to perform the Services or comply with legal requirements.{" "}
                    </span>
                </p>
                <p className="MsoNormal" style={{ marginLeft: ".9in", textIndent: "-.9in", lineHeight: "115%" }}>
                    <span lang="EN-GB">
                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                        3.5.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <span lang="EN-GB">
                        Some of the following example categories of Service Providers may receive your Personal Information depending on what Services you engage us to provide:{" "}
                    </span>
                </p>

                <table
                    className="MsoNormalTable"
                    style={{
                        marginLeft: "29.75pt",
                        borderCollapse: "collapse",
                        border: "none",
                    }}
                    width={570}
                    cellSpacing={0}
                    cellPadding={0}
                    border={1}
                >
                    <tbody>
                        <tr style={{ height: "21.0pt" }}>
                            <td
                                style={{
                                    width: "225.0pt",
                                    border: "solid black 1.0pt",
                                    background: "#F3F3F3",
                                    padding: "5.0pt 5.0pt 5.0pt 5.0pt",
                                    height: "21.0pt",
                                }}
                                width={300}
                                valign="top"
                            >
                                <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                    <span style={{ fontSize: "10.0pt" }} lang="EN-GB">
                                        Type of Service Provider
                                    </span>
                                </p>
                            </td>
                            <td
                                style={{
                                    width: "202.5pt",
                                    border: "solid black 1.0pt",
                                    borderLeft: "none",
                                    background: "#F3F3F3",
                                    padding: "5.0pt 5.0pt 5.0pt 5.0pt",
                                    height: "21.0pt",
                                }}
                                width={270}
                                valign="top"
                            >
                                <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                    <span style={{ fontSize: "10.0pt", color: "black" }} lang="EN-GB">
                                        Examples{" "}
                                    </span>
                                </p>
                            </td>
                        </tr>
                        <tr style={{ height: "21.0pt" }}>
                            <td
                                style={{
                                    width: "225.0pt",
                                    border: "solid black 1.0pt",
                                    borderTop: "none",
                                    padding: "5.0pt 5.0pt 5.0pt 5.0pt",
                                    height: "21.0pt",
                                }}
                                width={300}
                                valign="top"
                            >
                                <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                    <span lang="EN-GB">Data analytics services</span>
                                </p>
                            </td>
                            <td
                                style={{
                                    width: "202.5pt",
                                    borderTop: "none",
                                    borderLeft: "none",
                                    borderBottom: "solid black 1.0pt",
                                    borderRight: "solid black 1.0pt",
                                    padding: "5.0pt 5.0pt 5.0pt 5.0pt",
                                    height: "21.0pt",
                                }}
                                width={270}
                                valign="top"
                            >
                                <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                    <span style={{ fontSize: "10.0pt" }} lang="EN-GB">
                                        N/A
                                    </span>
                                </p>
                            </td>
                        </tr>
                        <tr style={{ height: "21.0pt" }}>
                            <td
                                style={{
                                    width: "225.0pt",
                                    border: "solid black 1.0pt",
                                    borderTop: "none",
                                    padding: "5.0pt 5.0pt 5.0pt 5.0pt",
                                    height: "21.0pt",
                                }}
                                width={300}
                                valign="top"
                            >
                                <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                    <span lang="EN-GB">Data storage services</span>
                                </p>
                            </td>
                            <td
                                style={{
                                    width: "202.5pt",
                                    borderTop: "none",
                                    borderLeft: "none",
                                    borderBottom: "solid black 1.0pt",
                                    borderRight: "solid black 1.0pt",
                                    padding: "5.0pt 5.0pt 5.0pt 5.0pt",
                                    height: "21.0pt",
                                }}
                                width={270}
                                valign="top"
                            >
                                <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                    <span style={{ fontSize: "10.0pt" }} lang="EN-GB">
                                        AWS
                                    </span>
                                </p>
                            </td>
                        </tr>
                        <tr style={{ height: "21.0pt" }}>
                            <td
                                style={{
                                    width: "225.0pt",
                                    border: "solid black 1.0pt",
                                    borderTop: "none",
                                    padding: "5.0pt 5.0pt 5.0pt 5.0pt",
                                    height: "21.0pt",
                                }}
                                width={300}
                                valign="top"
                            >
                                <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                    <span lang="EN-GB">Social networks</span>
                                </p>
                            </td>
                            <td
                                style={{
                                    width: "202.5pt",
                                    borderTop: "none",
                                    borderLeft: "none",
                                    borderBottom: "solid black 1.0pt",
                                    borderRight: "solid black 1.0pt",
                                    padding: "5.0pt 5.0pt 5.0pt 5.0pt",
                                    height: "21.0pt",
                                }}
                                width={270}
                                valign="top"
                            >
                                <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                    <span style={{ fontSize: "10.0pt" }} lang="EN-GB">
                                        N/A
                                    </span>
                                </p>
                            </td>
                        </tr>
                        <tr style={{ height: "21.0pt" }}>
                            <td
                                style={{
                                    width: "225.0pt",
                                    border: "solid black 1.0pt",
                                    borderTop: "none",
                                    padding: "5.0pt 5.0pt 5.0pt 5.0pt",
                                    height: "21.0pt",
                                }}
                                width={300}
                                valign="top"
                            >
                                <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                    <span lang="EN-GB">User authentication services</span>
                                </p>
                            </td>
                            <td
                                style={{
                                    width: "202.5pt",
                                    borderTop: "none",
                                    borderLeft: "none",
                                    borderBottom: "solid black 1.0pt",
                                    borderRight: "solid black 1.0pt",
                                    padding: "5.0pt 5.0pt 5.0pt 5.0pt",
                                    height: "21.0pt",
                                }}
                                width={270}
                                valign="top"
                            >
                                <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                    <span style={{ fontSize: "10.0pt" }} lang="EN-GB">
                                        Google Captcha
                                    </span>
                                </p>
                            </td>
                        </tr>
                        <tr style={{ height: "21.0pt" }}>
                            <td
                                style={{
                                    width: "225.0pt",
                                    border: "solid black 1.0pt",
                                    borderTop: "none",
                                    padding: "5.0pt 5.0pt 5.0pt 5.0pt",
                                    height: "21.0pt",
                                }}
                                width={300}
                                valign="top"
                            >
                                <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                    <span lang="EN-GB">Website hosting service providers</span>
                                </p>
                            </td>
                            <td
                                style={{
                                    width: "202.5pt",
                                    borderTop: "none",
                                    borderLeft: "none",
                                    borderBottom: "solid black 1.0pt",
                                    borderRight: "solid black 1.0pt",
                                    padding: "5.0pt 5.0pt 5.0pt 5.0pt",
                                    height: "21.0pt",
                                }}
                                width={270}
                                valign="top"
                            >
                                <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                                    <span style={{ fontSize: "10.0pt" }} lang="EN-GB">
                                        AWS
                                    </span>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <p className="MsoNormal" style={{ marginLeft: "28.3pt", textIndent: "-28.3pt", lineHeight: "115%" }}>
                    <b>
                        <span lang="EN-GB">
                            <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;</span>4.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                    </b>
                    <b>
                        <span lang="EN-GB">Privacy of Children</span>
                    </b>
                </p>
                <p
                    className="MsoNormal"
                    style={{
                        marginLeft: "63.75pt",
                        textIndent: "-63.75pt",
                        lineHeight: "115%",
                    }}
                >
                    <span lang="EN-GB">
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        4.1.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <span lang="EN-GB">Persons under the age of 18 may not use any of the Services and must not submit any Personal Information . </span>
                </p>
                <p
                    className="MsoNormal"
                    style={{
                        marginLeft: "63.75pt",
                        textIndent: "-63.75pt",
                        lineHeight: "115%",
                    }}
                >
                    <span lang="EN-GB">
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        4.2.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <span lang="EN-GB">
                        If you believe that a person under the age of 18 has provided Personal Information to us, please contact us immediately to request that we delete that
                        person’s Personal Information from our Services.{" "}
                    </span>
                </p>
                <p
                    className="MsoNormal"
                    style={{
                        marginLeft: "63.75pt",
                        textIndent: "-63.75pt",
                        lineHeight: "115%",
                    }}
                >
                    <span lang="EN-GB">
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        4.3.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <span lang="EN-GB">
                        We ask that all parents and guardians overseeing the care of children take all necessary precautions to ensure that their children never give out Personal
                        Information when online.
                    </span>
                </p>

                <p className="MsoNormal" style={{ marginLeft: "28.3pt", textIndent: "-28.3pt", lineHeight: "115%" }}>
                    <b>
                        <span lang="EN-GB">
                            <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;</span>5.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                    </b>
                    <b>
                        <span lang="EN-GB">Managing your Information</span>
                    </b>
                </p>
                <p
                    className="MsoNormal"
                    style={{
                        marginLeft: "63.75pt",
                        textIndent: "-63.75pt",
                        lineHeight: "115%",
                    }}
                >
                    <span lang="EN-GB">
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        5.1.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <span lang="EN-GB">
                        You are able to delete certain Personal Information we have about you upon request. We may maintain a copy of the unrevised Personal Information in our
                        records for the duration necessary to comply with our obligations to our affiliates and partners, and for the purposes described below.{" "}
                    </span>
                </p>
                <p
                    className="MsoNormal"
                    style={{
                        marginLeft: "63.75pt",
                        textIndent: "-63.75pt",
                        lineHeight: "115%",
                    }}
                >
                    <span lang="EN-GB">
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        5.2.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <span lang="EN-GB">
                        If you would like to delete your Personal Information or permanently delete your account, you can do so on the settings page of your account on the
                        Services.
                    </span>
                </p>
                <p
                    className="MsoNormal"
                    style={{
                        marginLeft: "63.75pt",
                        textIndent: "-63.75pt",
                        lineHeight: "115%",
                    }}
                >
                    <span lang="EN-GB">
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        5.3.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <span lang="EN-GB">
                        We will retain and use your Personal Information for the period necessary to comply with our legal obligations, as long as your user account remains active,
                        to enforce our agreements, resolve disputes, and unless a longer retention period is required or permitted by law.
                    </span>
                </p>
                <p
                    className="MsoNormal"
                    style={{
                        marginLeft: "63.75pt",
                        textIndent: "-63.75pt",
                        lineHeight: "115%",
                    }}
                >
                    <span lang="EN-GB">
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        5.4.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <span lang="EN-GB">
                        We may also use any aggregated data derived from or incorporating your Personal Information after you update or delete it, so long as it is stored in an
                        anonymised form in a manner that would identify you personally.{" "}
                    </span>
                </p>
                <p
                    className="MsoNormal"
                    style={{
                        marginLeft: "63.75pt",
                        textIndent: "-63.75pt",
                        lineHeight: "115%",
                    }}
                >
                    <span lang="EN-GB">
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        5.5.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <span lang="EN-GB">
                        Our retention period is set by internal policies that may vary from time to time and once the retention period expires, Personal Information shall be
                        deleted automatically. The right to access, the right to erasure, the right to rectification, and the right to data portability cannot be enforced after the
                        expiration of the retention period.
                    </span>
                </p>

                <p className="MsoNormal" style={{ marginLeft: "28.3pt", textIndent: "-28.3pt", lineHeight: "115%" }}>
                    <b>
                        <span lang="EN-GB">
                            <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;</span>6.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                    </b>
                    <b>
                        <span lang="EN-GB">Cookies and Other Tracking</span>
                    </b>
                </p>
                <p
                    className="MsoNormal"
                    style={{
                        marginLeft: "63.75pt",
                        textIndent: "-63.75pt",
                        lineHeight: "115%",
                    }}
                >
                    <span lang="EN-GB">
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        6.1.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <span lang="EN-GB">
                        Our Service may use cookies, tracking pixels and other tracking technology (“Cookies”) to recognise your device, personalise your experience and provide you
                        with some components of the Service.
                    </span>
                </p>
                <p
                    className="MsoNormal"
                    style={{
                        marginLeft: "63.75pt",
                        textIndent: "-63.75pt",
                        lineHeight: "115%",
                    }}
                >
                    <span lang="EN-GB">
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        6.2.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <span lang="EN-GB">
                        If you choose to decline Cookies or use a browser that restricts Cookies, you may not be able to fully experience the features of the Services.{" "}
                    </span>
                </p>
                <p
                    className="MsoNormal"
                    style={{
                        marginLeft: "63.75pt",
                        textIndent: "-63.75pt",
                        lineHeight: "115%",
                    }}
                >
                    <span lang="EN-GB">
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        6.3.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <span lang="EN-GB">
                        In addition, our Services may use third-party analytics tools that use Cookies, web beacons, or other similar technologies to collect internet activity and
                        usage information. This information is used to produce statistical reports on User activity such as how often Users visit our Services, what pages they
                        visit and for how long. This information is used to monitor and improve the Services.
                    </span>
                </p>
                <p
                    className="MsoNormal"
                    style={{
                        marginLeft: "63.75pt",
                        textIndent: "-63.75pt",
                        lineHeight: "115%",
                    }}
                >
                    <span lang="EN-GB">
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        6.4.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <span lang="EN-GB">
                        Certain components of our Service may incorporate social media features, such as Facebook/Twitter share buttons, etc (collectively, “Social Media
                        Features”). These Social Media Features may collect data about you and may use cookies to function properly. Social Media Features may be hosted directly on
                        our Services or by the respective provider. Your use of these Social Media Features is governed by the policies of their respective providers.
                    </span>
                </p>

                <p className="MsoNormal" style={{ marginLeft: "28.3pt", textIndent: "-28.3pt", lineHeight: "115%" }}>
                    <b>
                        <span lang="EN-GB">
                            7.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                    </b>
                    <b>
                        <span lang="EN-GB">Email Communications</span>
                    </b>
                </p>
                <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-1.0in", lineHeight: "115%" }}>
                    <span lang="EN-GB">
                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                        7.1.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <span lang="EN-GB">
                        Where you consent to receive email communications from us we may send you electronic newsletters and promotions that may be of interest to you
                        (“Newsletters”).{" "}
                    </span>
                </p>
                <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-1.0in", lineHeight: "115%" }}>
                    <span lang="EN-GB">
                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                        7.2.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <span lang="EN-GB">
                        We are committed to keeping your e-mail address confidential and will not disclose your email address to any third parties except where such disclosure is
                        allowed in the information use and processing section or as necessary to provide to a third-party provider to send and manage such emails.{" "}
                    </span>
                </p>
                <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-1.0in", lineHeight: "115%" }}>
                    <span lang="EN-GB">
                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                        7.3.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <span lang="EN-GB">
                        To ensure compliance with the <i>Spam Act 2003</i>
                        (Cth) (the “Spam Act”)&nbsp; we will ask for your permission to send you Newsletters and you may unsubscribe at any time.{" "}
                    </span>
                </p>
                <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-1.0in", lineHeight: "115%" }}>
                    <span lang="EN-GB">
                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                        7.4.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <span lang="EN-GB">
                        If you choose to unsubscribe from the Newsletters we will still send you necessary informational and administrative emails related to provision of the
                        Service..
                    </span>
                </p>

                <p className="MsoNormal" style={{ marginLeft: "28.3pt", textIndent: "-28.3pt", lineHeight: "115%" }}>
                    <b>
                        <span lang="EN-GB">
                            8.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                    </b>
                    <b>
                        <span lang="EN-GB">Security of your Information</span>
                    </b>
                </p>
                <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-1.0in", lineHeight: "115%" }}>
                    <span lang="EN-GB">
                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                        8.1.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <b>
                        <span lang="EN-GB">(External Links)</span>
                    </b>
                    <span lang="EN-GB">
                        The Service may contain links to external resources that are not controlled by us. We are not responsible for the privacy practices of such other external
                        resources or third parties and we encourage you to read the Privacy Policies of any external resource or third-party website before providing any Personal
                        Information to them.{" "}
                    </span>
                </p>
                <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-1.0in", lineHeight: "115%" }}>
                    <span lang="EN-GB">
                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                        8.2.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <b>
                        <span lang="EN-GB">(Security Practices)</span>
                    </b>
                    <span lang="EN-GB">
                        {" "}
                        Personal Information you provide to us is stored in a secure environment and only accessible by authorised parties. We maintain reasonable internal
                        technical, procedural and physical safeguards to protect against unauthorised use, access, modification and disclosure of your information.{" "}
                    </span>
                </p>
                <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-1.0in", lineHeight: "115%" }}>
                    <span lang="EN-GB">
                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                        8.3.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <b>
                        <span lang="EN-GB">(Limitations)</span>
                    </b>
                    <span lang="EN-GB">While we do our best to protect your Personal Information, we advise and you acknowledge that: </span>
                </p>
                <p className="MsoNormal" style={{ marginLeft: "92.1pt", textIndent: "-21.25pt", lineHeight: "115%" }}>
                    <span lang="EN-GB">
                        (a)
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <span lang="EN-GB">certain factors that may affect your security and privacy online are beyond our control; </span>
                </p>
                <p className="MsoNormal" style={{ marginLeft: "92.1pt", textIndent: "-21.25pt", lineHeight: "115%" }}>
                    <span lang="EN-GB">
                        (b)
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <span lang="EN-GB">we cannot guarantee the security, integrity, and privacy of information/data exchanged between you and the Services; </span>
                </p>
                <p className="MsoNormal" style={{ marginLeft: "92.1pt", textIndent: "-21.25pt", lineHeight: "115%" }}>
                    <span lang="EN-GB">
                        (c)
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <span lang="EN-GB">a third party may tamper with your information/data whilst in transit despite any efforts on our part; and </span>
                </p>
                <p className="MsoNormal" style={{ marginLeft: "92.1pt", textIndent: "-21.25pt", lineHeight: "115%" }}>
                    <span lang="EN-GB">
                        (d)
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <span lang="EN-GB">
                        as the security of Personal Information depends in part on the security of the device you use to communicate with us and the security you use to protect
                        your credentials, you represent and warrant that you take all reasonable measures to protect this information.
                    </span>
                </p>
                <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-1.0in", lineHeight: "115%" }}>
                    <span lang="EN-GB">
                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                        8.4.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <b>
                        <span lang="EN-GB">(Data Breach)</span>
                    </b>
                    <span lang="EN-GB">
                        {" "}
                        If we become aware that Users’ Personal Information has been compromised or disclosed to unrelated third parties as a result of hacking or fraud, you will
                        be notified of the breach and we reserve the right to take all appropriate measures, including investigation and notification to and cooperation with law
                        enforcement authorities.{" "}
                    </span>
                </p>

                <p className="MsoNormal" style={{ marginLeft: "28.3pt", textIndent: "-28.3pt", lineHeight: "115%" }}>
                    <b>
                        <span lang="EN-GB">
                            9.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                    </b>
                    <b>
                        <span lang="EN-GB">GDPR Addendum</span>
                    </b>
                </p>
                <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-1.0in", lineHeight: "115%" }}>
                    <span lang="EN-GB">
                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                        9.1.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <span lang="EN-GB">
                        Where the European Union General Data Protection Regulation (Regulation (EU) 2016/679) (“GDPR”) applies to our provision of the Service or your use or
                        access of the Service, the provisions of this clause shall apply and prevail to the extent of any inconsistency.
                    </span>
                </p>
                <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-1.0in", lineHeight: "115%" }}>
                    <span lang="EN-GB">
                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                        9.2.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <span lang="EN-GB">
                        Where the GDPR applies, the use, handling, disclosure, transfer, sharing or processing in any way and for any purpose, any information that relates to an
                        identified or identifiable individual shall constitute “Personal Data”.{" "}
                    </span>
                </p>
                <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-1.0in", lineHeight: "115%" }}>
                    <span lang="EN-GB">
                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                        9.3.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <span lang="EN-GB">
                        The Parties acknowledge and agree that all Personal Data disclosed by one Party and processed by the other Party as part of this Agreement is Confidential
                        Information of the disclosing Party and is subject to the confidentiality obligations set out in this Agreement.{" "}
                    </span>
                </p>
                <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-1.0in", lineHeight: "115%" }}>
                    <span lang="EN-GB">
                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                        9.4.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <span lang="EN-GB">
                        Notwithstanding its obligations of confidentiality SKUdrop may copy, modify, distribute, and otherwise use Personal Data received from or on behalf of
                        Customer for purposes of providing the Services.{" "}
                    </span>
                </p>
                <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-1.0in", lineHeight: "115%" }}>
                    <span lang="EN-GB">
                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                        9.5.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <span lang="EN-GB">
                        You represent and warrant that you have or will obtain all permissions, consents, and authorisations that are required by applicable law for Customer to
                        provide, or to arrange for the provision of, Personal Data to SKUdrop.{" "}
                    </span>
                </p>
                <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-1.0in", lineHeight: "115%" }}>
                    <span lang="EN-GB">
                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                        9.6.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <span lang="EN-GB">
                        We act as a data controller and a data processor when handling Personal Data, unless we have entered into a data processing agreement with you in which case
                        you would be the data controller and we would be the data processor.
                    </span>
                </p>
                <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-1.0in", lineHeight: "115%" }}>
                    <span lang="EN-GB">
                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                        9.7.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <span lang="EN-GB">Our role will differ depending on the specific situation involving Personal Information. For example: </span>
                </p>
                <p className="MsoNormal" style={{ marginLeft: "92.1pt", textIndent: "-21.25pt", lineHeight: "115%" }}>
                    <span lang="EN-GB">
                        (a)
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <span lang="EN-GB">
                        we act in the capacity of a data controller when you submit Personal Information that is necessary to ensure your access and use of the Services;&nbsp;{" "}
                    </span>
                </p>
                <p className="MsoNormal" style={{ marginLeft: "92.1pt", textIndent: "-21.25pt", lineHeight: "115%" }}>
                    <span lang="EN-GB">
                        (b)
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <span lang="EN-GB">
                        we act in the capacity of a data processor when you submit Personal Information through the Services. We do not own, control, or make decisions about the
                        submitted Personal Information, and such Personal Information is processed only in accordance with your instructions. In such instances, the User providing
                        Personal Information acts as a data controller.
                    </span>
                </p>
                <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-1.0in", lineHeight: "115%" }}>
                    <span lang="EN-GB">
                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                        9.8.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <span lang="EN-GB">
                        To the extent that SKUdrop “processes” (as that term is defined in the GDPR) Personal Data subject to the GDPR on behalf of Customer, SKUdrop shall:{" "}
                    </span>
                </p>
                <p className="MsoNormal" style={{ marginLeft: "92.1pt", textIndent: "-21.25pt", lineHeight: "115%" }}>
                    <span lang="EN-GB">
                        (a)
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <span lang="EN-GB">
                        only process the Personal Data provided by Customer in accordance with its instructions, for no other purposes than those determined by Customer and in
                        compliance with the provisions of the Data Protection Laws and Regulations;
                    </span>
                </p>
                <p className="MsoNormal" style={{ marginLeft: "92.1pt", textIndent: "-21.25pt", lineHeight: "115%" }}>
                    <span lang="EN-GB">
                        (b)
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <span lang="EN-GB">
                        take reasonable steps to ensure that employees having access to the personal data processed as part of providing the Services under this Agreement are
                        properly trained and subject to confidentiality obligations;{" "}
                    </span>
                </p>
                <p className="MsoNormal" style={{ marginLeft: "92.1pt", textIndent: "-21.25pt", lineHeight: "115%" }}>
                    <span lang="EN-GB">
                        (c)
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <span lang="EN-GB">
                        take reasonable technical and organisational measures against the unauthorised or unlawful processing of that Personal Data and against accidental loss or
                        destruction of, or damage of Personal Data;
                    </span>
                </p>
                <p className="MsoNormal" style={{ marginLeft: "92.1pt", textIndent: "-21.25pt", lineHeight: "115%" }}>
                    <span lang="EN-GB">
                        (d)
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <span lang="EN-GB">
                        inform the Customer of any data subject request, third party notices, breach of security or loss of Customer’s Personal Data and cooperate with Customer to
                        ensure compliance with the applicable Data Protection Laws and Regulations; /provide reasonable assistance to the Customer to allow it to comply with its
                        obligations under the Data Protection Laws and Regulations, including data subject requests; and
                    </span>
                </p>
                <p className="MsoNormal" style={{ marginLeft: "92.1pt", textIndent: "-21.25pt", lineHeight: "115%" }}>
                    <span lang="EN-GB">
                        (e)
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <span lang="EN-GB">
                        upon termination of the Agreement, cease all processing of Customer’s Personal Data and shall delete or, upon Customer’s request, return, all files
                        containing the Personal Data.
                    </span>
                </p>
                <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-1.0in", lineHeight: "115%" }}>
                    <span lang="EN-GB">
                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                        9.9.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <span lang="EN-GB">
                        SKUdrop agrees, upon reasonable notice by Customer, to submit to audits or have an independent third-party auditor, inspector, regulator, and other
                        representative, agreed in writing by the Customer and SKUdrop, to perform an audit on its behalf in order to validate SKUdrop’s compliance with its
                        obligations under this Agreement and the applicable Data Protection Laws and Regulations. All such audits shall be at Customer’s sole cost and expense.
                    </span>
                </p>
                <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-1.0in", lineHeight: "115%" }}>
                    <span lang="EN-GB">
                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                        9.10.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <span lang="EN-GB">
                        In the event either of the Parties breaches and fails to comply with the terms and conditions on data protection provided in this Agreement, the breaching
                        Party shall be held liable for all damages and costs incurred by the other Party.
                    </span>
                </p>
                <p className="MsoNormal" style={{ marginLeft: "1.0in", textIndent: "-1.0in", lineHeight: "115%" }}>
                    <span lang="EN-GB">
                        <span style={{ font: '7.0pt "Times New Roman"' }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                        9.11.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <span lang="EN-GB">
                        The Customer shall indemnify and hold SKUdrop harmless from any liability, losses, claims, penalties, damages, costs and expenses of whatever nature,
                        imposed by the any regulatory body on SKUdrop and arising out of any claims, actions, proceedings or settlements, resulting from the breach or
                        non-compliance of Customer with the terms and conditions on data protection set forth in this Agreement and/or with the applicable Data Protection Laws and
                        Regulations.
                    </span>
                </p>

                <p className="MsoNormal" style={{ marginLeft: "28.3pt", textIndent: "-28.3pt", lineHeight: "115%" }}>
                    <b>
                        <span lang="EN-GB">
                            10.
                            <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        </span>
                    </b>
                    <b>
                        <span lang="EN-GB">General Provisions</span>
                    </b>
                </p>
                <p
                    className="MsoNormal"
                    style={{
                        marginLeft: "63.75pt",
                        textIndent: "-63.75pt",
                        lineHeight: "115%",
                    }}
                >
                    <span lang="EN-GB">
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        10.1.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <b>
                        <span lang="EN-GB">(Amendments to Policy)</span>
                    </b>
                    <span lang="EN-GB">
                        {" "}
                        We reserve the right to modify this Policy or its terms related to the Services at any time at our sole discretion. When such amendments are made we will
                        change the version number and date at the bottom of this page and send you an email to notify you.{" "}
                    </span>
                </p>
                <p
                    className="MsoNormal"
                    style={{
                        marginLeft: "63.75pt",
                        textIndent: "-63.75pt",
                        lineHeight: "115%",
                    }}
                >
                    <span lang="EN-GB">
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        10.2.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <b>
                        <span lang="EN-GB">(Effective Date of Amendments)</span>
                    </b>
                    <span lang="EN-GB">
                        {" "}
                        Any updated versions of this Policy will be effective from the date that the revised policy is posted to this website. Your continued use of the Services
                        after the effective date of the revised Policy (or such other act specified at that time) will constitute your consent to those amendments.
                    </span>
                </p>
                <p
                    className="MsoNormal"
                    style={{
                        marginLeft: "63.75pt",
                        textIndent: "-63.75pt",
                        lineHeight: "115%",
                    }}
                >
                    <span lang="EN-GB">
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        10.3.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <b>
                        <span lang="EN-GB">(Acceptance)</span>
                    </b>
                    <span lang="EN-GB">
                        {" "}
                        You acknowledge that you have read this Policy and agree to all its terms and conditions. By accessing and using the Service and submitting your information
                        you agree to be bound by this Policy. If you do not agree to abide by the terms of this Policy, you should immediately cease use of the Service.
                    </span>
                </p>
                <p
                    className="MsoNormal"
                    style={{
                        marginLeft: "63.75pt",
                        textIndent: "-63.75pt",
                        lineHeight: "115%",
                    }}
                >
                    <span lang="EN-GB">
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        10.4.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <b>
                        <span lang="EN-GB">(Contacting Us)</span>
                    </b>
                    <span lang="EN-GB">
                        If you have any questions, concerns, or complaints regarding this Policy, the information we hold about you, or if you wish to exercise your rights, we
                        encourage you to contact us using the details below:{" "}
                        <a href="mailto:privacy@skudrop.com.au">
                            <span style={{ color: "#1155CC" }}>privacy@SKUdrop.com.au</span>
                        </a>
                    </span>
                </p>
                <p
                    className="MsoNormal"
                    style={{
                        marginLeft: "63.75pt",
                        textIndent: "-63.75pt",
                        lineHeight: "115%",
                    }}
                >
                    <span lang="EN-GB">
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        10.5.
                        <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </span>
                    <span lang="EN-GB">
                        We will attempt to resolve complaints and disputes and make every reasonable effort to honour your wish to exercise your rights as quickly as possible and
                        in any event, within the timescales provided by applicable Data Protection Laws.
                    </span>
                </p>
                <p className="MsoNormal" style={{ marginLeft: "0in", textIndent: "0in" }}>
                    <i>
                        <span style={{ fontSize: "14.0pt" }} lang="EN-GB">
                            &nbsp;
                        </span>
                    </i>
                </p>
                <p className="MsoNormal" style={{ marginLeft: ".25in", textIndent: "0in" }}>
                    <i>
                        <span style={{ fontSize: "14.0pt" }} lang="EN-GB">
                            This document was last updated on 05 Sep 2022
                        </span>
                    </i>
                </p>
            </div>

            <Footer />
        </div>
    );
};

export default Policy;
