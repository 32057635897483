import { FC } from "react";
import { FaPlane } from "react-icons/fa";
import { RiShipFill } from "react-icons/ri";

import carton_img from "../../../assets/img/LandingPage/HowWorksSection/how_works_2.png";
import pricing_arrow from "../../../assets/img/LandingPage/PricingSection/Pricing_panel_arrow.png";

export type PricingRatesCardPropsType = {
    mobile?: boolean;
};

const PricingRatesCard: FC<PricingRatesCardPropsType> = ({ mobile }) => {
    return (
        <div className="pricing-rates-card-container position-relative">
            <div className="pricing-rates-card-title">
                <h2 className="pricing-rates-card-title-header">SHIPPING</h2>
                <h5 className="pricing-rates-card-title-header">RATES</h5>
            </div>
            <div className="pricing-rates-card-info-container">
                <p className="pricing-rates-card-info-feature">SKUdrop offers multiple shipping carrier services:</p>
                <div style={{ height: "30px" }} className="d-flex justify-content-center align-items-center w-100">
                    <div className="pricing-rates-card-info-line">
                        <div className="pricing-line-carton-img-container">
                            <img src={carton_img} className="pricing-line-carton-img" alt="" />
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column">
                    <div className="pricing-rates-carrier-services">
                        <h6 className="m-0">REGULAR</h6>
                        <h6 className="m-0">MEDIUM</h6>
                        <h6 className="m-0">EXPRESS</h6>
                        <RiShipFill className="pricing-rates-carrier-icon" size={25} fill="#A1A0A4" />
                    </div>
                    <div className="pricing-rates-carrier-services mt-3">
                        <h6 className="m-0">AIRFREIGHT</h6>
                        <FaPlane className="pricing-rates-carrier-icon" size={25} fill="#A1A0A4" />
                    </div>
                </div>
                <p className="pricing-rates-card-info-feature">Plus we offer both trucking & UPS/FedEx last mile options</p>
                <p className="pricing-rates-card-info-calculator">Check out our shipping estimate calculator below</p>
            </div>
            {!mobile && <img src={pricing_arrow} className="pricing-arrow-image" alt="" />}
        </div>
    );
};

export default PricingRatesCard;
