import React from "react";
import { useNavigate } from "react-router";

const BanerSection = () => {
    const navigate = useNavigate();
    return (
        <div className="pt-5 feature-bg d-flex align-items-center justify-content-center">
            <div className="pl-section w-100">
                <div className="baner-container">
                    <h1 className="baner-title">Save Money in Your Supply Chain Today.</h1>
                    <button onClick={() => navigate("/register")} className="lp_button_light my-5">
                        Get Started
                    </button>
                </div>
            </div>
        </div>
    );
};

export default BanerSection;
