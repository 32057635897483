import React, { useEffect, useState } from "react";
import { pricingSimpleInfo, pricingSpecialInfo } from "./common/pricingInfo";

import PricingSimpleCard from "./PricingSimpleCard";
import PricingSpecialCard from "./PricingSpecialCard";
import PricingRatesCard from "./PricingRatesCard";

import ContactSalesModal from "../common/ContactSalesModal/ContactSalesModal";

const PricingSection = () => {
    const [mobile, setMobile] = useState(false);
    const [showContactModal, setShowContactModal] = useState(false);

    const handleResize = () => {
        if (window.innerWidth < 1350) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    };

    // create an event listener
    useEffect(() => {
        window.addEventListener("resize", handleResize);
    });

    useEffect(() => {
        handleResize();
    }, []);
    return (
        <div id="fees" className="feature-bg d-flex flex-column align-items-center justify-content-center pt-5">
            <div className="pl-section w-100">
                <h1 className="mt-5 text-center save-money-title section-name">Simple pricing for everyone</h1>
                <div
                    className={
                        mobile
                            ? "d-flex flex-row justify-content-center align-items-start my-5 p-0 pricing-cards"
                            : "d-flex flex-row justify-content-center align-items-start mt-5 p-0 pricing-cards"
                    }
                >
                    {pricingSimpleInfo.map((info, idx) => (
                        <PricingSimpleCard cardInfo={info} key={idx} />
                    ))}
                    <PricingSpecialCard cardInfo={pricingSpecialInfo} openContactModal={() => setShowContactModal(true)} />
                    <PricingRatesCard mobile={mobile} />
                </div>
            </div>
            {showContactModal && <ContactSalesModal showModal={showContactModal} handleClose={() => setShowContactModal(false)} />}
        </div>
    );
};

export default PricingSection;
