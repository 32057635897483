import React from "react";
import Results from "../common/Results";

const CaseStudyResults1 = () => {
    return (
        <>
            <Results title="CASE STUDY #1 RESULTS" number1="$14,972" number2="31.9%" number3="26%" />
        </>
    );
};

export default CaseStudyResults1;
