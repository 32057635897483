import axios from "axios";
import Cookies from "universal-cookie";

import {
    SetAvailableTrackingTypesType,
    SetIsLoadingTrackingInfoType,
    SetIsLoadingType,
    SetLastTrackingFiltersType,
    SetTrackingInfoType,
    SetTrackingShipmentsType,
    TActiveTrackingFilter,
    TDeliveredToPortObj,
    TGetAvailableTrackingTypes,
    TLastTrackingFilters,
    TShipmentsTrackingInfo,
    TShipsGoExternalTrackingIds,
    TTrackingShipmentsResult,
    ThunkType,
    TrackingActionTypes,
} from "./types/TrackingTypes/tracking.types";
import { trackingApi } from "../api/trackingAPI";
import { displayError } from "../hooks/useErrorHandler";
import { getShipmentsTrackingInfo } from "../components/Dashboard/TrackShipments/common/trackingFunctions";

export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_TRACKING_SHIPMENTS = "SET_TRACKING_SHIPMENTS";
export const SET_TRACKING_INFO = "SET_TRACKING_INFO";
export const SET_AVAILABLE_TRACKING_TYPES = "SET_AVAILABLE_TRACKING_TYPES";
export const SET_IS_LOADING_TRACKING_INFO = "SET_IS_LOADING_TRACKING_INFO";
export const SET_LAST_TRACKING_FILTERS = "SET_LAST_TRACKING_FILTERS";

type InitialStateStatisticsType = {
    isLoading: boolean;
    isLoadingTrackingInfo: boolean;
    trackingShipments: TTrackingShipmentsResult[] | null;
    trackingInfo: TShipmentsTrackingInfo;
    availableTrackingTypes: TGetAvailableTrackingTypes | null;
    lastTrackingFilters: TLastTrackingFilters | null;
};

const initialState: InitialStateStatisticsType = {
    isLoading: false,
    isLoadingTrackingInfo: false,
    trackingShipments: null,
    trackingInfo: {},
    availableTrackingTypes: null,
    lastTrackingFilters: null,
};

const trackingReducer = (state = initialState, action: TrackingActionTypes): InitialStateStatisticsType => {
    switch (action.type) {
        case SET_IS_LOADING: {
            return {
                ...state,
                isLoading: action.data,
            };
        }
        case SET_IS_LOADING_TRACKING_INFO: {
            return {
                ...state,
                isLoadingTrackingInfo: action.data,
            };
        }
        case SET_TRACKING_SHIPMENTS: {
            return {
                ...state,
                trackingShipments: action.data,
            };
        }
        case SET_TRACKING_INFO: {
            return {
                ...state,
                trackingInfo: action.data,
            };
        }
        case SET_AVAILABLE_TRACKING_TYPES: {
            return {
                ...state,
                availableTrackingTypes: action.data,
            };
        }
        case SET_LAST_TRACKING_FILTERS: {
            return {
                ...state,
                lastTrackingFilters: action.data,
            };
        }
        default: {
            return state;
        }
    }
};

export const SetLoading = (loading: boolean): SetIsLoadingType => ({
    type: SET_IS_LOADING,
    data: loading,
});
export const SetTrackingInfoLoading = (loading: boolean): SetIsLoadingTrackingInfoType => ({
    type: SET_IS_LOADING_TRACKING_INFO,
    data: loading,
});
export const SetTrackingShipment = (data: TTrackingShipmentsResult[]): SetTrackingShipmentsType => ({
    type: SET_TRACKING_SHIPMENTS,
    data,
});
export const SetTrackingInfo = (data: TShipmentsTrackingInfo): SetTrackingInfoType => ({
    type: SET_TRACKING_INFO,
    data,
});
export const SetAvailableTrackingTypes = (data: TGetAvailableTrackingTypes): SetAvailableTrackingTypesType => ({
    type: SET_AVAILABLE_TRACKING_TYPES,
    data,
});
export const SetLastTrackingFilters = (data: TLastTrackingFilters): SetLastTrackingFiltersType => ({
    type: SET_LAST_TRACKING_FILTERS,
    data,
});

export const getContainersTracking = (
    cookies: Cookies,
    latestShipped: boolean,
    activeTrackingFilter: TActiveTrackingFilter,
    items: number,
    offset: number,
    setProductAllNext: (value: boolean) => void,
    searchValue: string
): ThunkType => {
    return async (dispatch, getState) => {
        const { trackingInfo } = getState().tracking;

        try {
            if (!offset) {
                dispatch(SetLoading(true));
            }
            dispatch(SetTrackingInfoLoading(true));

            const response = await trackingApi.getContainersTracking(cookies, latestShipped, activeTrackingFilter, items, offset, searchValue);
            setProductAllNext(response.data.next !== null ? true : false);

            const trackingDataResp = await getShipmentsTrackingInfo(cookies, response.data.results, trackingInfo);
            dispatch(SetTrackingInfo({ ...trackingInfo, ...trackingDataResp.shipmentData }));
            if (trackingDataResp.deliveredToPort.length > 0 || trackingDataResp.shipsGoTrackingIds.length > 0) {
                dispatch(setContainerTrackingProgram(cookies, trackingDataResp.shipsGoTrackingIds, trackingDataResp.deliveredToPort));
            }

            const { lastTrackingFilters } = getState().tracking;

            if (
                lastTrackingFilters?.activeDelivery === activeTrackingFilter.boat_calculation_type &&
                lastTrackingFilters?.activeAmazonStatus === activeTrackingFilter.amazon_status &&
                lastTrackingFilters?.latestShipped === latestShipped
            ) {
                dispatch(SetTrackingShipment(response.data.results));
            }

            dispatch(SetTrackingInfoLoading(false));
            if (!offset) {
                dispatch(SetLoading(false));
            }
        } catch (e) {
            console.log(e);
        }
    };
};

export const setContainerTrackingProgram = (cookies: Cookies, shipsGoTrackingIds: TShipsGoExternalTrackingIds[], orders_delivered_to_port: TDeliveredToPortObj[]): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetTrackingInfoLoading(true));
            await trackingApi.setContainerTrackingProgram(cookies, shipsGoTrackingIds, orders_delivered_to_port);
            dispatch(SetTrackingInfoLoading(false));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetTrackingInfoLoading(false));
                displayError(e.response.data, "Something went wrong while setting the tracking program.");
            }
        }
    };
};

export const getAvailableTrackingTypes = (cookies: Cookies, searchValue: string): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            const response = await trackingApi.getAvailableTrackingTypes(cookies, searchValue);
            dispatch(SetAvailableTrackingTypes(response.data));
            dispatch(SetLoading(false));
        } catch (e) {
            dispatch(SetLoading(false));
            console.log(e);
        }
    };
};

export default trackingReducer;
