export type TPricingSimpleInfo = {
    header: string;
    subHeader: string;
    priceDetails: TPriceDetails[];
};

type TPriceDetails = {
    name: string;
    price: string;
    useСurrency: boolean;
    comment: string | null;
};

export type TPricingSpecialInfo = {
    header: string;
    subHeader: string;
    description: string;
    features: string[];
};

export const pricingSimpleInfo: TPricingSimpleInfo[] = [
    {
        header: "0-70 CBM",
        subHeader: "in storage",
        priceDetails: [
            {
                name: "Software access",
                price: "$9.99",
                useСurrency: true,
                comment: null,
            },
            {
                name: "Receiving fees",
                price: "FREE",
                useСurrency: false,
                comment: "We don't charge anything to receive your cartons",
            },
            {
                name: "Storage fees",
                price: "$0.49",
                useСurrency: true,
                comment: "Per cubic meter each day",
            },
            {
                name: "Carton prep fees",
                price: "$2.75",
                useСurrency: true,
                comment: "Per carton for picking, prepping & container loading",
            },
        ],
    },
    {
        header: "71-140 CBM",
        subHeader: "in storage",
        priceDetails: [
            {
                name: "Software access",
                price: "$9.99",
                useСurrency: true,
                comment: null,
            },
            {
                name: "Receiving fees",
                price: "FREE",
                useСurrency: false,
                comment: "We don't charge anything to receive your cartons",
            },
            {
                name: "Storage fees",
                price: "$0.44",
                useСurrency: true,
                comment: "Per cubic meter each day",
            },
            {
                name: "Carton prep fees",
                price: "$2.20",
                useСurrency: true,
                comment: "Per carton for picking, prepping & container loading",
            },
        ],
    },
];

export const pricingSpecialInfo: TPricingSpecialInfo = {
    header: "ENTERPRISE",
    subHeader: "141+ CBM in storage",
    description: "For high volume sellers who require customized pricing tailored for their business",
    features: ["Custom pricing for carton prep", "Custom pricing for storage fees", "Discounted shipping rates", "FREE software subscription", "Dedicated account manager"],
};
