import { useEffect, useState } from "react";

import datapointDesktop from "../../../assets/img/LandingPage/PriceMethodGuarantee/datapoints_desktop.png";
import datapointMobile from "../../../assets/img/LandingPage/PriceMethodGuarantee/datapoints_mobile.png";

const PriceMethodGuarantee = () => {
    const [mobile, setMobile] = useState(false);

    const handleResize = () => {
        if (window.innerWidth < 990) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    };

    // create an event listener
    useEffect(() => {
        window.addEventListener("resize", handleResize);
    });

    useEffect(() => {
        handleResize();
    }, []);

    return (
        <div className="py-5 feature-bg d-flex align-items-center justify-content-center">
            <div className="basis-container">
                <div className="second-block-basis">
                    <img className="story-image" src={mobile ? datapointMobile : datapointDesktop} alt="" />
                </div>
                <div className="first-block-basis align-items-center">
                    <h3 className="story-title">Best price method guarantee</h3>
                    <p className="story-text story-padding">SKUDrop has built the world’s first shipping cost comparison algorithm for Amazon sellers.</p>
                    <p className="story-text story-padding">
                        All freight that’s shipped from the SKUDrop warehouse, when using our freight options, is put through our shipping cost algorithm. With over 1,168 data
                        points analyzed for each order, we ensure you receive the best possible price and combination for all your shipments.
                    </p>
                    <p className="story-text story-padding">
                        With the click of one button, you receive shipping quotes for your Amazon shipping plans comparing every combination, all under 3 seconds!
                    </p>
                    <p className="story-text-mobile-p w-8 ">We compare:</p>
                    <p className="story-text-mobile-p w-8">Cubic meter (cbm) vs Kilogram rates</p>
                    <p className="story-text-mobile-p w-8">Carrier speeds - Regular service, Matson CLX, and CLX+</p>
                    <p className="story-text-mobile-p w-8">UPS/FedEx vs Trucking last mile deliveries rates</p>
                    <p className="story-text story-padding">Combination deliveries vs truck or UPS/FedEx only</p>
                    <p className="story-text story-padding">
                        Our shipping algorithm handles the complexities of different order amounts, carton sizes, and weights, over 185 different FBA warehouse locations to
                        service, and the potential of split shipping plans with ease.
                    </p>
                    <p className="story-text story-padding">SKUDrop has the infrastructure to fulfill any combination you’re given, with the best possible price, guaranteed!</p>
                </div>
            </div>
        </div>
    );
};

export default PriceMethodGuarantee;
