type TRelationsObj = {
    [key: string]: string;
};

export const revenue_per_CBM = [0.01, 0.02, 0.03, 0.04, 0.05, 0.06];

export const free_subscription_period = [
    {
        title: "1 month",
        value: 1,
    },
    {
        title: "2 months",
        value: 2,
    },
    {
        title: "3 months",
        value: 3,
    },
    {
        title: "4 months",
        value: 4,
    },
    {
        title: "5 months",
        value: 5,
    },
    {
        title: "6 months",
        value: 6,
    },
    {
        title: "12 months",
        value: 12,
    },
    {
        title: "Free for life",
        value: "FREE_FOREVER",
    },
];

export const prep_fee_discount = [
    {
        title: "-",
        value: 0,
    },
    {
        title: "10%",
        value: 10,
    },
    {
        title: "20%",
        value: 20,
    },
    {
        title: "30%",
        value: 30,
    },
    {
        title: "40%",
        value: 40,
    },
    {
        title: "50%",
        value: 50,
    },
];

export const prep_fee_discount_period = [
    {
        title: "-",
        value: 0,
    },
    {
        title: "1 month",
        value: 1,
    },
    {
        title: "2 months",
        value: 2,
    },
    {
        title: "3 months",
        value: 3,
    },
    {
        title: "4 months",
        value: 4,
    },
    {
        title: "5 months",
        value: 5,
    },
    {
        title: "6 months",
        value: 6,
    },
    {
        title: "12 months",
        value: 12,
    },
];

export const prep_fee_discount_period_null = [
    {
        title: "-",
        value: 0,
    },
];

export const number_of_shipping_credits_usage = [
    {
        title: "10",
        value: 10,
    },
    {
        title: "20",
        value: 20,
    },
    {
        title: "30",
        value: 30,
    },
    {
        title: "40",
        value: 40,
    },
    {
        title: "50",
        value: 50,
    },
    {
        title: "100",
        value: 100,
    },
    {
        title: "200",
        value: 200,
    },
    {
        title: "300",
        value: 300,
    },
    {
        title: "500",
        value: 500,
    },
    {
        title: "Unlimited",
        value: 0,
    },
];

export const shipping_credits_amount = [
    {
        title: "$100",
        value: 100,
    },
    {
        title: "$200",
        value: 200,
    },
    {
        title: "$250",
        value: 250,
    },
    {
        title: "$300",
        value: 300,
    },
    {
        title: "$400",
        value: 400,
    },
    {
        title: "$500",
        value: 500,
    },
];

export const AFFILIATE_PERIODS_RELATION: TRelationsObj = {
    "0": "0 months",
    "1": "1 month",
    "2": "2 months",
    "3": "3 months",
    "4": "4 months",
    "5": "5 months",
    "6": "6 months",
    "12": "12 months",
    FREE_FOREVER: "Free for life",
};

export const AFFILIATE_EXPIRATIONS_OPTIONS = [
    {
        title: "1 day",
        value: 1,
    },
    {
        title: "2 days",
        value: 2,
    },
    {
        title: "3 days",
        value: 3,
    },
    {
        title: "7 days",
        value: 7,
    },
    {
        title: "14 days",
        value: 14,
    },
    {
        title: "1 month",
        value: 30,
    },
    {
        title: "No expiration",
        value: 0,
    },
];
