import { FC } from "react";

export type HowWorkCardPropsType = {
    image: string;
    small?: boolean;
    headline: string;
    title: string;
    description: string;
};

const HowWorkCard: FC<HowWorkCardPropsType> = ({ image, small, headline, title, description }) => {
    return (
        <div className={headline === "WE STORE" ? "how-card mobile-margin-top" : "how-card"}>
            <div className="how-card-image-container">
                <img src={image} className={small ? "how-card-image small-image" : "how-card-image"} alt="" />
            </div>
            <h2 className="how-card-title">{headline}</h2>
            <p className="text-center p-3 py-2 how-card-subtitle mb-0">{title}</p>
            <p className="text-center p-3 py-2 how-card-desc mb-0">{description}</p>
        </div>
    );
};

export default HowWorkCard;
