import Cookies from "universal-cookie";

import {
    StoredActionTypes,
    SetStoredProductAllType,
    SetStoredProductType,
    SetStoredTotalsType,
    StoredDict,
    StoredProductListItem,
    StoredThunkType,
    SetSuppliersType,
    TStoredProductsLoadingStates,
    TSetLoadingStates,
    TSupplierDetails,
} from "./types/StoredTypes/stored.types";
import { storedProduct } from "../api/storedProductApi";
import { TRequestLazySearchParams } from "./types/common/common.types";

export const SET_STORED_PRODUCT = "SET_STORED_PRODUCT";
export const SET_STORED_TOTAL = "SET_STORED_TOTAL";
export const SET_LOADING_STATES = "SET_LOADING_STATES";
export const SET_STORED_PRODUCT_ALL = "SET_STORED_PRODUCT_ALL";
export const SET_SUPPLIERS = "SET_SUPPLIERS";

type InitialStateType = {
    storedProduct: null | StoredProductListItem[];
    storedProductAll: null | StoredProductListItem[];
    storedTotals: null | StoredDict;
    storedProductsSuppliers: null | TSupplierDetails[];
    loadingStates: {
        isLoadingStoredProduct: boolean;
        isLoadingSuppliers: boolean;
    };
};

let initialState: InitialStateType = {
    storedProduct: null,
    storedProductAll: null,
    storedTotals: null,
    storedProductsSuppliers: null,
    loadingStates: {
        isLoadingStoredProduct: false,
        isLoadingSuppliers: false,
    },
};

const storedProductReducer = (state = initialState, action: StoredActionTypes): InitialStateType => {
    switch (action.type) {
        case SET_STORED_PRODUCT: {
            return {
                ...state,
                storedProduct: action.data,
            };
        }
        case SET_STORED_PRODUCT_ALL: {
            return {
                ...state,
                storedProductAll: action.data,
            };
        }
        case SET_STORED_TOTAL: {
            return {
                ...state,
                storedTotals: action.data,
            };
        }
        case SET_SUPPLIERS: {
            return {
                ...state,
                storedProductsSuppliers: action.data,
            };
        }
        case SET_LOADING_STATES: {
            return {
                ...state,
                loadingStates: { ...state.loadingStates, ...action.data },
            };
        }
        default:
            return state;
    }
};

export const SetStoredProduct = (data: Array<StoredProductListItem>): SetStoredProductType => ({
    type: SET_STORED_PRODUCT,
    data,
});
export const SetStoredProductAll = (data: Array<StoredProductListItem>): SetStoredProductAllType => ({
    type: SET_STORED_PRODUCT_ALL,
    data,
});
export const SetStoredTotals = (data: StoredDict): SetStoredTotalsType => ({
    type: SET_STORED_TOTAL,
    data,
});
export const SetSuppliers = (data: TSupplierDetails[]): SetSuppliersType => ({
    type: SET_SUPPLIERS,
    data,
});

export const SetLoadingStates = (data: { [key in keyof TStoredProductsLoadingStates]?: boolean }): TSetLoadingStates => ({
    type: SET_LOADING_STATES,
    data,
});

export const getStoredProduct = (cookies: Cookies, country_code?: string): StoredThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingStoredProduct: true }));
            const response = await storedProduct.getStoredProduct(cookies, country_code);
            dispatch(SetStoredProduct(response.data.results));
            dispatch(SetStoredTotals(response.data.stored_dict));
            dispatch(SetLoadingStates({ isLoadingStoredProduct: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getStoredProductAll = (queryParams: TRequestLazySearchParams & Partial<{ filter: string }>, setProductsAllNext: (value: boolean) => void): StoredThunkType => {
    return async (dispatch, getState) => {
        try {
            const { cookies } = getState().auth;
            const response = await storedProduct.getStoredProductAll(cookies, queryParams);
            setProductsAllNext(!!response.data.next);
            dispatch(SetStoredProductAll(response.data.results));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getSuppliers = (): StoredThunkType => {
    return async (dispatch, getState) => {
        try {
            dispatch(SetLoadingStates({ isLoadingSuppliers: true }));
            const { cookies } = getState().auth;
            const response = await storedProduct.getSuppliers(cookies);
            dispatch(SetSuppliers(response.data));
            dispatch(SetLoadingStates({ isLoadingSuppliers: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export default storedProductReducer;
