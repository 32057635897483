import React from "react";
import DataTable from "../common/DataTable";
import sku_logo from "../../../assets/img/skudrop-logo.png";
import boxes from "../../../assets/img/LandingPage/CaseStudy/boxes.png";

const CaseStudyTable2 = () => {
    return (
        <>
            <DataTable
                image_1={sku_logo}
                image_2={boxes}
                th_title1="stored in China & shipped by doing smaller regular direct shipments into Amazon over 3 months"
                th_title2="shipped to a US 3PL in a container then into Amazon"
                th_title_number1="80 cartons"
                th_title_number2="All 80 cartons"
                tr_1_3="LESS THAN CONTAINER LOAD"
                prepare_num_cartons="Prepping 80 cartons:"
                prepare_num_cartons_cost_1="$220"
                prepare_num_cartons_cost_2="$384"
                prepare_num_cartons_title_2="$3 + $1.80 label fee USD / CARTON"
                tariffs_price_month="$1,272"
                tariffs_price_week="$106 dollars per week"
                freight_cost_1="$1,185 USD"
                freight_cost_1_title="no more to pay Door to Door paid over a 3 month period"
                freight_cost_2="$3,115 USD"
                bonds_2="$190 USD"
                cartons_delivered_1="$100 USD"
                port_dwell_2="$40 USD"
                delivery_fees_2="$565 USD"
                pl_receiving_2="$240 USD"
                pallets_fees_2="$125 USD"
                amazon_spd="$12.20 USD / CARTON - $976 USD"
                total_1="$2,777"
                total_2="$5,251"
            />
        </>
    );
};

export default CaseStudyTable2;
