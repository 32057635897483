import React from "react";
import StudyHero from "../common/StudyHero";

const CaseStudyHero1 = () => {
    return (
        <>
            <StudyHero
                label="Case Study #1"
                title="Full Container Load (FCL)"
                description="This case study is based on an Amazon seller who shipped 671 cartons using the traditional supply chain sequence (sending a full container) when compared to shipping 671 cartons using the SKUdrop solution (smaller regular direct shipments into Amazon) "
                subTitle="Costs are based on a 40” HC container - 671 cartons (July 2022)"
            />
        </>
    );
};

export default CaseStudyHero1;
