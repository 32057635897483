import { Container } from "react-bootstrap";
import "../Auth.css";
import skudropLogo from "../../../assets/img/skudrop-logo.png";
import SignUpSecondStep from "./SignUpSecondStep";
import SignUpFirstStep from "./SignUpFirstStep";
import SignUpThirdStep from "./SignUpThirdStep";
import CheckEmail from "../common/CheckEmail";
import SignUpComplete from "./SignUpComplete";
import SignUpPortalComplete from "./SignUpPortalComplete";
import { useAppSelector } from "../../../redux/ReduxStore";

const SignUp = () => {
    const registerFirstStep = useAppSelector((state) => state.auth.registerFirstStep);
    const registerSecondStep = useAppSelector((state) => state.auth.registerSecondStep);
    const registerThirdStep = useAppSelector((state) => state.auth.registerThirdStep);
    const checkEmail = useAppSelector((state) => state.auth.checkEmail);
    const registerPortalComplete = useAppSelector((state) => state.auth.registerPortalComplete);

    return (
        <Container className="max-h-100vh info-block container-bg m-0 mw-100 p-0 overflow-auto">
            {registerFirstStep && <SignUpFirstStep logo={skudropLogo} />}
            {registerSecondStep && <SignUpSecondStep logo={skudropLogo} />}
            {registerThirdStep && <SignUpThirdStep logo={skudropLogo} />}
            {registerPortalComplete && <SignUpPortalComplete />}
            {checkEmail && <CheckEmail />}
        </Container>
    );
};

export default SignUp;
