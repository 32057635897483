import { FC } from "react";
import { Container } from "react-bootstrap";

export type FeaturePropsType = {
    img: string;
    headline: string;
    text: string;
};

const Feature: FC<FeaturePropsType> = ({ img, headline, text }) => {
    return (
        <Container className="d-flex flex-column align-items-start section-width feature-width my-3">
            <div className="feature-image-container">
                <img src={img} width="50" alt="Feature" />
            </div>
            {headline === "Stay cash liquid" ? (
                <h5 className="my-3 feature-headline">
                    Stay cash <br /> liquid
                </h5>
            ) : headline === "Dirt cheap storage" ? (
                <h5 className="my-3 feature-headline">
                    Dirt cheap <br /> storage
                </h5>
            ) : (
                <h5 className="my-3 feature-headline">{headline}</h5>
            )}
            <p className="feature-text">{text}</p>
        </Container>
    );
};

export default Feature;
