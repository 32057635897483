import { FC } from "react";

export type DataTablePropsType = {
    image_1: string;
    image_2: string;
    th_title1: string;
    th_title2: string;
    th_title_number1: string;
    th_title_number2: string;
    tr_1_3: string;
    prepare_num_cartons: string;
    prepare_num_cartons_cost_1: string;
    prepare_num_cartons_cost_2: string;
    prepare_num_cartons_title_2: string;
    tariffs_price_month: string;
    tariffs_price_week: string;
    freight_cost_1: string;
    freight_cost_1_title: string;
    freight_cost_2: string;
    bonds_2: string;
    cartons_delivered_1: string;
    port_dwell_2: string;
    delivery_fees_2: string;
    pl_receiving_2: string;
    pallets_fees_2: string;
    amazon_spd: string;
    total_1: string;
    total_2: string;
};

const DataTable: FC<DataTablePropsType> = ({
    image_1,
    image_2,
    th_title1,
    th_title2,
    th_title_number1,
    th_title_number2,
    tr_1_3,
    prepare_num_cartons,
    prepare_num_cartons_cost_1,
    prepare_num_cartons_cost_2,
    prepare_num_cartons_title_2,
    tariffs_price_month,
    tariffs_price_week,
    freight_cost_1,
    freight_cost_1_title,
    freight_cost_2,
    bonds_2,
    cartons_delivered_1,
    port_dwell_2,
    delivery_fees_2,
    pl_receiving_2,
    pallets_fees_2,
    amazon_spd,
    total_1,
    total_2,
}) => {
    return (
        <div className="data-table">
            <div className="data-table-block">
                <table width="100%">
                    <thead>
                        <tr>
                            <th className="w-33 first-column">
                                <div className="w-100"></div>
                            </th>
                            <th className="w-33" align="center">
                                <div className="w-100 d-flex justify-content-center">
                                    <img className="th-image" src={image_1} alt="" />
                                </div>
                            </th>
                            <th className="w-33" align="center">
                                <div className="w-100 d-flex justify-content-center">
                                    <img className="th-image w-50" src={image_2} alt="" />
                                </div>
                            </th>
                        </tr>
                        <tr>
                            <th className="w-33 first-column">
                                <h3 className="w-100 th-title">SUPPLY CHAIN FEES</h3>
                            </th>
                            <th className="w-33" align="center">
                                <h3 className="w-100 text-center th-title">USING SKUdrop</h3>
                            </th>
                            <th className="w-33" align="center">
                                <h3 className="w-100 text-center th-title">{tr_1_3}</h3>
                            </th>
                        </tr>
                        <tr>
                            <th className="w-33 first-column">
                                <p className="w-100 th-text">All fees payable to manage the flow of cartons to Amazon FBA</p>
                            </th>
                            <th className="w-33">
                                <div className="w-100 d-flex justify-content-center">
                                    <p className="w-100 text-center th-text max-w-300 ">
                                        <b>{th_title_number1}</b> {th_title1}
                                    </p>
                                </div>
                            </th>
                            <th className="w-33">
                                <div className="w-100 d-flex justify-content-center">
                                    <p className="w-100 text-center th-text max-w-300">
                                        <b>{th_title_number2}</b> {th_title2}{" "}
                                    </p>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td valign="top" className="pt-65 first-column">
                                <h3 className="th-title-sm first-column-color">Storage fees </h3>
                            </td>
                            <td className="td-border">
                                <div className="td-background">
                                    <h3 className="w-100 text-center th-title">STORAGE & PREP FEES</h3>
                                    <h3 className="w-100 text-center th-title-sm">
                                        <b className="green">$0.49</b> USD / cubic meter / day{" "}
                                    </h3>
                                    <p className="w-100 text-center th-text mb-0">Storing 20 cbm in 1</p>
                                    <p className="w-100 text-center th-text">
                                        month would cost: <b className="green">$294</b>
                                    </p>
                                </div>
                            </td>
                            <td className="td-border">
                                <div className="td-background">
                                    <h3 className="w-100 text-center th-title">STORAGE & PREP FEES</h3>
                                    <h3 className="w-100 text-center th-title-sm">
                                        <b className="red">$0.72</b> USD / cubic meter / day{" "}
                                    </h3>
                                    <p className="w-100 text-center th-text mb-0">Storing 20 cbm in 1</p>
                                    <p className="w-100 text-center th-text">
                                        month would cost: <b className="red">$432</b>
                                    </p>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td valign="top" className="pt-25 first-column">
                                <h3 className="th-title-sm first-column-color">Outgoing prep fees</h3>
                            </td>
                            <td className="td-border">
                                <div className="td-background" style={{ height: 112 }}>
                                    <h3 className="w-100 text-center th-title-sm">$2.75 USD/ CARTON</h3>
                                    <p className="w-100 text-center th-text">
                                        {prepare_num_cartons} <b className="green">{prepare_num_cartons_cost_1}</b>
                                    </p>
                                </div>
                            </td>
                            <td className="td-border">
                                <div className="td-background" style={{ height: 112 }}>
                                    <h3 className="w-100 text-center th-title-sm">{prepare_num_cartons_title_2}</h3>
                                    <p className="w-100 text-center th-text">
                                        {prepare_num_cartons} <b className="red">{prepare_num_cartons_cost_2}</b>
                                    </p>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="first-column">
                                <h3 className="th-title-sm first-column-color">Tariffs</h3>
                            </td>
                            <td className="td-border">
                                <div className="td-background tariffs-position">
                                    <h3 className="w-100 text-center th-title">TARIFFS</h3>
                                    <p className="w-100 text-center th-text">
                                        <b className="bold">{tariffs_price_month}</b> <b className="green-small">paid over 3 months</b>
                                    </p>
                                    <p className="w-100 text-center th-text">{tariffs_price_week}</p>
                                </div>
                            </td>
                            <td className="td-border" valign="top">
                                <div className="td-background tariffs-position">
                                    <h3 className="w-100 text-center th-title">TARIFFS</h3>
                                    <p className="w-100 text-center th-text">
                                        <b className="bold">{tariffs_price_month}</b> <b className="red-small">paid upfront</b>
                                    </p>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td valign="bottom" className="pt-25 first-column-color first-column">
                                <h3 className="th-title-sm first-column-color mb-2">Freight charges</h3>
                                <p className="w-100 text-left secondary-text p-0 mb-2">{th_title_number1}</p>
                            </td>
                            <td valign="top" className="td-border">
                                <div className="td-background" style={{ height: 158 }}>
                                    <h3 className="w-100 text-center th-title">FREIGHT COSTS INTO AMAZON</h3>
                                    <p className="w-100 text-center th-text px-4">
                                        <b className="bold">{freight_cost_1}</b> {freight_cost_1_title}
                                    </p>
                                </div>
                            </td>
                            <td valign="top" className="td-border">
                                <div className="td-background" style={{ height: 158 }}>
                                    <h3 className="w-100 text-center th-title">FREIGHT COSTS INTO AMAZON</h3>
                                    <p className="w-100 text-center th-text">
                                        <b className="bold">{freight_cost_2} </b>paid upfront
                                    </p>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="first-column">
                                <h3 className="th-title-sm first-column-color">Bonds</h3>
                            </td>
                            <td valign="top" className="td-border">
                                <div className="td-background">
                                    <p className="w-100 text-center th-text">NO FEE PAYABLE </p>
                                </div>
                            </td>
                            <td valign="top" className="td-border">
                                <div className="td-background">
                                    <p className="w-100 text-center th-text">{bonds_2}</p>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="first-column">
                                <h3 className="th-title-sm first-column-color">Cartons delivered to SKUdrop</h3>
                            </td>
                            <td valign="top" className="td-border">
                                <div className="td-background">
                                    <p className="w-100 text-center th-text">{cartons_delivered_1}</p>
                                </div>
                            </td>
                            <td valign="top" className="td-border">
                                <div className="td-background">
                                    <p className="w-100 text-center th-text">NO FEE PAYABLE</p>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="first-column">
                                <h3 className="th-title-sm first-column-color">Port dwell & demurrage fees</h3>
                            </td>
                            <td valign="top" className="td-border">
                                <div className="td-background">
                                    <p className="w-100 text-center th-text">NO FEE PAYABLE </p>
                                </div>
                            </td>
                            <td valign="top" className="td-border">
                                <div className="td-background">
                                    <p className="w-100 text-center th-text">{port_dwell_2}</p>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="first-column">
                                <h3 className="th-title-sm first-column-color mt-1 mb-0">Container delivery fees </h3>
                                <p className="w-100 text-left secondary-text mb-0 p-0">From Port to 3PL</p>
                            </td>
                            <td valign="top" className="td-border">
                                <div className="td-background">
                                    <p className="w-100 text-center th-text">NO FEE PAYABLE </p>
                                </div>
                            </td>
                            <td valign="top" className="td-border">
                                <div className="td-background">
                                    <p className="w-100 text-center th-text">{delivery_fees_2}</p>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="first-column">
                                <h3 className="th-title-sm first-column-color">3PL receiving fees </h3>
                            </td>
                            <td valign="top" className="td-border">
                                <div className="td-background">
                                    <p className="w-100 text-center th-text">NO FEE PAYABLE </p>
                                </div>
                            </td>
                            <td valign="top" className="td-border">
                                <div className="td-background">
                                    <p className="w-100 text-center th-text">{pl_receiving_2}</p>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="first-column">
                                <h3 className="th-title-sm first-column-color">Pallet fees</h3>
                            </td>
                            <td valign="top" className="td-border">
                                <div className="td-background">
                                    <p className="w-100 text-center th-text">NO FEE PAYABLE </p>
                                </div>
                            </td>
                            <td valign="top" className="td-border">
                                <div className="td-background">
                                    <p className="w-100 text-center th-text">{pallets_fees_2}</p>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="first-column">
                                <h3 className="th-title-sm first-column-color">Amazon SPD/LTL shipping fees</h3>
                            </td>
                            <td valign="top" className="td-border">
                                <div className="td-background">
                                    <p className="w-100 text-center th-text">NO FEE PAYABLE </p>
                                </div>
                            </td>
                            <td valign="top" className="td-border">
                                <div className="td-background">
                                    <p className="w-100 text-center th-text">{amazon_spd}</p>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="first-column">
                                <div className="w-100"></div>
                            </td>
                            <td valign="top">
                                <div className="totals">
                                    <h3 className="total-title mb-4">TOTAL HARD SUPPLY CHAIN COSTS:</h3>
                                    <h3 className="total-title green my-2">
                                        <b className="xl-numbers">{total_1}</b> USD
                                    </h3>
                                    <h3 className="total-title gray">PAID OVER 3 MONTHS</h3>
                                </div>
                            </td>
                            <td valign="top">
                                <div className="totals">
                                    <h3 className="total-title mb-4">TOTAL HARD SUPPLY CHAIN COSTS:</h3>
                                    <h3 className="total-title red my-2">
                                        <b className="xl-numbers">{total_2} </b> USD
                                    </h3>
                                    <h3 className="total-title gray">PAID UPFRONT</h3>
                                    <p>excluding SPD/LTL fees as that is paid over time</p>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default DataTable;
