import ReactGA from "react-ga";
import { Container } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router";

import { onPaymentConfirm } from "../../../../redux/settingsReducer";
import { useAppDispatch, useAppSelector } from "../../../../redux/ReduxStore";

import preloader from "../../../../assets/video/preloader.webp";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const PaymentConfirm = () => {
    let query = useQuery();

    const dispatch = useAppDispatch();

    const payment_intent = query.get("payment_intent");
    const payment_intent_client_secret = query.get("payment_intent_client_secret");
    const redirect_status = query.get("redirect_status");

    const cookies = useAppSelector((state) => state.auth.cookies);

    const [paymentConfirm, setPaymentConfirm] = useState(false);

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    useEffect(() => {
        if (payment_intent && payment_intent_client_secret && redirect_status) {
            dispatch(onPaymentConfirm(payment_intent, payment_intent_client_secret, redirect_status, setPaymentConfirm, cookies));
        }
    }, []);

    if (paymentConfirm) {
        return <Navigate to="/dashboard/settings/billing" />;
    }

    return (
        <Container className="spiner-container container-bg">
            <img src={preloader} style={{ marginLeft: 40 }} width="150" height="150" alt="Loading..."></img>
        </Container>
    );
};

export default PaymentConfirm;
