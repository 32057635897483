import { FC } from "react";

export type ResultsPropsTypes = {
    title: string;
    number1: string;
    number2: string;
    number3: string;
};

const Results: FC<ResultsPropsTypes> = ({ title, number1, number2, number3 }) => {
    return (
        <div className="data-table py-5">
            <div className="results-block">
                <h1 className="results-title">{title}</h1>
                <div className="numbers-container">
                    <div>
                        <h1 className="results-number">{number1}</h1>
                        <p className="results-number-description">in supply chain savings </p>
                    </div>
                    <div>
                        <h1 className="results-number">{number2}</h1>
                        <p className="results-number-description">reduction in storage fees</p>
                    </div>
                    <div>
                        <h1 className="results-number">{number3}</h1>
                        <p className="results-number-description">reduction in prep fees</p>
                    </div>
                </div>
                <h1 className="results-title fz-45">Conclusion</h1>
                <p className="conclusion-text">
                    When comparing supply chain models, the SKUdrop supply chain solution resulted in significant cost saving. Not only was money saved on storage, prep and freight
                    charges, <b>SKUdrop allowed the seller to keep more money in their pocket by paying substantially less costs upfront.</b>
                </p>
                <p className="conclusion-text">
                    Another advantage of the SKUdrop model was a reduction in risk through less carton handling (removal of 3PL’s) and not having the <b>entire order</b> being
                    shipped and at risk during shipping.
                </p>
            </div>
        </div>
    );
};

export default Results;
