import ReactGA from "react-ga";
import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import InfoBlock from "../InfoBlock/InfoBlock";
import SignIn from "./SignIn";

const SignInContainer = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <Container style={{ margin: 0, maxWidth: "100%", padding: 0 }}>
            <Row>
                <Col xl={6} className="info-image-container" style={{ padding: 0 }}>
                    <InfoBlock />
                </Col>
                <Col xl={6} style={{ padding: 0 }}>
                    <div className="max-h-100vh info-block container-bg m-0 mw-100 p-0 overflow-auto container">
                        <SignIn />
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default SignInContainer;
