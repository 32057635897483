import ReactGA from "react-ga";
import { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

import { useAppDispatch } from "../../../redux/ReduxStore";
import { ConfirmAuthorizedUserToTenant } from "../../../redux/warehouseReducer";

import preloader from "../../../assets/video/preloader.webp";

export type AddAuthorizedUserUrlParamsType = {
    id: string;
    token: string;
};

const AddAuthorizedUserToTenant = () => {
    const { id, token } = useParams<AddAuthorizedUserUrlParamsType>();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    useEffect(() => {
        if (id && token) {
            dispatch(ConfirmAuthorizedUserToTenant(id, token));
            navigate("/login");
        }
    }, []);

    return (
        <Container className="spiner-container container-bg">
            <img src={preloader} style={{ marginLeft: 40 }} width="150" height="150" alt="Loading..."></img>
        </Container>
    );
};

export default AddAuthorizedUserToTenant;
