import React, { useState } from "react";
import { Container } from "react-bootstrap";
import Faqs from "./Faqs";

const FaqsSection = () => {
    const [showAll, setShowAll] = useState(false);
    return (
        <div id="faq" className="py-5 feature-bg d-flex align-items-center justify-content-center">
            <div className="pl-section w-100">
                <h1 className="mb-5 story-title text-center">Frequently asked questions</h1>
                <Container>
                    <Faqs
                        text="What is SKUdrop?"
                        description="SKUdrop is a total supply chain solution where we manage the storage of your goods in our Chinese warehouses, we prep your cartons, and we ship your goods directly into Amazon’s fulfilment centres."
                        description2="On top of this, you get access to our user-friendly software management platform to help you manage and oversee your entire E-comm supply chain with the click of a button. "
                    />
                    <Faqs
                        text="What sort of Amazon sellers can use SKUdrop?"
                        description="All sellers can use SKUdrop. Whether you’re shipping full containers (FCL) or just doing less than container loads (LCL), our solution is perfect for any size seller. What’s important for us is that SKUdrop helps Amazon sellers of all sizes implement efficiencies into their supply chain processes to ensure they maximise profits and save money."
                    />
                    <Faqs
                        text="What are SKUdrop’s fees?"
                        description="Receiving your goods is 100% free, yes, you read that correctly – FREE! Picking, labeling, and loading fees, and storage fees vary depending on the amount of cubic meters (CBM) you have stored in our warehouse. Check out our pricing section to find out more about our tiered pricing. Enterprise options are also available for larger sellers."
                    />
                </Container>
                {showAll && (
                    <Container>
                        <Faqs
                            text="So SKUdrop takes care of my storage and shipping into Amazon, is that correct?"
                            description="Yes. That’s correct. We store your goods in our secure warehouses in China and our product dashboard allows you to track how many units and cartons you have available to send directly into Amazon. "
                            description2="When you’re ready to send cartons into Amazon, simply pick your SKU’s and carton quantities, create your Amazon shipping plans with the click of a button, and once you’re happy with everything, we then generate an instant shipping quote for you. "
                            description3="We take care of the rest as we pick, prep, and ship your cartons to Amazon. "
                            description4="All this is done from within our system. There’s no need to go to Seller Central to create your shipping plans, no need to attach and send us shipping labels, this is all automated by our supply chain system."
                        />
                        <Faqs
                            text="How long does it take to ship goods directly into Amazon’s fulfilment centres from your warehouse?"
                            description="We have multiple shipping solutions for various Amazon fulfilment centers for both the US and UK markets. Shipping can take between 10-50 days to arrive depending on the shipping method selected and port congestion."
                        />
                        <Faqs
                            text="Does SKUdrop do DDP?"
                            description="No. SKUDrop does not ship any goods via DDP incoterms. You have 100% ownership of your goods when they arrive at the SKUDrop warehouse for shipping and storage. When using SKUdrop, we only ship legally declaring the full value of your goods, as supplied to us via your commercial invoice and HTS codes. 7501 US Customs Entry Summary extracts are issued for your records after clearance. We then charge your credit card for any duties payable and notify you via email confirmation when this process is complete."
                        />
                        <Faqs
                            text="How do I pay SKUdrop for my shipments and their services?"
                            description="SKUdrop charges you via the credit card you have assigned in your SKUdrop account. All shipments, duties and warehouse storage fees charged are processed automatically. SKUdrop is all about creating efficiencies for our customers. Automation allows you to save time and say goodbye to time wasting emails forever. SKUdrop notifies you for every charge and creates receipts for every transaction in your account billing section for your business records."
                        />
                        <Faqs
                            text="When do I pay for my duties and tariffs?"
                            description="SKUdrop manages all duties and tariffs on your behalf prior to your goods entering the US or UK. Your credit card on file is used to make payment which is based on your goods commercial value and HTS codes provided to us."
                            description2="When charging your duties and tariffs, a payment receipt and the relevant importing documents are emailed to you. They are also available in your account billing section."
                        />
                        <Faqs
                            text="Does SKUdrop pick up my goods from my manufacturer's factory for me?"
                            description="No. It is your responsibility to get your goods to the SKUdrop warehouse. Depending on arrangements with your supplier and their location, having FOB terms could include a delivery to SKUdrop. If not, internal deliveries are inexpensive in China and organising this with your manufacturer should be very straightforward. If you or your supplier has any issues finding internal logistics companies to help deliver your goods to SKUdrop, please reach out to support. Our China staff can recommend a delivery service to your manufacturer."
                        />
                        <Faqs
                            text="Where is the SKUdrop warehouse located in China and how will I know where to deliver my goods?"
                            description="The SKUdrop warehouse is in Yiwu, China. Centrally located on the east coast and very accessible to the Ningbo and Shanghai shipping ports."
                            description2="Once you create an account in SKUdrop, you will be able to send your products to our warehouse. When you do this, you will receive an email which includes the delivery address in English and Chinese, as well as further instructions to help organise your inbound SKUdrop delivery."
                        />
                        <Faqs
                            text="Do I need to put any labels on my cartons before sending them to SKUdrop?"
                            description="Yes! SKUdrop has an internal receiving and delivery system which requires you to attach a small label prior to delivering your cartons into SKUdrop. This label is emailed to you with every inbound SKUdrop order you create. Please ensure your manufacturer attaches our SKUdrop labels on every carton prior to shipping to SKUdrop."
                        />
                        <Faqs
                            text="Do I need to palletise my cartons before sending them into SKUdrop?"
                            description="No. Our warehouse staff will receive your goods loose loaded or palletised at our delivery docks. All goods will then be re-palletised on our scannable pallets. Once this is done, we scan your cartons into our internal warehouse inventory management system, before being put away for storage."
                        />
                        <Faqs
                            text="Can I ship my goods from SKUdrop to any other Amazon marketplace?"
                            description="Yes, at this point in time, SKUdrop can ship your goods to both the US and UK markets. We are looking to add more markets in the future."
                        />
                        <Faqs text="Does SKUdrop have air freight options?" description="Yes. We now have airfreight options to the US market." />
                    </Container>
                )}
                <button className="lp_button xxlarge my-5" onClick={() => setShowAll(!showAll)}>
                    {showAll ? "Show Less" : "View All"}
                </button>
            </div>
        </div>
    );
};

export default FaqsSection;
