import { useState, useEffect, FC } from "react";
import white_box from "../../../assets/img/white_box.png";
import { VscDebugRestart } from "react-icons/vsc";

export type OversizeIssuePropsType = {
    onResetCalculator: () => void;
};

const OversizeIssue: FC<OversizeIssuePropsType> = ({ onResetCalculator }) => {
    const [mobile, setMobile] = useState(false);

    const handleResize = () => {
        if (window.innerWidth < 990) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    };

    // create an event listener
    useEffect(() => {
        window.addEventListener("resize", handleResize);
    });

    useEffect(() => {
        handleResize();
    }, []);
    return (
        <div className="d-flex flex-column align-items-center justify-content-center w-100">
            {mobile ? (
                <div className="d-flex flex-column align-items-center justify-content-center w-100">
                    <div className="d-flex flex-column align-items-center justify-content-center">
                        <h3 className="text-center story-title w-100 px-3">Test Drive Our Rates... </h3>
                        <p className="p-landing-page px-3 text-center">Remember, the SKUdrop model works best with regular weekly shipments</p>
                    </div>
                    <div className="d-flex flex-column align-items-center justify-content-center py-5 px-3 border min-h-350 position-relative w-9 bg-white">
                        <div className="position-absolute reset-calculator" onClick={() => onResetCalculator()}>
                            <VscDebugRestart style={{ width: 25, height: 25, color: "#1B2B4A" }} title="Reset calculator" />
                        </div>
                        <img src={white_box} className="w-120px" alt="" />
                        <h5 className="text-center mt-3">OVERSIZE CARTON ISSUE DETECTED.</h5>
                        <p className="text-center mt-2 ">
                            We would like to investigate this further to help save you money.
                            <br />
                            Please contact the SKUdrop support team for further assistance.
                        </p>
                    </div>
                </div>
            ) : (
                <div className="d-flex flex-column align-items-center justify-content-center w-100 pt-4">
                    <div className="d-flex flex-column align-items-center justify-content-center pb-4 mt-4 px-3 border calculator-wrapper min-h-350 position-relative bg-white">
                        <div className="d-flex flex-column align-items-center justify-content-center pt-5 pb-3">
                            <h1 className="text-center story-title w-100 px-3">Test Drive Our Rates... </h1>
                            <p className="p-landing-page px-3 text-center">Remember, the SKUdrop model works best with regular weekly shipments</p>
                        </div>
                        <div aria-label="reset-calculator" className="position-absolute reset-calculator" onClick={() => onResetCalculator()}>
                            <VscDebugRestart style={{ width: 25, height: 25, color: "#1B2B4A" }} title="Reset calculator" />
                        </div>
                        <img src={white_box} className="w-120px" alt="" />
                        <h3 className="text-center mt-3">OVERSIZE CARTON ISSUE DETECTED.</h3>
                        <p className="text-center mt-2 ">
                            We would like to investigate this further to help save you money.
                            <br />
                            Please contact the SKUdrop support team for further assistance.
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default OversizeIssue;
