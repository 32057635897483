import ReactGA from "react-ga";
import { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useAppSelector } from "../../redux/ReduxStore";
import InfoBlock from "./InfoBlock/InfoBlock";
import SignUpComplete from "./SignUp/SignUpComplete";
import SignUpContainer from "./SignUp/SignUpContainer";

const Auth = () => {
    const registerComplete = useAppSelector((state) => state.auth.registerComplete);

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <Container className="container-bg" style={{ margin: 0, maxWidth: "100%", padding: 0 }}>
            {registerComplete ? (
                <Row>
                    <SignUpComplete />
                </Row>
            ) : (
                <Row>
                    <Col xl={6} className="info-image-container" style={{ padding: 0 }}>
                        <InfoBlock />
                    </Col>
                    <Col xl={6} style={{ padding: 0 }}>
                        <SignUpContainer />
                    </Col>
                </Row>
            )}
        </Container>
    );
};

export default Auth;
