import * as yup from "yup";

export const schemaFirstStep = yup.object({
    firstName: yup
        .string()
        .required("First Name required")
        .max(30,"First Name to long"),
    lastName: yup
        .string()
        .required("Last name required")
        .max(30, "Last Name to long"),
    email: yup
        .string()
        .email("Email address is invalid")
        .required("Email required"),
}).required();

export const schemaSecondStep = yup.object({
    password: yup
        .string()
        .min(8, "Password must be at least 8 characters")
        .required("Password required"),
    confirmPassword: yup
        .string()
        .oneOf([yup.ref("password"), null], "Passwords do not match"),
}).required();

export const schemaThirdStep = yup.object({
    companyName: yup
        .string()
        .required("Company Name required")
        .max(30, "Company Name to long"),
    name: yup
        .string()
        .required("Name required")
        .max(30, "Name to long"),
    subdomain: yup
        .string()
        .required("Sub domain required")
        .max(20, "Sub domain to long"),
    adress: yup
        .string()
        .required("Adress required")
        .max(64, "First Name to long"),
    postalCode: yup
        .string()
        .required("Postal code required")
        .max(15, "First Name to long"),
    city: yup
        .string()
        .required("City required"),
    state: yup
        .string()
        .required("State required"),
    country: yup
        .string()
        .required("Country required"),
    code: yup
        .string(),
    phone: yup
        .string(),

}).required();

export const schemaThirdStepPortal = yup.object({
    companyName: yup
        .string()
        .required("Company Name required")
        .max(30, "Company Name to long"),
    name: yup
        .string()
        .required("Name required")
        .max(30, "Name to long"),
    subdomain: yup
        .string()
        .required("Sub domain required")
        .max(20, "Sub domain to long"),
    adress: yup
        .string()
        .required("Adress required")
        .max(64, "First Name to long"),
    postalCode: yup
        .string()
        .required("Postal code required")
        .max(15, "First Name to long"),
    city: yup
        .string()
        .required("City required"),
    state: yup
        .string()
        .required("State required"),
    country: yup
        .string()
        .required("Country required"),
}).required();

export const schemaLastStep = yup.object({
    sellerId: yup
        .string()
        .required("Seller ID required"),
    marketplaceId: yup
        .string()
        .required("Marketplace ID required"),
    authToken: yup
        .string()
        .required("MWS Auth Token required"),
}).required();

export const login = yup.object({
    email: yup
        .string()
        .email("Email address is invalid")
        .required("Email required"),
    password: yup
        .string()
        .required("Password required"),
}).required();

export const SendEmailToResetValidator = yup.object({
    email: yup
        .string()
        .email("Email address is invalid")
        .required("Email required"),
}).required()
