import React, { useState, useEffect } from "react";
import PL from "../../../assets/img/LandingPage/3PLSection/3PLs.png";
import PL_mobile from "../../../assets/img/LandingPage/3PLSection/Mobile_3PL.png";

const PLSection = () => {
    const [mobile, setMobile] = useState(false);

    const handleResize = () => {
        if (window.innerWidth < 990) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    };

    // create an event listener
    useEffect(() => {
        window.addEventListener("resize", handleResize);
    });

    useEffect(() => {
        handleResize();
    }, []);

    return (
        <div className="py-5 pl-bg d-flex align-items-center justify-content-center">
            <div className="pl-section">
                <h1 className="story-title text-center">Eliminate 3PL’s</h1>
                <p className="story-text text-center w-60 mt-3 mb-0">
                    SKUdrop eliminates the need for 3PL’s in the US as we ship your goods directly into Amazon.
                    <br />
                    On top of this, you can say goodbye to the 3PL receiving fees you get charged, the SPD shipping charges from Amazon, the expensive storage fees each month and
                    the high prep fees to prep your cartons.
                </p>
                <img className="w-80 mt-2" src={mobile ? PL_mobile : PL} alt="" />
            </div>
        </div>
    );
};

export default PLSection;
