import React from "react";

export const handleWheel = (e: React.WheelEvent<HTMLInputElement>) => {
    (e.target as HTMLInputElement).blur();
};

export const calculateVisibility = (top: number, bottom: number, windowTop: number, windowBottom: number) => {
    const windowHeight = windowBottom - windowTop;

    if (top > windowTop && top < windowBottom && bottom > windowTop && bottom < windowBottom) {
        return 100;
    }

    if (top > windowTop && top < windowBottom) {
        return 100 + ((windowTop - top) / windowHeight) * 100;
    }

    if (bottom > windowTop && bottom < windowBottom) {
        return 100 + ((bottom - windowBottom) / windowHeight) * 100;
    }

    return 0;
};
