import axios from "axios";
import Cookies from "universal-cookie";

import { NavigateFunction } from "react-router";

import {
    ActionAddProductType,
    AddProductFromAmazonCategoriesType,
    AddProductFromAmazonRequest,
    AddProductThunkType,
    EditProductDataType,
    HSInfoStateType,
    IntegrationsListType,
    ProductActionResult,
    ProductType,
    SetAmazonProductLoadingType,
    SetBandsProductType,
    SetBrandsType,
    SetFilesUploadingType,
    SetHSInfoType,
    SetIntegrationListType,
    SetIsLoadingSkusFileType,
    SetLoadingHSInfoType,
    SetLoadingIntegrationsType,
    SetProductActionAllCountType,
    SetProductActionCountType,
    SetProductAllType,
    SetProductsFromAmazonType,
    SetProductType,
    SetSkusFileUploadResponseType,
    SetTaskStatusType,
    TActiveMeasurements,
    TAddProductLoadingStates,
    TProductsFromAmazonState,
    TSetActiveMeasurements,
    TSetLoadingAddProductStates,
    UploadFileOfSkusResponse,
} from "./types/AddProductTypes/AddProduct.types";
import { dashboardAPI } from "../api/addProductAPI";
import { SetLoadingType } from "./types/AuthTypes/auth.types";
import { displayError, displaySuccess } from "../hooks/useErrorHandler";
import { BrandProductType, TStateBrandProducts } from "../components/Dashboard/types/products.types";

export const SET_PRODUCT = "SET_PRODUCT";
export const SET_PRODUCT_ALL = "SET_PRODUCT_ALL";
export const SET_PRODUCT_ACTION = "SET_PRODUCT_ACTION";
export const SET_PRODUCT_ACTION_ALL = "SET_PRODUCT_ACTION_ALL";
export const SET_BRAND_PRODUCT = "SET_BRAND_PRODUCT";
export const SET_BRANDS = "SET_BRANDS";
export const SET_LOADING = "SET_LOADING";
export const SET_PRODUCTS_FROM_AMAZON = "SET_PRODUCTS_FROM_AMAZON";
export const SET_PRODUCTS_FROM_AMAZON_ALL = "SET_PRODUCTS_FROM_AMAZON_ALL";
export const SET_AMAZON_PRODUCT_LOADING = "SET_AMAZON_PRODUCT_LOADING";
export const SET_ERROR = "SET_ERROR";
export const SET_TASK_STATUS = "SET_TASK_STATUS";
export const SET_INTEGRATIONS_LIST = "SET_INTEGRATIONS_LIST";
export const SET_LOADING_INTEGRATION = "SET_LOADING_INTEGRATION";
export const SET_LOADING_HS_INFO = "SET_LOADING_HS_INFO";
export const SET_HS_INFO = "SET_HS_INFO";
export const SET_FILES_UPLOADING = "SET_FILES_UPLOADING";
export const SET_SKUS_UPLOAD_RESPONSE = "SET_SKUS_UPLOAD_RESPONSE";
export const SET_IS_LOADING_SKUS_FILE = "SET_IS_LOADING_SKUS_FILE";
export const SET_ACTIVE_MEASUREMENTS = "SET_ACTIVE_MEASUREMENTS";
export const SET_LOADING_STATES = "SET_LOADING_STATES";

type InitialStateType = {
    product_list: null | ProductType[];
    product_list_all: null | ProductType[];
    product_action_list: null | ProductActionResult[];
    product_action_list_all: null | ProductActionResult[];
    isLoading: boolean;
    isAmazonProductLoading: boolean;
    brandProducts: TStateBrandProducts | null;
    marketplaceProducts: TProductsFromAmazonState | null;
    taskStatus: null | string;
    integrationsList: null | IntegrationsListType[];
    isLoadingIntegrations: boolean;
    brands: string[];
    isLoadingUserDashboard: boolean;
    isLoadingHSInfo: boolean;
    hsInfo: HSInfoStateType | null;
    isUploadingFiles: boolean;
    skusUploadResponse: null | UploadFileOfSkusResponse[];
    isLoadingSkusFile: boolean;
    activeMeasurements: TActiveMeasurements | null;
    loadingStates: TAddProductLoadingStates;
};

let initialState: InitialStateType = {
    product_list: null,
    product_list_all: null,
    product_action_list: null,
    product_action_list_all: null,
    isLoading: false,
    isAmazonProductLoading: false,
    brandProducts: null,
    brands: [],
    marketplaceProducts: null,
    taskStatus: null,
    integrationsList: null,
    isLoadingIntegrations: false,
    isLoadingUserDashboard: false,
    isLoadingHSInfo: false,
    hsInfo: null,
    isUploadingFiles: false,
    skusUploadResponse: null,
    isLoadingSkusFile: false,
    activeMeasurements: null,
    loadingStates: {
        isLoadingUploadedProducts: false,
        isLoadingProductsRequiredAction: false,
        isLoadingBrands: false,
        isLoadingBrandProducts: false,
        isLoadingUSProductsFromAmazon: false,
        isLoadingUKProductsFromAmazon: false,
    },
};

const productsReducer = (state = initialState, action: ActionAddProductType): InitialStateType => {
    switch (action.type) {
        case SET_IS_LOADING_SKUS_FILE: {
            return {
                ...state,
                isLoadingSkusFile: action.data,
            };
        }
        case SET_SKUS_UPLOAD_RESPONSE: {
            return {
                ...state,
                skusUploadResponse: action.data,
            };
        }
        case SET_INTEGRATIONS_LIST: {
            return {
                ...state,
                integrationsList: action.data,
            };
        }
        case SET_TASK_STATUS: {
            return {
                ...state,
                taskStatus: action.data,
            };
        }
        case SET_AMAZON_PRODUCT_LOADING: {
            return {
                ...state,
                isAmazonProductLoading: action.data,
            };
        }
        case SET_PRODUCT: {
            return {
                ...state,
                product_list: action.data,
            };
        }
        case SET_PRODUCT_ACTION: {
            return {
                ...state,
                product_action_list: action.data,
            };
        }
        case SET_PRODUCT_ACTION_ALL: {
            return {
                ...state,
                product_action_list_all: action.data,
            };
        }
        case SET_PRODUCT_ALL: {
            return {
                ...state,
                product_list_all: action.data,
            };
        }
        case SET_BRANDS: {
            return {
                ...state,
                brands: action.data,
            };
        }
        case SET_BRAND_PRODUCT: {
            return {
                ...state,
                brandProducts: { ...state?.brandProducts, [action.brand]: action.brandProduct },
            };
        }
        case SET_LOADING: {
            return {
                ...state,
                isLoading: action.data,
            };
        }
        case SET_LOADING_INTEGRATION: {
            return {
                ...state,
                isLoadingIntegrations: action.data,
            };
        }
        case SET_PRODUCTS_FROM_AMAZON: {
            return {
                ...state,
                marketplaceProducts: { ...state.marketplaceProducts, ...action.data },
            };
        }
        case SET_LOADING_HS_INFO: {
            return {
                ...state,
                isLoadingHSInfo: action.data,
            };
        }
        case SET_HS_INFO: {
            return {
                ...state,
                hsInfo: action.data,
            };
        }
        case SET_FILES_UPLOADING: {
            return {
                ...state,
                isUploadingFiles: action.data,
            };
        }
        case SET_ACTIVE_MEASUREMENTS: {
            return {
                ...state,
                activeMeasurements: { ...state?.activeMeasurements, [action.countryCode]: { ...state?.activeMeasurements?.[action.countryCode], ...action.data } },
            };
        }
        case SET_LOADING_STATES: {
            return {
                ...state,
                loadingStates: { ...state.loadingStates, ...action.data },
            };
        }
        default:
            return state;
    }
};
export const SetIntegrationList = (data: IntegrationsListType[] | null): SetIntegrationListType => ({
    type: SET_INTEGRATIONS_LIST,
    data,
});
export const SetProductCount = (response: ProductType[]): SetProductType => ({
    type: SET_PRODUCT,
    data: response,
});
export const SetProductActionCount = (response: ProductActionResult[]): SetProductActionCountType => ({
    type: SET_PRODUCT_ACTION,
    data: response,
});
export const SetProductActionAllCount = (response: ProductActionResult[]): SetProductActionAllCountType => ({
    type: SET_PRODUCT_ACTION_ALL,
    data: response,
});
export const SetProductCountAll = (data: ProductType[]): SetProductAllType => ({
    type: SET_PRODUCT_ALL,
    data,
});
export const SetBrands = (response: string[]): SetBrandsType => ({
    type: SET_BRANDS,
    data: response,
});
export const SetBrandsProduct = (response: BrandProductType, brand: string): SetBandsProductType => ({
    type: SET_BRAND_PRODUCT,
    brandProduct: response,
    brand: brand,
});
export const SetLoading = (response: boolean): SetLoadingType => ({
    type: SET_LOADING,
    data: response,
});
export const SetLoadingIntegrations = (response: boolean): SetLoadingIntegrationsType => ({
    type: SET_LOADING_INTEGRATION,
    data: response,
});

export const SetAmazonProductLoading = (response: boolean): SetAmazonProductLoadingType => ({
    type: SET_AMAZON_PRODUCT_LOADING,
    data: response,
});
export const SetProductsFromAmazon = (data: TProductsFromAmazonState): SetProductsFromAmazonType => ({
    type: SET_PRODUCTS_FROM_AMAZON,
    data,
});
export const SetTaskStatus = (data: string): SetTaskStatusType => ({
    type: SET_TASK_STATUS,
    data,
});
export const SetLoadingHSInfo = (data: boolean): SetLoadingHSInfoType => ({
    type: SET_LOADING_HS_INFO,
    data: data,
});
export const SetHSInfo = (data: HSInfoStateType): SetHSInfoType => ({
    type: SET_HS_INFO,
    data,
});
export const SetFilesUploading = (data: boolean): SetFilesUploadingType => ({
    type: SET_FILES_UPLOADING,
    data,
});
export const SetSkusUploadFileResponse = (data: UploadFileOfSkusResponse[] | null): SetSkusFileUploadResponseType => ({
    type: SET_SKUS_UPLOAD_RESPONSE,
    data,
});
export const SetIsLoadingSkusFile = (data: boolean): SetIsLoadingSkusFileType => ({
    type: SET_IS_LOADING_SKUS_FILE,
    data,
});
export const SetActiveMeasurements = (countryCode: string, data: { isPounds?: boolean; isInches?: boolean }): TSetActiveMeasurements => ({
    type: SET_ACTIVE_MEASUREMENTS,
    countryCode,
    data,
});

export const SetLoadingStates = (data: { [key in keyof TAddProductLoadingStates]?: boolean }): TSetLoadingAddProductStates => ({
    type: SET_LOADING_STATES,
    data,
});

export const getIntegrationsList = (token: string): AddProductThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingIntegrations(true));
            let response = await dashboardAPI.getIntegrationsList(token);
            dispatch(SetIntegrationList(response.data.results));
            dispatch(SetLoadingIntegrations(false));
        } catch (e) {
            console.log(e);
        }
    };
};

export const editProduct = (cookies: Cookies, product: ProductType, data: EditProductDataType, activeMarket: string | undefined): AddProductThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingUploadedProducts: true }));
            await dashboardAPI.editProduct(cookies.get("token"), product.carton_id, data);

            // prettier-ignore
            const promises = [
                dispatch(ShipmentUploadedAction(cookies.get("token"), activeMarket)),
                dispatch(getBrandProduct(cookies, product.get_product_by_id.brand))
            ];

            await Promise.all(promises);

            if (data.category?.shipping_declaration_document.length) {
                displaySuccess("New documents will be updated soon");
            }
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingUploadedProducts: false }));
                displayError(e.response.data, "Something went wrong during a product update");
            }
        }
    };
};

export const deleteProduct = (cookies: Cookies, product: ProductType, activeMarket: string | undefined): AddProductThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingUploadedProducts: true }));
            await dashboardAPI.deleteProduct(cookies.get("token"), product.carton_id);
            dispatch(ShipmentUploadedAction(cookies.get("token"), activeMarket));
            dispatch(getBrandProduct(cookies, product.get_product_by_id.brand));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingUploadedProducts: false }));
                displayError(e.response.data, "Something went wrong while removing a product");
            }
        }
    };
};

export const deleteHSCode = (cookies: Cookies, product: ProductType, hsId: string): AddProductThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingUploadedProducts: true }));
            await dashboardAPI.deleteHSCode(cookies, hsId);
            dispatch(ShipmentUploadedAction(cookies.get("token")));
            dispatch(getBrandProduct(cookies, product.get_product_by_id.brand));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingUploadedProducts: false }));
                displayError(e.response.data, "Something went wrong when deleting the HS code");
            }
        }
    };
};

export const getBrandProduct = (cookies: Cookies, brand: string): AddProductThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingBrandProducts: true }));
            let response = await dashboardAPI.getBrandProduct(cookies.get("token"), brand);
            dispatch(SetBrandsProduct(response.data, brand));
            dispatch(SetLoadingStates({ isLoadingBrandProducts: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getBrandsNames = (cookies: Cookies): AddProductThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingBrands: true }));

            const response = await dashboardAPI.getBrands(cookies.get("token"));
            dispatch(SetBrands(response.data));

            dispatch(SetLoadingStates({ isLoadingBrands: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getProductAction = (token: string, country_code?: string): AddProductThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingProductsRequiredAction: true }));
            let response = await dashboardAPI.getProductAction(token, country_code);
            dispatch(SetProductActionCount(response.data.results));
            dispatch(SetLoadingStates({ isLoadingProductsRequiredAction: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getProductActionAll = (
    cookies: Cookies,
    items: number,
    offset: number,
    searchTerm: string,
    setProductAllNext: (value: boolean) => void,
    country_code?: string,
    brand?: string
): AddProductThunkType => {
    return async (dispatch) => {
        try {
            let response = await dashboardAPI.getProductActionAll(cookies.get("token"), items, offset, searchTerm, country_code, brand);
            dispatch(SetProductActionAllCount(response.data.results));
            setProductAllNext(response.data.next !== null ? true : false);
        } catch (e) {
            console.log(e);
        }
    };
};

export const deleteRequiredActionProduct = (cookies: Cookies, id: string): AddProductThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingProductsRequiredAction: true }));
            await dashboardAPI.deleteProduct(cookies.get("token"), id);
            dispatch(getProductAction(cookies.get("token")));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isLoadingProductsRequiredAction: false }));
                displayError(e.response.data, "Something went wrong while removing a product");
            }
        }
    };
};

export const ShipmentUploadedAction = (token: string, country_code?: string): AddProductThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingStates({ isLoadingUploadedProducts: true }));
            let response = await dashboardAPI.ShipmentUploadedAction(token, country_code);
            dispatch(SetProductCount(response.data.results));
            dispatch(SetLoadingStates({ isLoadingUploadedProducts: false }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const ShipmentUploadedActionAll = (
    cookies: Cookies,
    items: number,
    offset: number,
    search: string,
    setUploadedProductAllNext: (value: boolean) => void,
    country_code?: string,
    brand?: string
): AddProductThunkType => {
    return async (dispatch) => {
        try {
            let response = await dashboardAPI.ShipmentUploadedActionAll(cookies.get("token"), items, offset, search, country_code, brand);
            console.log(response.data);
            dispatch(SetProductCountAll(response.data.results));
            setUploadedProductAllNext(response.data.next !== null ? true : false);
        } catch (e) {
            console.log(e);
        }
    };
};

export const getProductFromAmazon = (
    cookies: Cookies,
    limit: number,
    offset: number,
    integration: IntegrationsListType,
    searchTerm: string,
    setProductsFromAmazonNext: (data: boolean) => void
): AddProductThunkType => {
    return async (dispatch) => {
        try {
            if (offset === 0 && limit < 1000) {
                dispatch(SetLoadingStates({ [integration.country_code === "US" ? "isLoadingUSProductsFromAmazon" : "isLoadingUKProductsFromAmazon"]: true }));
            }

            let response = await dashboardAPI.getProductFromAmazon(cookies, limit, offset, searchTerm, integration.id);

            dispatch(SetProductsFromAmazon({ [integration.id]: response.data.results }));
            setProductsFromAmazonNext(!!response.data.next);
            dispatch(SetTaskStatus(response.data.task_status));

            if (response.data.task_status === "WT" || response.data.task_status === "IP") {
                dispatch(SetAmazonProductLoading(true));
            } else if (response.data.task_status === "FN" || response.data.task_status === "FR") {
                dispatch(SetAmazonProductLoading(false));
            }

            if (offset === 0 && limit < 1000) {
                dispatch(SetLoadingStates({ [integration.country_code === "US" ? "isLoadingUSProductsFromAmazon" : "isLoadingUKProductsFromAmazon"]: false }));
            }
        } catch (e) {
            console.log(e);
        }
    };
};

export const getProductFromAmazonAll = (cookies: Cookies): AddProductThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));

            let response = await dashboardAPI.getProductFromAmazon(cookies, 1, 0, "");
            dispatch(SetTaskStatus(response.data.task_status));

            if (response.data.task_status === "WT" || response.data.task_status === "IP") {
                dispatch(SetAmazonProductLoading(true));
            } else if (response.data.task_status === "FN" || response.data.task_status === "FR") {
                dispatch(SetAmazonProductLoading(false));
            }

            dispatch(SetLoading(false));
        } catch (e) {
            console.log(e);
        }
    };
};

export const addProductFromAmazon = (amazonProducts: AddProductFromAmazonRequest[] | null, token: string, navigate: NavigateFunction): AddProductThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            await dashboardAPI.addProductFromAmazon(amazonProducts, token);
            navigate("/dashboard");
            dispatch(SetLoading(false));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoading(false));
                displayError(e.response.data, "Something went wrong while adding the products");
            }
        }
    };
};

export const addProductFromAmazonWithCategories = (
    amazonProducts: AddProductFromAmazonRequest[] | null,
    categories: AddProductFromAmazonCategoriesType[],
    token: string,
    navigate: NavigateFunction
): AddProductThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetFilesUploading(true));
            await dashboardAPI.addProductFromAmazonCategories(categories, token);
            dispatch(SetFilesUploading(false));

            dispatch(SetLoading(true));
            await dashboardAPI.addProductFromAmazon(amazonProducts, token);
            navigate("/dashboard");
            dispatch(SetLoading(false));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoading(false));
                displayError(e.response.data, "Something went wrong while adding the products");
            }
        }
    };
};

export const updateProductFromAmazon = (cookies: Cookies, fast_mode: boolean): AddProductThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            await dashboardAPI.updateProductFromAmazon(fast_mode, cookies.get("token"));
            dispatch(getIntegrationsList(cookies.get("token")));
            dispatch(getProductFromAmazonAll(cookies));
            dispatch(SetLoading(false));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoading(false));
                displayError(e.response.data, "Something went wrong during a products update");
            }
        }
    };
};

export const importCustomProductAmazon = (cookies: Cookies, sku_list: string[]): AddProductThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            await dashboardAPI.importCustomProductAmazon(sku_list, cookies.get("token"));
            dispatch(getIntegrationsList(cookies.get("token")));
            dispatch(getProductFromAmazonAll(cookies));
            dispatch(SetLoading(false));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoading(false));
                displayError(e.response.data, "Something went wrong when importing products");
            }
        }
    };
};

export const getHSCodeInfo = (query: string, countryCode: string, token: string): AddProductThunkType => {
    return async (dispatch, getState) => {
        const { addProduct } = getState();
        try {
            dispatch(SetLoadingHSInfo(true));
            await dashboardAPI.getHSCodeInfo(query, countryCode, token);
            dispatch(
                SetHSInfo(addProduct.hsInfo ? { ...addProduct.hsInfo, [countryCode]: { ...addProduct.hsInfo[countryCode], [query]: true } } : { [countryCode]: { [query]: true } })
            );
            dispatch(SetLoadingHSInfo(false));
        } catch (e) {
            console.error(e);
            if (axios.isAxiosError(e) && e.response && e.response.status === 400) {
                dispatch(
                    SetHSInfo(
                        addProduct.hsInfo ? { ...addProduct.hsInfo, [countryCode]: { ...addProduct.hsInfo[countryCode], [query]: false } } : { [countryCode]: { [query]: false } }
                    )
                );
                dispatch(SetLoadingHSInfo(false));
            }
        }
    };
};

export const getUploadSkusTemplate = (integrationId: string, token: string): AddProductThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetIsLoadingSkusFile(true));
            const response = await dashboardAPI.getUploadSkusTemplate(integrationId, token);
            window.open(response.data);
            dispatch(SetIsLoadingSkusFile(false));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetIsLoadingSkusFile(false));
                displayError(e.response.data, "Something went wrong while downloading template file");
            }
        }
    };
};

export const uploadFileWithSkus = (integrationId: string, token: string, file: File): AddProductThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetIsLoadingSkusFile(true));
            const response = await dashboardAPI.uploadFileWithSkus(integrationId, token, file);
            dispatch(SetSkusUploadFileResponse(response.data));
            dispatch(SetIsLoadingSkusFile(false));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetIsLoadingSkusFile(false));
                displayError(e.response.data, "Something went wrong while uploading file");
            }
        }
    };
};

export const waitForCompletionProductsImport = (cookies: Cookies): AddProductThunkType => {
    return async (dispatch) => {
        try {
            for (let i = 0; i < 15; i++) {
                let response = await dashboardAPI.getProductFromAmazon(cookies, 1, 0, "");

                if (response.data.task_status === "FN" || response.data.task_status === "FR") {
                    dispatch(SetTaskStatus(response.data.task_status));
                    dispatch(SetAmazonProductLoading(false));

                    break;
                }

                await new Promise((resolve) => setTimeout(resolve, 3000));
            }
        } catch (e) {
            console.log(e);
        }
    };
};

export default productsReducer;
