import React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import checkEmailImg from "../../../assets/img/checkEmail.png";
import preloader from "../../../assets/video/preloader.webp";
import { useAppSelector } from "../../../redux/ReduxStore";

const EmailSent = () => {
    const isLoading = useAppSelector((state) => state.auth.isLoading);

    if (isLoading) {
        return (
            <Container className="block auth-form-container container-bg">
                <Form className="auth-form d-flex align-items-center justify-content-center shadow-sm">
                    <img src={preloader} style={{ marginLeft: 40 }} width="150" height="150" alt="Loading..."></img>
                </Form>
            </Container>
        );
    }
    return (
        <Container className="block auth-form-container container-bg p-0 justify-content-start">
            <Container className="d-flex flex-column align-items-center justify-content-center w-100 h-100">
                <Form className="auth-form complete shadow-sm">
                    <Row>
                        <Col className="d-flex justify-content-center mb-5">
                            <img src={checkEmailImg} alt="Loading..." style={{ width: "30%" }}></img>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-flex justify-content-center flex-column text-center">
                            <h4>Please check your email inbox</h4>
                            <p className="text-muted">To reset your password, please follow the link in the email you receive.</p>
                        </Col>
                    </Row>
                </Form>
            </Container>
        </Container>
    );
};

export default EmailSent;
