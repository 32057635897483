import Cookies from "universal-cookie";
import {
    getGeocodedAddress,
    getGoogleWay,
    getParcelAppTrackingInfo,
    getShipmentAddress,
    getShipmentCurrentTrackingInfo,
    getTrackingContainerInfo,
} from "../../../../api/trackingExternalAPI";
import {
    TDeliveredToPortObj,
    TGetShipmentAddressReq,
    TShipmentTrackingPosition,
    TShipmentsTrackingInfo,
    TShipmentsTrackingInfoObj,
    TShipsGoExternalTrackingIds,
    TTrackingShipmentsResult,
} from "../../../../redux/types/TrackingTypes/tracking.types";

export const calculateCurrentProgress = (shipment: TTrackingShipmentsResult, shipmentTrackingInfo: TShipmentsTrackingInfoObj | undefined) => {
    // function for processing shipment progress bar
    if (shipment.progress_bar_2 === 100 || shipment.delivered_to_amazon) {
        return 100;
    } else if (typeof shipmentTrackingInfo?.custom_progress_bar === "number") {
        // when custom_progress_bar is set
        return shipmentTrackingInfo.custom_progress_bar;
    } else if (shipment.tracking_information.delivered_to_port || shipmentTrackingInfo?.delivered_to_port) {
        return shipment.progress_bar_2;
    } else {
        return shipment.progress_bar * 0.65;
    }
};

export const findShipsGoCoorditates = (results: TShipmentTrackingPosition) => {
    // function for processing shipment coordinates based on ShipsGo response.
    let coordinates;

    if (results.VesselLatitude !== "Not Supported" && results.VesselLongitude !== "Not Supported") {
        coordinates = {
            lat: results.VesselLatitude,
            lng: results.VesselLongitude,
        };
    } else if (
        results.TSPorts &&
        results.TSPorts?.length > 0 &&
        results.TSPorts.some((item) => item.VesselLatitude !== "Not Supported" && item.VesselLongitude !== "Not Supported")
    ) {
        const objWithCoordinates = results.TSPorts.reverse().find((item) => item.VesselLatitude !== "Not Supported" && item.VesselLongitude !== "Not Supported");

        if (objWithCoordinates && typeof objWithCoordinates.VesselLatitude === "number" && typeof objWithCoordinates.VesselLongitude === "number") {
            coordinates = {
                lat: objWithCoordinates.VesselLatitude,
                lng: objWithCoordinates.VesselLongitude,
            };
        }
    }

    return coordinates || null;
};

const processShipsGoStatuses = (shipment: TTrackingShipmentsResult, shipmentInfo: TShipmentsTrackingInfoObj, results: TShipmentTrackingPosition) => {
    // function for processing ShipGo response
    let deliveredToPortInfo: TDeliveredToPortObj | undefined;

    if (results.StatusId === 30 || results.StatusId === 35) {
        // shipment in POL
        const shipmentCoordinates = findShipsGoCoorditates(results);
        shipmentInfo.before_port_coordinates = shipmentCoordinates;
        shipmentInfo.custom_progress_bar = 0;
    } else if (results.StatusId === 40) {
        // shipment is in Sea
        const shipmentCoordinates = findShipsGoCoorditates(results);
        shipmentInfo.before_port_coordinates = shipmentCoordinates;

        if (results.DepartureDate.Date && results.ArrivalDate.Date) {
            // case for calculation progress bar using ShipGo dates (required DepartureDate and ArrivalDate)
            const dateNow = new Date().valueOf();
            const departureDate = new Date(results.DepartureDate.Date).valueOf();
            const arrivalDate = new Date(results.ArrivalDate.Date).valueOf();
            const totalDuration = arrivalDate - departureDate;
            const elapsedDuration = dateNow - departureDate;

            shipmentInfo.custom_progress_bar = Math.max(0, Math.min(62, (elapsedDuration / totalDuration) * 62));
        }
    } else if (results.StatusId === 45 || results.StatusId === 50) {
        // shipment in POD
        const shipmentCoordinates = findShipsGoCoorditates(results);
        shipmentInfo.before_port_coordinates = shipmentCoordinates;
        shipmentInfo.delivered_to_port = true;
        if (shipmentCoordinates) {
            deliveredToPortInfo = { order_id: shipment.id, port_coordinates: shipmentCoordinates };
        }
    } else {
        // need to display warning message that currently we can't display location
        shipmentInfo.unable_to_track = true;
        shipmentInfo.custom_progress_bar = 0;
    }

    return { shipmentInfo, deliveredToPortInfo };
};

export const getShipmentTrackingInfo = async (
    shipment: TTrackingShipmentsResult,
    shipmentInfo: TShipmentsTrackingInfoObj,
    deliveredToPort: TDeliveredToPortObj[],
    shipsGoTrackingIds: TShipsGoExternalTrackingIds[],
    delayedUSAirShipments: TGetShipmentAddressReq[]
) => {
    // main function for shipments tracking using external APIs
    try {
        if (shipment.shipment_speed_type === "AIR") {
            const response = await getParcelAppTrackingInfo(shipment.container_number);

            const containerDate = new Date(shipment.created_at.replace(/(\d+)(st|nd|rd|th) ([A-Za-z]+) (\d+)/, "$3 $1, $4"));
            const dateNow = new Date();
            const dateDifference = dateNow.valueOf() - containerDate.valueOf();

            if (response && response?.shipments[0]?.lastState?.location) {
                const shipmentTrackingInfo = response.shipments[0].states;

                if (!shipmentTrackingInfo.some((obj) => obj.status.toUpperCase().includes("DELIVERED")) && dateDifference > 5) {
                    // shipment is delivering more than 5 days
                    const geocodedAddress = await getGeocodedAddress(response.shipments[0].lastState.location);

                    shipmentInfo.before_port_coordinates = geocodedAddress?.results[0].geometry.location || null;

                    if (shipment.carrier_tracking_id) {
                        delayedUSAirShipments.push({ order_id: shipment.id, tracking_id: shipment.carrier_tracking_id });
                    }
                } else if (shipmentTrackingInfo.some((obj) => obj.status.toUpperCase().includes("DELIVERED"))) {
                    // shipment delivered to AOD
                    const findedStatus = shipmentTrackingInfo.find((obj) => obj.status.toUpperCase().includes("DELIVERED"));

                    if (findedStatus && findedStatus?.location) {
                        const geocodedAddress = await getGeocodedAddress(findedStatus.location);

                        shipmentInfo.before_port_coordinates = geocodedAddress?.results[0].geometry.location || null;
                        shipmentInfo.custom_progress_bar = shipment.carrier_tracking_id ? false : 65;
                        shipmentInfo.delivered_to_port = true;

                        if (geocodedAddress?.results[0].geometry.location) {
                            deliveredToPort.push({ order_id: shipment.id, port_coordinates: geocodedAddress?.results[0].geometry.location });
                        }
                    }
                } else if (shipmentTrackingInfo.some((obj) => obj.status.toUpperCase().includes("DEPARTED"))) {
                    // shipment arrived from AOL
                    const findedStatus = shipmentTrackingInfo.find((obj) => obj.status.toUpperCase().includes("DEPARTED"));

                    if (findedStatus && findedStatus?.location) {
                        const geocodedAddress = await getGeocodedAddress(findedStatus.location);

                        shipmentInfo.before_port_coordinates = geocodedAddress?.results[0].geometry.location || null;
                    }
                } else if (shipmentTrackingInfo.some((obj) => obj.status.toUpperCase().includes("BOOKED"))) {
                    // shipment is just in AOL
                    const findedStatus = shipmentTrackingInfo.find((obj) => obj.status.toUpperCase().includes("BOOKED"));

                    if (findedStatus && findedStatus?.location) {
                        const geocodedAddress = await getGeocodedAddress(findedStatus.location);

                        shipmentInfo.before_port_coordinates = geocodedAddress?.results[0].geometry.location || null;
                        shipmentInfo.custom_progress_bar = 0;
                    }
                } else {
                    const geocodedAddress = await getGeocodedAddress(response.shipments[0].lastState.location);

                    shipmentInfo.before_port_coordinates = geocodedAddress?.results[0].geometry.location || null;
                }
            } else {
                // case when ParselApp did not receive any usefull information
                if (dateDifference > 5 && shipment.carrier_tracking_id) {
                    // shipment is delivering more than 5 days
                    delayedUSAirShipments.push({ order_id: shipment.id, tracking_id: shipment.carrier_tracking_id });
                } else {
                    // tracking id is just created and don't have any useful tracking info
                    // need to display warning message that currently we can't display location
                    shipmentInfo.unable_to_track = true;
                    shipmentInfo.custom_progress_bar = 0;
                }
            }
        } else {
            // case for US SEA tracking using ShipsGo API
            if (shipment.tracking_information.external_tracking_id) {
                // case when we already have ShipsGO tracking id
                const response = await getShipmentCurrentTrackingInfo(shipment.tracking_information.external_tracking_id);

                if (response && response.length > 0) {
                    const results = response[0];

                    const newData = processShipsGoStatuses(shipment, shipmentInfo, results);
                    shipmentInfo = newData.shipmentInfo;
                    newData.deliveredToPortInfo && deliveredToPort.push(newData.deliveredToPortInfo);
                }
            } else {
                // case with creating ShipGo tracking request to obtain its tracking id for the shipment
                const response = await getTrackingContainerInfo(shipment);

                if (response) {
                    shipsGoTrackingIds.push({ external_tracking_id: response, container_id: shipment.container_id });

                    const responseShipment = await getShipmentCurrentTrackingInfo(response);

                    if (responseShipment) {
                        const results = responseShipment[0];

                        const newData = processShipsGoStatuses(shipment, shipmentInfo, results);
                        shipmentInfo = newData.shipmentInfo;
                        newData.deliveredToPortInfo && deliveredToPort.push(newData.deliveredToPortInfo);
                    }
                }
            }
        }
    } catch {
        shipmentInfo.unable_to_track = true;
        shipmentInfo.custom_progress_bar = 0;
    }

    return {
        shipmentInfo,
    };
};

export const getUSDelayedTrackingInfo = async (
    cookies: Cookies,
    delayedUSAirShipments: TGetShipmentAddressReq[],
    shipmentData: TShipmentsTrackingInfo,
    deliveredToPort: TDeliveredToPortObj[]
) => {
    // function for tracking shipments that delivering more than n days (in our case is 5 days)
    try {
        const resp = await getShipmentAddress(cookies, delayedUSAirShipments);

        if (resp) {
            for (let info of resp) {
                if (info.origin_address || info.current_address) {
                    if (info.origin_address) {
                        // case when shipment is delivering more than 5 days and trackable througth upx/fedex
                        // this case mean than shipment is in airport of destination
                        const address = `${info.origin_address.city}, ${info.origin_address.stateProvince}, ${info.origin_address.country}`;
                        const geocodedAddress = await getGeocodedAddress(address);
                        shipmentData[info.order_id].delivered_to_port = true;

                        if (geocodedAddress) {
                            shipmentData[info.order_id].before_port_coordinates = geocodedAddress.results[0].geometry.location;
                            deliveredToPort.push({ order_id: info.order_id, port_coordinates: geocodedAddress.results[0].geometry.location });
                        }
                    }

                    if (info.current_address && shipmentData[info.order_id].delivered_to_port) {
                        // case when shipment is delivering more than 5 days and trackable througth upx/fedex
                        // this case mean than shipment is arrived from airport of destination
                        const address = `${info.current_address.city}, ${info.current_address.stateProvince}, ${info.current_address.country}`;
                        const geocodedAddress = await getGeocodedAddress(address);

                        if (geocodedAddress) {
                            shipmentData[info.order_id].after_port_coordinates = geocodedAddress.results[0].geometry.location;
                        }
                    } else {
                        // if the shipment has been delivered to the airport of destination and has not left it
                        shipmentData[info.order_id].custom_progress_bar = 65;
                    }
                } else {
                    // case when ups/fedex is not provide any data
                    shipmentData[info.order_id].unable_to_track = shipmentData[info.order_id].before_port_coordinates ? false : true;
                    shipmentData[info.order_id].custom_progress_bar = shipmentData[info.order_id].before_port_coordinates ? false : 0;
                }
            }
        }
    } catch (e) {
        console.log(e);
    }
};

export const getUSTrackingInfo = async (cookies: Cookies, shipmentsForUSTracking: TGetShipmentAddressReq[], shipmentData: TShipmentsTrackingInfo) => {
    try {
        const resp = await getShipmentAddress(cookies, shipmentsForUSTracking);

        if (resp) {
            for (let info of resp) {
                if (info.current_address) {
                    const address = `${info.current_address.city}, ${info.current_address.stateProvince}, ${info.current_address.country}`;
                    const geocodedAddress = await getGeocodedAddress(address);

                    if (geocodedAddress) {
                        shipmentData[info.order_id].after_port_coordinates = geocodedAddress.results[0].geometry.location;
                    }
                } else {
                    shipmentData[info.order_id].custom_progress_bar = 65;
                }
            }
        }
    } catch (e) {
        console.log(e);
    }
};

export const predictCoordinates = async (cookies: Cookies, start_point: string, end_point: string, percentage: number) => {
    const resp = await getGoogleWay(cookies, start_point, end_point);

    if (resp && resp.status === "OK") {
        const route = resp.routes[0];
        const totalDistance = route.legs.reduce((acc, leg) => acc + leg.distance.value, 0) / 1000; // Total distance in kilometers

        const desiredDistance = totalDistance * (percentage / 100);

        let currentDistance = 0;
        for (let i = 0; i < route.legs.length; i++) {
            const leg = route.legs[i];
            const legDistance = leg.distance.value / 1000; // Distance for this leg in kilometers

            if (currentDistance + legDistance >= desiredDistance) {
                // Calculate the coordinates along this leg
                const percent = (desiredDistance - currentDistance) / legDistance;
                const lat = leg.start_location.lat + percent * (leg.end_location.lat - leg.start_location.lat);
                const lng = leg.start_location.lng + percent * (leg.end_location.lng - leg.start_location.lng);

                return {
                    lat,
                    lng,
                };
            }

            currentDistance += legDistance;
        }
    } else {
        console.error("Directions request failed due to " + resp?.status);
    }
};

export const getShipmentsTrackingInfo = async (cookies: Cookies, trackingShipments: TTrackingShipmentsResult[], shipmentsTrackingInfo: TShipmentsTrackingInfo) => {
    let shipmentData: TShipmentsTrackingInfo = {};
    let deliveredToPort: TDeliveredToPortObj[] = [];
    let shipsGoTrackingIds: TShipsGoExternalTrackingIds[] = [];
    let shipmentsForUSTracking: TGetShipmentAddressReq[] = [];
    let delayedUSAirShipments: TGetShipmentAddressReq[] = [];

    for (const shipment of trackingShipments) {
        if (!shipmentsTrackingInfo[shipment.id] && shipment.progress_bar_2 !== 100) {
            let shipmentInfo: TShipmentsTrackingInfoObj = {
                delivered_to_port: false,
                before_port_coordinates: null,
                after_port_coordinates: null,
                custom_progress_bar: false,
                unable_to_track: false,
            };

            if (!shipment.tracking_information.delivered_to_port) {
                const data = await getShipmentTrackingInfo(shipment, shipmentInfo, deliveredToPort, shipsGoTrackingIds, delayedUSAirShipments);

                shipmentInfo = data.shipmentInfo;
            }

            if ((shipment.tracking_information.delivered_to_port || shipmentInfo.delivered_to_port) && shipment.carrier_tracking_id) {
                // when shipment is delivered to port and shipment has UPS/FedEx tracking id
                shipmentsForUSTracking.push({ order_id: shipment.id, tracking_id: shipment.carrier_tracking_id });
            } else if (
                !shipment.boat_calculation_type.includes("Truck") &&
                (shipment.tracking_information.delivered_to_port || shipmentInfo.delivered_to_port) &&
                !shipment.carrier_tracking_id
            ) {
                // shipment is delivered to port, but without UPS/FedEx tracking id (there should be no Truck last mile delivery)
                shipmentInfo.custom_progress_bar = 65;
            }

            if (
                shipment.boat_calculation_type.includes("Truck") &&
                shipment.warehouse_coordinate &&
                (shipmentInfo?.delivered_to_port || shipment.tracking_information.delivered_to_port)
            ) {
                // when shipment last mile delivery is "Truck" and shipment is delivered to port (also required warehouse coordinates to predict route)
                const percentage = ((shipment.progress_bar_2 - 65) / (100 - 65)) * 100;
                const portCoordinates = shipment.tracking_information.port_coordinates || shipmentInfo?.before_port_coordinates || shipmentInfo?.after_port_coordinates;

                const resp = await predictCoordinates(
                    cookies,
                    `${portCoordinates?.lat}, ${portCoordinates?.lng}`,
                    `${shipment.warehouse_coordinate.lat}, ${shipment.warehouse_coordinate.lng}`,
                    percentage
                );

                if (resp) {
                    shipmentInfo.after_port_coordinates = resp;
                } else {
                    shipmentInfo.unable_to_track = true;
                    shipmentInfo.custom_progress_bar = 65;
                }
            }

            shipmentData = { ...shipmentData, [shipment.id]: shipmentInfo };
        }
    }

    if (shipmentsForUSTracking.length > 0) {
        await getUSTrackingInfo(cookies, shipmentsForUSTracking, shipmentData);
    }

    if (delayedUSAirShipments.length > 0) {
        await getUSDelayedTrackingInfo(cookies, delayedUSAirShipments, shipmentData, deliveredToPort);
    }

    return {
        shipmentData,
        deliveredToPort,
        shipsGoTrackingIds,
    };
};
