import { useState, useEffect, FC } from "react";
import { Button } from "react-bootstrap";
import { VscDebugRestart } from "react-icons/vsc";
import { MonthDataType } from "./CalculatorSection";

export type QuizePropsType = {
    question: string;
    buttons: MonthDataType[];
    nextStep: (data: string, customStep: null | number, submitRequest: boolean) => void;
    customStep: null | number;
    submitRequest: boolean;
    onResetCalculator: () => void;
};

const Quize: FC<QuizePropsType> = ({ question, buttons, nextStep, customStep, submitRequest, onResetCalculator }) => {
    const [mobile, setMobile] = useState(false);

    const handleResize = () => {
        if (window.innerWidth < 990) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    };

    // create an event listener
    useEffect(() => {
        window.addEventListener("resize", handleResize);
    });

    useEffect(() => {
        handleResize();
    }, []);
    return (
        <div className="d-flex flex-column align-items-center justify-content-center w-100">
            {mobile ? (
                <div className="d-flex flex-column align-items-center justify-content-center w-100">
                    <div className="d-flex flex-column align-items-center justify-content-center">
                        <h3 className="text-center story-title w-100 px-3">Test Drive Our Rates... </h3>
                        <p className="p-landing-page px-3 text-center">Remember, the SKUdrop model works best with regular weekly shipments</p>
                    </div>
                    <div className="d-flex flex-column align-items-center justify-content-center pt-5 px-3 pb-4 border min-h-350 position-relative w-9 bg-white">
                        <div className="position-absolute reset-calculator" onClick={() => onResetCalculator()}>
                            <VscDebugRestart style={{ width: 25, height: 25, color: "#1B2B4A" }} title="Reset calculator" />
                        </div>
                        <h5 className="text-center">{question}</h5>
                        <div className="d-flex flex-row align-items-center justify-content-center w-100 flex-wrap">
                            {buttons &&
                                buttons.map((item, index) => (
                                    <div key={index} className="d-flex flex-column align-items-center justify-content-center mx-3 flex-wrap ">
                                        <Button className="mt-4 question-button px-4" variant="primary" onClick={() => nextStep(item.text, customStep, submitRequest)}>
                                            {item.text}
                                        </Button>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            ) : (
                <div className="d-flex flex-column align-items-center justify-content-center w-100">
                    <div className="d-flex flex-column align-items-center justify-content-center">
                        <h1 className="text-center story-title w-100 px-3">Test Drive Our Rates... </h1>
                        <p className="p-landing-page px-3 text-center">Remember, the SKUdrop model works best with regular weekly shipments</p>
                    </div>
                    <div className="d-flex flex-column align-items-center justify-content-center pt-5 px-3 pb-4 border calculator-wrapper min-h-350 position-relative bg-white">
                        <div aria-label="reset-calculator" className="position-absolute reset-calculator" onClick={() => onResetCalculator()}>
                            <VscDebugRestart style={{ width: 25, height: 25, color: "#1B2B4A" }} title="Reset calculator" />
                        </div>
                        <h3 className="text-center">{question}</h3>
                        <div className="d-flex flex-row align-items-center justify-content-center w-100 flex-wrap">
                            {buttons &&
                                buttons.map((item, index) => (
                                    <div key={index} className="d-flex flex-column align-items-center justify-content-center mx-3">
                                        <Button
                                            name="question-button"
                                            className="mt-4 question-button px-4"
                                            variant="primary"
                                            onClick={() => nextStep(item.text, customStep, submitRequest)}
                                        >
                                            {item.text}
                                        </Button>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Quize;
