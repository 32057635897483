type GenerateRangeDateType = {
    startDate?: string | null | Date | undefined;
    endDate?: string | null | Date | undefined;
};

export const generateRangeDate = (range: GenerateRangeDateType) => {
    const newStartDate = range.startDate ? new Date(range.startDate) : "";
    const newEndDate = range.endDate ? new Date(range.endDate) : "";
    const generatedStartDate = newStartDate ? `${newStartDate.getFullYear()}-${newStartDate.getMonth() + 1}-${newStartDate.getDate()}` : "";
    const generatedEndDate = newEndDate ? `${newEndDate.getFullYear()}-${newEndDate.getMonth() + 1}-${newEndDate.getDate()}` : "";
    return { generatedStartDate, generatedEndDate };
};
