import HowWorkCard from "../common/HowWorkCard";

import how_work_1 from "../../../assets/img/LandingPage/HowWorksSection/how_works_1.webp";
import how_work_2 from "../../../assets/img/LandingPage/HowWorksSection/how_works_2.png";
import how_work_3 from "../../../assets/img/LandingPage/HowWorksSection/how_works_3.webp";
import how_work_4 from "../../../assets/img/LandingPage/HowWorksSection/how_works_4.png";

const HowWorkSection = () => {
    return (
        <div className="feature-bg d-flex align-items-center justify-content-center pb-5">
            <div className="pl-section">
                <h1 className="section-name save-money-title story-title">How SKUdrop works</h1>
                <div className="how-work-block">
                    <HowWorkCard
                        image={how_work_1}
                        headline="WE STORE"
                        title="SKUdrop is your cost effective storage facility in China."
                        description="By using our Chinese warehouses, you save yourself so much money when it comes to storage as you eliminate the expensive costs associated with storing your cartons in US 3PL’s."
                    />
                    <HowWorkCard
                        image={how_work_2}
                        small={true}
                        headline="WE SHIP"
                        title="SKUdrop can facilitate your shipments with maximum flexibility."
                        description="Send weekly direct shipments into Amazon by creating shipping plans in Seller Central from our platform with ease, or use your preferred Freight Forwarder to best suit your needs."
                    />
                    <HowWorkCard
                        image={how_work_3}
                        headline="WE MANAGE"
                        title="SKUdrop manages your carton’s journey into Amazon."
                        description="Our software updates your shipping plans with tracking ID’s and our team manages your tariffs and duties payable at the port. We even provide you with 7501 entry summary extracts."
                    />
                    <HowWorkCard
                        image={how_work_4}
                        headline="WE SIMPLIFY"
                        title="Our software is your supply chain management tool."
                        description="Get full visibility of stock levels, replenish your stock in our warehouse, create shipping plans with the click of a button, receive instant shipping quotes from 3 carriers and save time."
                    />
                </div>
            </div>
        </div>
    );
};

export default HowWorkSection;
