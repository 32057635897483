import DataTable from "../common/DataTable";
import sku_logo from "../../../assets/img/skudrop-logo.png";
import container from "../../../assets/img/LandingPage/CaseStudy/container.png";

const CaseStudyTable1 = () => {
    return (
        <>
            <DataTable
                image_1={sku_logo}
                image_2={container}
                th_title1="stored in China & shipped by doing smaller regular direct shipments into Amazon over 3 months"
                th_title2="shipped to a US 3PL in a 40” HC container then into Amazon"
                th_title_number1="671 cartons"
                th_title_number2="All 671 cartons"
                tr_1_3="SENDING A FULL CONTAINER "
                prepare_num_cartons="Prepping 671 cartons:"
                prepare_num_cartons_cost_1="$1,845.25"
                prepare_num_cartons_cost_2="$2,516.25"
                prepare_num_cartons_title_2="$3.75 USD/ CARTON"
                tariffs_price_month="$9,200"
                tariffs_price_week="$766 dollars per week"
                freight_cost_1="$8,102 USD"
                freight_cost_1_title="no more to pay, paid over a 3 month period"
                freight_cost_2="$8,900 USD"
                bonds_2="$250 USD"
                cartons_delivered_1="$400 USD"
                port_dwell_2="$300 USD"
                delivery_fees_2="$1,200 USD"
                pl_receiving_2="$825 USD"
                pallets_fees_2="$630 USD"
                amazon_spd="$15.94 USD / CARTON - $10,698 USD"
                total_1="$19,547.25"
                total_2="$34,519.25"
            />
        </>
    );
};

export default CaseStudyTable1;
