import React from "react";
import StudyHero from "../common/StudyHero";

const CaseStudyHero2 = () => {
    return (
        <>
            <StudyHero
                label="Case Study #2"
                title="Less Than Container Load (LCL)"
                description="This case study is based on an Amazon seller shipping 80 cartons using the traditional supply chain sequence (sending a less than container load shipment to a US 3PL then into Amazon) then shipping 80 cartons using the SKUdrop solution (smaller regular direct shipments into Amazon over a 3 month period)."
                subTitle="Costs are based on an LCL shipment of 80 cartons - July 2022"
            />
        </>
    );
};

export default CaseStudyHero2;
