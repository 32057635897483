import { Col, Container, Form, Row } from "react-bootstrap";

import { registerComplete } from "../../../redux/authReducer";
import { useAppDispatch, useAppSelector } from "../../../redux/ReduxStore";

import preloader from "../../../assets/video/preloader.webp";
import registerCompleteSVG from "../../../assets/img/SuccessMWS.png";

const SignUpPortalComplete = () => {
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector((state) => state.auth.isLoading);
    const cookies = useAppSelector((state) => state.auth.cookies);

    const onSubmit = () => {
        dispatch(registerComplete(cookies));
        try {
            const splittedArray = window.location.hostname.split(".");
            let newArray;

            if (splittedArray.length > 3) {
                newArray = splittedArray.map((el, idx) => (idx === 0 ? "dev" : el));
            } else {
                newArray = splittedArray.map((el, idx) => (idx === 0 ? "www" : el));
            }

            window.location.replace(`https://${newArray.join(".")}/`);
        } catch (e) {
            window.location.replace(`https://www.skudrop.com/`);
        }
    };

    if (isLoading) {
        return (
            <Container className="block auth-form-container container-bg">
                <Form className="auth-form d-flex align-items-center justify-content-center shadow-sm">
                    <img src={preloader} alt="Loading..." style={{ marginLeft: 40 }} width="150" height="150"></img>
                </Form>
            </Container>
        );
    }

    return (
        <Container className="block auth-form-container container-bg p-0 justify-content-start">
            <Container className="d-flex flex-column align-items-center justify-content-center w-100 h-100">
                <Form className="auth-form complete shadow-sm">
                    <Row>
                        <Col className="d-flex justify-content-center mb-2">
                            {registerCompleteSVG && <img src={registerCompleteSVG} className="register-complete-image" style={{ width: "40%" }} alt="logo"></img>}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-flex justify-content-center flex-column text-center">
                            <h4 className="complete-title">Your account has been registered successfully. </h4>
                            <h2 className="my-2 mb-3 complete-h2-text">You will be able to log in once your account has been approved</h2>
                            <button type="button" className="btn btn-primary register-coplete-btn py-2" onClick={onSubmit} style={{ textDecoration: "none", color: "#ffff" }}>
                                GO TO MAIN PAGE
                            </button>
                        </Col>
                    </Row>
                </Form>
            </Container>
        </Container>
    );
};

export default SignUpPortalComplete;
