import axios from "axios";
import Cookies from "universal-cookie";

import {
    ActionAdminWarehouseType,
    SetErrorWarehouseListType,
    SetIsLoadingType,
    TPatchWarehouse,
    TPostWarehouse,
    ThunkType,
} from "./types/AdminWarehouseTypes/AdminWarehouse.types";
import { getWarehouseList } from "./warehouseReducer";
import { dashboardWarehouseAPI } from "../api/adminWarehouse";
import { displayError, displaySuccess } from "../hooks/useErrorHandler";

export const SET_ERROR_WAREHOUSE_LIST = "SET_ERROR_WAREHOUSE_LIST";
export const SET_LOADING = "SET_LOADING";

type AdminWarehouseInitialType = {
    isErrorWarehouseList: null | boolean;
    isLoading: boolean;
};

let initialState: AdminWarehouseInitialType = {
    isErrorWarehouseList: null,
    isLoading: false,
};

const warehouseSetUpReducer = (state = initialState, action: ActionAdminWarehouseType): AdminWarehouseInitialType => {
    switch (action.type) {
        case SET_ERROR_WAREHOUSE_LIST: {
            return {
                ...state,
                isErrorWarehouseList: action.data,
            };
        }
        case SET_LOADING: {
            return {
                ...state,
                isLoading: action.data,
            };
        }
        default:
            return state;
    }
};

export const SetErrorWarehouseList = (error: null | boolean): SetErrorWarehouseListType => ({
    type: SET_ERROR_WAREHOUSE_LIST,
    data: error,
});

export const SetLoading = (loading: boolean): SetIsLoadingType => ({
    type: SET_LOADING,
    data: loading,
});

export const WarehouseSetUp = (token: string, data: TPostWarehouse, onSucces: () => void): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoading(true));
            await dashboardWarehouseAPI.warehouseSetUp(token, data);
            onSucces();
            displaySuccess("Warehouse creation procedure started.");
            dispatch(SetLoading(false));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoading(false));
                displayError(e.response.data, "Something went wrong when creating a warehouse");
            }
        }
    };
};

export const PatchWarehouse = (cookies: Cookies, id: string, data: TPatchWarehouse): ThunkType => {
    return async (dispatch) => {
        try {
            let response = await dashboardWarehouseAPI.patchWarehouse(cookies, id, data);
            dispatch(getWarehouseList(cookies));
            if (response.data) {
                dispatch(SetErrorWarehouseList(false));
            }
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                displayError(e.response.data, "Something went wrong during the warehouse update");
            }
        }
    };
};

export default warehouseSetUpReducer;
