import ReactGA from "react-ga";
import { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";

import { confirmAmazon } from "../../../redux/authReducer";
import { useAppDispatch, useAppSelector } from "../../../redux/ReduxStore";
import { AmazonLinkAxiosResponse } from "../../../redux/types/AuthTypes/auth.types";

import preloader from "../../../assets/video/preloader.webp";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const ConfirmAmazonLink = () => {
    const dispatch = useAppDispatch();

    let query = useQuery();
    const spapi_oauth_code = query.get("spapi_oauth_code");
    const state = query.get("state");
    const selling_partner_id = query.get("selling_partner_id");
    const cookies = useAppSelector((state) => state.auth.cookies);

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    const redirectToDashboard = () => {
        const tenantName = window.location.hostname.includes(".dev.skudrop.com") ? cookies.get("tenantName") + ".dev" : cookies.get("tenantName");
        tenantName && window.location.replace("https://" + tenantName + ".skudrop.com/dashboard/add-product-from-amazon/");
    };

    const showEditNameModal = (data: AmazonLinkAxiosResponse) => {
        window.location.replace("https://" + data.url + `/dashboard/settings/integration/?integration_id=${data.id}`);
    };

    useEffect(() => {
        if (spapi_oauth_code && state && selling_partner_id) {
            dispatch(confirmAmazon(spapi_oauth_code, state, selling_partner_id, showEditNameModal, redirectToDashboard));
        }
    }, []);

    return (
        <Container className="spiner-container container-bg">
            <img src={preloader} style={{ marginLeft: 40 }} width="150" height="150" alt="Loading..."></img>
        </Container>
    );
};

export default ConfirmAmazonLink;
