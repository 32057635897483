import ReactGA from "react-ga";
import { useEffect } from "react";
import { Container } from "react-bootstrap";
import { Navigate, useNavigate, useParams } from "react-router-dom";

import { confirmEmail } from "../../../redux/authReducer";
import { useAppDispatch, useAppSelector } from "../../../redux/ReduxStore";

import preloader from "../../../assets/video/preloader.webp";

export type ConfirmEmailUrlParamsType = {
    id: string;
    token: string;
};

const ConfirmEmail = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { id, token } = useParams<ConfirmEmailUrlParamsType>();

    const confirmEmailValue = useAppSelector((state) => state.auth.confirmEmail);
    const cookies = useAppSelector((state) => state.auth.cookies);

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    const redirectToRegister = () => {
        navigate("/register");
    };

    useEffect(() => {
        if (id && token) {
            cookies.set("confirmToken", token, {
                path: "/",
                secure: true,
            });
            cookies.set("confirmId", id, {
                path: "/",
                secure: true,
            });
            dispatch(confirmEmail(id, token, redirectToRegister, cookies));
        }
    }, []);

    if (confirmEmailValue) {
        return <Navigate to="/register" />;
    }
    return (
        <Container className="spiner-container container-bg">
            <img src={preloader} style={{ marginLeft: 40 }} width="150" height="150" alt="Loading..."></img>
        </Container>
    );
};

export default ConfirmEmail;
