import ReactGA from "react-ga";
import { scroller } from "react-scroll";
import { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router";

import { calculateVisibility } from "../common/componentsUtils";

import Footer from "./Footer/Footer";
import NavBar from "./NavBar/NavBar";

import PLSection from "./3PLSection/3PLSection";
import FirstDual from "./DualSection/FirstDual";
import SecondDual from "./DualSection/SecondDual";
import FaqsSection from "./FaqsSection/FaqsSection";
import HeroSection from "./HeroSection/HeroSection";
import SaveMoney from "./SaveMoneySection/SaveMoney";
import BanerSection from "./BanerSection/BanerSection";
import OwnFFSection from "./OwnFFSection/OwnFFSection";
import ConnectWithUs from "./ConnectWithUs/ConnectWithUs";
import WelcomSection from "./WelcomeSection/WelcomSection";
import PricingSection from "./PricingSection/PricingSection";
import StorySection from "./SKUDropStorySection/StorySection";
import HowWorkSection from "./HowWorksSection/HowWorkSection";
import FeatureSection from "./FeaturesSection/FeatureSection";
import GuaranteeSection from "./GuaranteeSection/GuaranteeSection";
import CalculatorSection from "./CalculatorSection/CalculatorSection";
import PriceMethodGuarantee from "./PriceMethodGuarantee/PriceMethodGuarantee";
import FreightForwarderVsSKUdrop from "./FreightForwarderVsSKUdrop/FreightForwarderVsSKUdrop";

import "./LandingPage.css";

const LandingPage = () => {
    const navigate = useNavigate();
    const { hash } = useLocation();

    const containerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const section = document.getElementById(hash.replace("#", ""));
        if (section && hash.replace("#", "").length) {
            const timer = setTimeout(() => {
                scroller.scrollTo(section.id, { duration: 500, smooth: "easeInOutQuart", offset: -50 });
            }, 750);

            return () => clearTimeout(timer);
        }
    }, []);

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: "0px",
            threshold: [0.05, 0.5, 1],
        };

        const blockElements = Array.from(containerRef?.current?.children || []);

        const observer = new IntersectionObserver(() => {
            const windowTop = window.scrollY;
            const windowBottom = windowTop + window.innerHeight;

            let max = 0;
            let maxElement: any = null;

            blockElements.forEach((element) => {
                const rect = element.getBoundingClientRect();
                const elementTop = rect.top + window.scrollY;
                const elementBottom = elementTop + element.clientHeight;

                const visibility = calculateVisibility(elementTop, elementBottom, windowTop, windowBottom);

                if (visibility > max) {
                    max = visibility;
                    maxElement = element;
                }
            });

            if (maxElement instanceof Element) {
                navigate(`#${maxElement.id}`, { replace: true });
            }
        }, options);

        blockElements.forEach((element) => {
            observer.observe(element);
        });

        return () => {
            observer.disconnect();
        };
    }, [containerRef, navigate]);

    return (
        <div ref={containerRef}>
            <NavBar />
            <HeroSection />
            <FeatureSection />
            <div id="details">
                <HowWorkSection />
                <PriceMethodGuarantee />
                <StorySection />
                <WelcomSection />
                <FirstDual />
                <PLSection />
                <SecondDual />
                <GuaranteeSection />
                <SaveMoney />
            </div>
            <PricingSection />
            <CalculatorSection />
            <OwnFFSection />
            <ConnectWithUs />
            <FreightForwarderVsSKUdrop />
            <FaqsSection />
            <BanerSection />
            <Footer />
        </div>
    );
};

export default LandingPage;
