import { useEffect, useState } from "react";
import CompareTable from "./CompareTable/CompareTable";
import sku_logo from "../../../assets/img/skudrop-logo.png";

export type CompareSkuDropData = {
    question: string;
    answer_freight_forward: {
        title: string;
        description: null | string;
    };
    answer_skudrop: {
        title: string;
        description: null | string;
    };
};

const FreightForwarderVsSKUdrop = () => {
    const data = [
        {
            question: "Can goods be consolidated, stored & FBA prepped in China for efficiency?",
            answer_freight_forward: {
                title: "NO",
                description: null,
            },
            answer_skudrop: {
                title: "YES",
                description: "SKUdrop becomes your Chinese storage facility",
            },
        },
        {
            question: "Can shipping plans be created in Seller Central in under 5 seconds?",
            answer_freight_forward: {
                title: "NO",
                description: null,
            },
            answer_skudrop: {
                title: "YES",
                description: "Do shipping plans in our software in under 5 seconds",
            },
        },
        {
            question: "Is it easy to get quotes?",
            answer_freight_forward: {
                title: "NO",
                description: "Emails and information is sent back & forth before you receive a quote",
            },
            answer_skudrop: {
                title: "YES",
                description: "Our quotes are instant when creating shipping plans",
            },
        },
        {
            question: "Does the quote compare every type of pricing rate to help the seller save money?",
            answer_freight_forward: {
                title: "NO",
                description: "You only get a KG rate or a CBM rate",
            },
            answer_skudrop: {
                title: "YES",
                description: "We compare all pricing rates to give you the best rate",
            },
        },
        {
            question: "Is payment required upfront?",
            answer_freight_forward: {
                title: "YES",
                description: "You have to pay for your whole shipment upfront draining you of cashflow",
            },
            answer_skudrop: {
                title: "NO",
                description: "The SKUdrop model is based on smaller weekly shipments",
            },
        },
        {
            question: "Are tracking ID's updated in Seller Central?",
            answer_freight_forward: {
                title: "NO",
                description: null,
            },
            answer_skudrop: {
                title: "YES",
                description: "Our software solution incorporates full automation into your supply chain",
            },
        },
        {
            question: "Are tariffs managed automatically?",
            answer_freight_forward: {
                title: "NO",
                description: null,
            },
            answer_skudrop: {
                title: "YES",
                description: "We automate this process and even provide 7501 entry summary forms",
            },
        },
        {
            question: "Can risk be reduced?",
            answer_freight_forward: {
                title: "NO",
                description: "Your entire order is on the water which increases risk of stock outs",
            },
            answer_skudrop: {
                title: "YES",
                description: "Your entire order is never on the water with SKUdrop reducing risk",
            },
        },
        {
            question: "Can 3PL costs be eliminated?",
            answer_freight_forward: {
                title: "NO",
                description: "Freight forwarders cannot eliminate expensive 3PL costs at all",
            },
            answer_skudrop: {
                title: "YES",
                description: "SKUdrop eliminates expensive 3PL's saving you $1,000's of dollars because we ship your goods direct to Amazon",
            },
        },
        {
            question: "Do you need to purchase bonds?",
            answer_freight_forward: {
                title: "YES",
                description: null,
            },
            answer_skudrop: {
                title: "NO",
                description: "We take care of this for you",
            },
        },
    ];

    const [mobile, setMobile] = useState(false);

    const handleResize = () => {
        if (window.innerWidth < 1100) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    };

    // create an event listener
    useEffect(() => {
        window.addEventListener("resize", handleResize);
    });

    useEffect(() => {
        handleResize();
    }, []);

    return (
        <div id="freight-forwarders" className="py-5 feature-bg d-flex flex-column align-items-center justify-content-start">
            <h1 className="section-name mb-0">Freight Forwarders vs SKUdrop</h1>
            <div className="w-100 max-w-890px">
                <p className="mb-0 mt-4 text-center px-4 avenir-book-400">
                    SKUdrop offers a superior supply chain solution compared to traditional freight forwarders. Our platform is designed by sellers, for sellers, so we understand
                    your needs and have incorporated them into every aspect of our service.
                </p>
            </div>
            <CompareTable image={sku_logo} data={data} mobile={mobile} />
        </div>
    );
};

export default FreightForwarderVsSKUdrop;
