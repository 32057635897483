import ReactGA from "react-ga";
import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router";
import InfoBlock from "../InfoBlock/InfoBlock";
import EmailSent from "./EmailSent";
import ResetPassword from "./ResetPassword";
import SendEmailToReset from "./SendEmailToReset";

export type ResetContainerUrlParamsType = {
    id: string;
    token: string;
};

const ResetContainer = () => {
    const { id, token } = useParams<ResetContainerUrlParamsType>();
    const [resetStatus, setResetStatus] = useState("first");

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    useEffect(() => {
        if (id && token) {
            setResetStatus("third");
        }
    }, []);

    return (
        <Container style={{ margin: 0, maxWidth: "100%", padding: 0 }}>
            <Row>
                <Col xl={6} style={{ padding: 0 }}>
                    <InfoBlock />
                </Col>
                <Col xl={6} style={{ padding: 0 }}>
                    <div className="max-h-100vh info-block container-bg m-0 mw-100 p-0 overflow-auto container">
                        {resetStatus === "first" && <SendEmailToReset setResetStatus={setResetStatus} />}
                        {resetStatus === "second" && <EmailSent />}
                        {resetStatus === "third" && id && token && <ResetPassword id={id} token={token} />}
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default ResetContainer;
