import React, { useEffect, useState } from "react";
import { Alert, Col, Container, Form, Row } from "react-bootstrap";
import checkEmailImg from "../../../assets/img/checkEmail.png";
import preloader from "../../../assets/video/preloader.webp";
import { useAppSelector } from "../../../redux/ReduxStore";

const CheckEmail = () => {
    const [error, setError] = useState<boolean | string>(false);
    const isLoading = useAppSelector((state) => state.auth.isLoading);
    const registerError = useAppSelector((state) => state.auth.registerError);

    useEffect(() => {
        if (registerError === "Reactivate email") {
            setError("Account with this email was found in the system. New verification email was sent.");
        } else if (registerError === "Reactivate email and set password") {
            setError("Account with this email was found in the system. But password was not confirmed, please follow new link in email to update your password.");
        }
    }, [registerError]);

    if (isLoading) {
        return (
            <Container className="block auth-form-container container-bg">
                <Form className="auth-form d-flex align-items-center justify-content-center shadow-sm">
                    <img src={preloader} style={{ marginLeft: 40 }} width="150" height="150" alt="Loading..."></img>
                </Form>
            </Container>
        );
    }

    return (
        <Container className="block auth-form-container container-bg p-0 justify-content-start">
            <Container className="d-flex flex-column align-items-center justify-content-center w-100 h-100">
                <Form style={{ width: "75%" }} className="auth-form complete shadow-sm">
                    {error && (
                        <Row>
                            <Col>
                                <Alert className="text-center" variant={"danger"} onClose={() => setError(false)} dismissible>
                                    {error}
                                </Alert>
                            </Col>
                        </Row>
                    )}
                    <Row>
                        <Col className="d-flex justify-content-center mb-5 check-email-container">
                            <img src={checkEmailImg} alt="Loading..." className="check-email-img" style={{ width: "30%" }}></img>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-flex justify-content-center flex-column text-center p-0">
                            <h4 className="check-email-title">Please check your email inbox</h4>
                            <p className="text-muted mb-0">To register, please follow the link in the email you receive.</p>
                        </Col>
                    </Row>
                </Form>
            </Container>
        </Container>
    );
};

export default CheckEmail;
